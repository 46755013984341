import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import ShortUniqueId from 'short-unique-id'

import {
  isEmpty,
  difference,
  upperFirst,
  lowerFirst,
  lowerCase,
  capitalize,
} from 'lodash'

import { updatePlatform } from '../../../api/platform'

import platformActions from '../platformActions'
import PlatformTests from '../platformTests/PlatformTests'

class ErrorWithoutStackTrace extends Error {
  constructor(...args) {
    super(...args)
    this.stack = `${this.name}: ${this.message}`
  }
}

const getRandomNumberByDigit = (digit) => {
  if (!digit) throw Error('Please pass the digit of random number')
  let randomNum = Number(Math.random().toFixed(digit).slice(-digit))
  if (`${randomNum}`.length < digit) randomNum = `${randomNum}`.padEnd(digit, '1')
  return Number(randomNum)
}

const getRequestNameTxnNo = (betIndexes) => {
  if (!betIndexes) return ''

  return ` - ${betIndexes.map((i) => {
    if (Array.isArray(i)) {
      const [betIdx, txnIdx] = i
      return `TXN ${betIdx + 1}-${txnIdx + 1}`
    }
    return `TXN ${i + 1}`
  }).join(', ')}`
}

export const getTimesLoopRequestName = (timesLoop) => {
  if (timesLoop == null) return ''

  const times = timesLoop + 1
  let timesText = `${times}th`
  if (times === 1) timesText = '1st'
  if (times === 2) timesText = '2nd'
  if (times === 3) timesText = '3rd'
  return `${timesText} TIME: `
}

const getPlayerRequestName = playerIndex => (playerIndex != null ? `PLAYER ${playerIndex + 1}: ` : '')
const getHorseLoopRequestName = horseLoop => (horseLoop != null ? `HORSE ${horseLoop + 1}: ` : '')

const getRequestNameStr = ({
  action,
  settleResult,
  playerIndexes,
  betIndexes,
  horseLoop,
  timesLoop,
}) => {
  const capitalizedAction = upperFirst(action)

  let capitalizedSettleResult = ''
  if (settleResult) capitalizedSettleResult = ` ${upperFirst(settleResult)}`

  const playerIndexName = getPlayerRequestName(playerIndexes?.[0])
  const timesLoopName = getTimesLoopRequestName(timesLoop)
  const horseLoopName = getHorseLoopRequestName(horseLoop)

  return `${timesLoopName}${playerIndexName}${horseLoopName}${capitalizedAction}${capitalizedSettleResult}${getRequestNameTxnNo(betIndexes)}`
}

export const getRequestName = (args) => {
  const { playerIndexes } = args

  let result = ''
  if (playerIndexes) {
    result = playerIndexes.map(playerIdx => getRequestNameStr({ ...args, playerIndexes: [playerIdx] }))
    if (playerIndexes.length > 1) {
      result.unshift('')
      result = result.join('\n')
    } else {
      result = result.toString()
    }
  } else {
    result = getRequestNameStr(args)
  }

  return result
}

/* eslint-disable no-await-in-loop */
export default class PlatformRequests {
  constructor(formData) {
    this.formData = formData

    const { platform } = formData
    this.actions = new platformActions[platform](formData)
    this.tests = new PlatformTests(formData)

    this.requestsInTestScenario = []
    this.txnIdCounter = null
    this.roundIdCounter = null
  }

  emptyRequests() {
    const ALL_ACTIONS_WITH_DATA_IN_TESTS = [
      'bet',
      'cancelBet',
      'settle',
      'voidBet',
      'unvoidBet',
      'unsettle',
      'resettle',
      'voidSettle',
      'unvoidSettle',
      'tip',
      'cancelTip',
      'give',
      'refund',
      'betNSettle',
      'cancelBetNSettle',
      'adjustAmt',
      'cancelAdjustAmt',
    ]
    ALL_ACTIONS_WITH_DATA_IN_TESTS.forEach((action) => {
      if (this.tests[`${action}Data`]) this.tests[`${action}Data`] = null
    })

    const ALL_ACTIONS_WITH_DATA = [
      'bet',
      'cancelBet',
      'tip',
      'cancelTip',
      'betNSettle',
      'cancelBetNSettle',
      'adjustAmt',
      'cancelAdjustAmt',
    ]
    ALL_ACTIONS_WITH_DATA.forEach(action => this[`${action}Data`] = [])

    this.requestsInTestScenario = []
    this.asyncSettleData = {}
  }

  async requestPromise({ requestName, action, extraData }, res, testCallback) {
    const requestIdx = this.requestsInTestScenario.length + 1
    this.requestNo = `${this.scenarioNo}-${requestIdx}`

    if (res.data.data) {
      await testCallback().catch(async (e) => {
        this.caughtErr = e

        // cancel bet when error caught after betForNegativeBalance
        if (process.env.NODE_ENV !== 'production' && this.negativeBalanceBetIndex != null) {
          let cancelBetRes
          if (this.betData?.[0]) {
            cancelBetRes = await this.actions.cancelBet(this.betData[0][this.negativeBalanceBetIndex].flat())
          }
          if (this.betNSettleData?.[0]) {
            cancelBetRes = await this.actions.cancelBetNSettle(this.betNSettleData[0][this.negativeBalanceBetIndex].flat())
          }
          console.log('cancelBetRes', cancelBetRes)
        }
      })
    }

    this.pushRequest({ requestName, extraData }, res)

    return new Promise((resolve, reject) => {
      const actionsNeedGetBalanceAfterFailed = ['settle', 'voidBet', 'unvoidBet', 'unsettle', 'voidSettle', 'unvoidSettle', 'betNSettle']
      const actionIdx = actionsNeedGetBalanceAfterFailed.indexOf(action)

      if (this.caughtErr
        && (actionIdx < 0 || (actionIdx > -1 && (this.negativeBalanceBetIndex != null || isEmpty(this.asyncSettleData))))) {
        const storedErr = this.caughtErr
        this.caughtErr = null
        this.negativeBalanceBetIndex = null
        reject(storedErr)
      } else {
        resolve()
      }
    })
  }

  pushRequest({ requestName, extraData }, res) {
    const {
      data: {
        error,
        data,
        responseCode,
        requestTs,
        responseTs,
        duration,
      },
      config: {
        data: requestBody,
      },
    } = res

    const statusCodePassed = !error && (responseCode.startsWith('20') || responseCode.startsWith('30'))

    const tests = this.tests.testsInRequest
    const requestPassed = statusCodePassed && !!tests.length && tests.every(({ passed }) => passed)

    this.requestsInTestScenario.push({
      name: `${this.requestNo}. ${requestName}`,
      extraData,
      passed: requestPassed,
      tests,
      requestBody,
      responseBody: JSON.stringify(data),
      responseCode,
      requestTs,
      responseTs,
      duration,
    })

    this.tests.emptyTests()

    if (error) throw error
    if (!statusCodePassed) throw new ErrorWithoutStackTrace('Error response status code')
    if (!data && !error) throw new ErrorWithoutStackTrace('The response is null')
  }

  async getBalance(args = {}) {
    const {
      action,
      playerIndexes,
      options,
    } = args

    const playerIndex = playerIndexes ? playerIndexes[0] : 0

    const res = await this.actions.getBalance(this.formData[`userId${playerIndex + 1}`])
    if (this.asyncSettleData.res) res.data.asyncSettleRes = this.asyncSettleData.res

    const playerIndexName = playerIndexes ? getPlayerRequestName(playerIndex) : ''

    let capitalizedAction
    let requestName = `${playerIndexName}GetBalance`
    if (action) {
      capitalizedAction = upperFirst(action)
      requestName = `${playerIndexName}GetBalance after ${capitalizedAction}`

      if (action.includes('async')) requestName = `GetBalance after ${action.replace(/async(\w+)/, '(Network) $1')}`
      if (action.includes('After')) requestName = `GetBalance for ${capitalizedAction.replace(/(\w+)After(\w+)/, '$1 after $2')}`
    }

    return this.requestPromise(
      {
        action: 'getBalance',
        requestName,
      },
      res,
      () => this.tests[`${action ? `getBalanceAfter${capitalizedAction}` : 'getBalance'}`]({
        requestNo: this.requestNo,
        res: res.data,
        playerIndex,
        options,
      }),
    )
  }

  async getIdData(args = {}) {
    const {
      action = 'bet',
      playerIndex = 0,
      betIndex = 0,
      options,
    } = args

    let { platform } = this.formData
    const needNewRoundId = betIndex === 0 || (betIndex && options?.differentRound)

    let lastTxn = {}
    if (betIndex) [lastTxn] = this[`${action}Data`][playerIndex].slice(-1)[0].slice(-1)

    const idData = {}
    switch (platform) {
      case 'KINGMAKERSLOT': { // Bet, CancelBet, Settle
        idData.roundId = needNewRoundId ? new ShortUniqueId({ length: 13 }).rnd() : lastTxn.roundId
        break
      }

      case 'KINGMAKERMINI': // Bet, CancelBet, Settle
      case 'KINGMAKERTABLE': { // Bet, CancelBet, Settle, Give
        const uuidArr = uuidv4().split('-')
        idData.txnId = uuidArr.slice(1).join('')

        const suid = new ShortUniqueId({ length: 13 })
        idData.roundId = needNewRoundId ? suid.rnd() : lastTxn.roundId
        break
      }

      case 'PGSLOTMIXED': // Bet, Settle, BetNSettle
      case 'PGSLOTOLD': // Bet, CancelBet, Settle
      case 'PGSLOT': // BetNSettle, CancelBettleNSettle
      case 'PGTABLE': { // BetNSettle, CancelBettleNSettle
        idData.txnId = `${moment().valueOf()}${getRandomNumberByDigit(6)}`
        break
      }

      case 'PPEGAME': // Bet, CancelBet, Settle
      case 'PPLIVE': // Bet, CancelBet, Settle
      case 'PPSLOT': { // Bet, CancelBet, Settle
        const uuidArr = uuidv4().split('-')
        idData.txnId = uuidArr.slice(1).join('')
        idData.roundId = moment().valueOf()
        break
      }

      case 'YLEGAME': { // Bet, CancelBet, Settle
        idData.roundId = moment().format('yyyyMMDDHHmmssSSS') + getRandomNumberByDigit(7)
        break
      }

      case 'YLFISH': { // BetNSettle, CancelBettleNSettle
        if (this.txnIdCounter == null) this.txnIdCounter = 100000
        this.txnIdCounter += 1

        let roundId
        let roomId
        if (needNewRoundId) {
          roundId = moment().format('yyyyMMDDHHmmssSSS') + getRandomNumberByDigit(7)
          roomId = moment().format('yyyyMMDDHHmmssSSS') + getRandomNumberByDigit(7)
        } else {
          ({ roundId, roomId } = lastTxn)
        }

        idData.txnId = `${roundId}${this.txnIdCounter}`
        idData.roundId = roundId
        idData.roomId = roomId
        break
      }

      case 'JILITABLE': { // Bet, CancelBet, Settle
        const txnIdUnix = `${moment().valueOf()}`.slice(-11)
        idData.txnId = `${txnIdUnix}${getRandomNumberByDigit(7)}`

        let roundId
        if (needNewRoundId) {
          if (this.roundIdCounter == null) this.roundIdCounter = Number(`2${getRandomNumberByDigit(14)}`)
          this.roundIdCounter += 1
          roundId = this.roundIdCounter
        } else {
          ({ roundId } = lastTxn)
        }

        idData.roundId = roundId
        break
      }

      case 'LUCKYPOKER': { // Bet, CancelBet, Settle
        let roundId
        let roundIdUnix
        if (needNewRoundId) {
          if (this.roundIdCounter == null) this.roundIdCounter = 10000000
          this.roundIdCounter += 1
          roundId = this.roundIdCounter
          roundIdUnix = moment().unix()
        } else {
          ({ roundId, roundIdUnix } = lastTxn)
        }

        if (this.txnIdCounter == null) this.txnIdCounter = 10000
        this.txnIdCounter += 1

        idData.txnId = this.txnIdCounter
        idData.roundId = roundId
        idData.roundIdUnix = roundIdUnix
        break
      }

      case 'FASTSPINFISH': // BetNSettle, CancelBettleNSettle
      case 'FASTSPINSLOT': { // Bet, CancelBet, Settle, Give
        if (this.roundIdCounter == null) this.roundIdCounter = 10000
        this.roundIdCounter += 1

        if (this.txnIdCounter == null) this.txnIdCounter = 100000
        this.txnIdCounter += 1

        const txnIdPrefix = moment().format('yyyyMMDDHHmmssSSS')

        idData.txnId = `${txnIdPrefix}${this.txnIdCounter}`
        idData.roundId = `${this.roundIdCounter}`
        break
      }

      case 'SPADEEGAME': // Bet, CancelBet, Settle
      case 'SPADESLOT': { // Bet, CancelBet, Settle, Give, FreeSpin
        const txnIdIdx = moment().format('yyyyMMDDHHmmssSSS')
        const randomNo = getRandomNumberByDigit(6)
        idData.txnId = `${txnIdIdx}${randomNo}`
        break
      }

      case 'JILIFISH': // BetNSettle, CancelBettleNSettle
      case 'JILISLOT': {
        const txnIdUnix = `${moment().valueOf()}`.slice(-11)

        if (this.roundIdCounter == null) this.roundIdCounter = getRandomNumberByDigit(17)

        idData.txnId = `${txnIdUnix}${getRandomNumberByDigit(7)}`
        idData.roundId = this.roundIdCounter
        break
      }

      case 'JDBEGAME': // Bet, CancelBet, Settle, BetNSettle, CancelBettleNSettle
      case 'JDBFISH':
      case 'JDBSLOT':
      case 'GTFFISH':
      case 'GTFSLOT': {
        if (this.txnIdCounter == null) this.txnIdCounter = getRandomNumberByDigit(7)
        this.txnIdCounter += 1

        if (this.roundIdCounter == null) this.roundIdCounter = getRandomNumberByDigit(7)
        this.roundIdCounter += 1

        idData.txnId = `${this.txnIdCounter}`
        idData.roundId = `525017${this.roundIdCounter}`
        break
      }

      case 'JDBTABLE': {
        if (this.txnIdCounter == null) this.txnIdCounter = getRandomNumberByDigit(5)
        this.txnIdCounter += 1

        if (this.roundIdCounter == null) this.roundIdCounter = getRandomNumberByDigit(5)
        this.roundIdCounter += 1

        idData.txnId = `20${this.txnIdCounter}`
        idData.roundId = `22750${this.roundIdCounter}`
        break
      }

      case 'SPRIBE': {
        if (this.txnIdCounter == null) this.txnIdCounter = getRandomNumberByDigit(7)
        this.txnIdCounter += 1

        if (this.roundIdCounter == null) this.roundIdCounter = getRandomNumberByDigit(7)
        this.roundIdCounter += 1

        idData.txnId = `${this.txnIdCounter}`
        idData.roundId = `${this.roundIdCounter}`
        break
      }

      case 'AWSEGAME': // BetNSettle, CancelBettleNSettle
      case 'AWSSLOT':
      case 'AWSTABLE': {
        idData.txnId = moment().format('yyyyMMDDHHmmssSSS')
        break
      }

      case 'SPADEFISH': { // BetNSettle, CancelBettleNSettle
        idData.txnId = `${moment().format('yyyyMMDDHHmmssSSS')}${getRandomNumberByDigit(6)}`
        break
      }

      case 'AELOTTO': { // Bet, CancelBet, Settle, VoidBet
        idData.txnId = moment().valueOf()
        idData.roundId = needNewRoundId ? moment().format('yyyyMMDDHHmmSSS') : lastTxn.roundId
        break
      }

      case 'BG': { // Bet, Settle, Unsettle, VoidBet, VoidSettle, CancelBet
        idData.txnId = `${moment().valueOf()}`.slice(-10)

        let roundId
        if (needNewRoundId) {
          if (this.roundIdCounter == null) this.roundIdCounter = Number(moment().format('YYMMDD') + 100)
          this.roundIdCounter += 1
          roundId = this.roundIdCounter
        } else {
          ({ roundId } = lastTxn)
        }

        idData.roundId = roundId
        break
      }

      case 'FCEGAME': // Bet, CancelBet, Settle, BetNSettle, CancelBettleNSettle
      case 'FCFISH':
      case 'FCSLOT': {
        idData.txnId = uuidv4().split('-').slice(1, 5).join('')
        idData.roundId = needNewRoundId ? uuidv4().split('-').slice(1, 5).join('') : lastTxn.roundId
        break
      }

      case 'YESBINGOFISH': // BetNSettle, CancelBettleNSettle
      case 'YESBINGOEGAME': // Bet, CancelBet, Settle
      case 'YESBINGOSLOT': { // Bet, CancelBet, Settle
        idData.txnId = `${moment().valueOf()}`.slice(-10)

        if (this.roundIdCounter == null) this.roundIdCounter = getRandomNumberByDigit(10)
        this.roundIdCounter += 1
        idData.roundId = this.roundIdCounter
        break
      }

      case 'RTSLOT': // Bet, CancelBet, Settle, VoidBet
      case 'RTTABLE': {
        idData.txnId = uuidv4()
        idData.roundId = moment().unix()
        break
      }

      case 'PLAY8FISH': // BetNSettle, CancelBettleNSettle
      case 'PLAY8SLOT': {
        idData.txnId = uuidv4().replaceAll('-', 'a')
        idData.roundId = moment().unix()
        break
      }

      case 'YESBINGOBINGO': { // Bet, CancelBet, Settle
        idData.txnId = `${moment().valueOf()}`.slice(-10)

        if (this.roundIdCounter == null) this.roundIdCounter = getRandomNumberByDigit(7)
        this.roundIdCounter += 1
        idData.roundId = this.roundIdCounter
        break
      }

      case 'PTLIVE':
      case 'PTSLOT': {
        if (this.txnIdCounter == null) this.txnIdCounter = getRandomNumberByDigit(10)
        this.txnIdCounter += 1

        if (this.roundIdCounter == null) this.roundIdCounter = getRandomNumberByDigit(10)
        this.roundIdCounter += 1

        // const { data: { roundId } } = await updatePlatform({
        //   platform,
        //   roundId: 1,
        // })

        idData.txnId = `${this.txnIdCounter}`
        idData.roundId = `${this.roundIdCounter}`
        break
      }
      // 173738707923328 1318874398806 11021 162116037494609  2022 1021 074
      case 'VRLOTTO': { // Bet, CancelBet, Settle, VoidBet
        if (this.roundIdCounter == null) this.roundIdCounter = 100
        this.roundIdCounter += 1

        idData.txnId = `1${moment().format('MMDD')}${moment().valueOf()}`
        idData.roundId = needNewRoundId ? `${moment().format('yyyyMMDD')}${this.roundIdCounter}` : lastTxn.roundId
        break
      }

      case 'DRAGOONSOFT': { // Bet, CancelBet, Settle
        idData.txnId = uuidv4().split('-').slice(1, 5).join('')
        break
      }

      case 'BTG':
      case 'EVOLUTION':
      case 'NETENTSLOT':
      case 'NETENTTABLE': { // Bet, CancelBet, Settle
        idData.txnId = `69${moment().valueOf()}${platform === 'EVOLUTION' ? 180 : 236}`
        idData.roundId = uuidv4().split('-').slice(1, 5).join('')
        break
      }

      case 'NLC': { // Bet, CancelBet, Settle
        if (this.txnIdCounter == null) this.txnIdCounter = getRandomNumberByDigit(7)
        this.txnIdCounter += 1
        idData.txnId = `NLCP-${this.txnIdCounter}` // NLCP-8826985
        idData.roundId = idData.txnId
        break
      }

      case 'HOTROAD': {
        if (this.txnIdCounter == null) this.txnIdCounter = Number(`${moment().valueOf()}`.slice(-9))
        this.txnIdCounter += 1

        if (this.roundIdCounter == null) this.roundIdCounter = 1
        this.roundIdCounter += 1

        idData.txnId = this.txnIdCounter
        idData.roundId = this.roundIdCounter
        break
      }

      case 'ILOVEU': { // BetNSettle, CancelBettleNSettle
        if (this.txnIdCounter == null) this.txnIdCounter = getRandomNumberByDigit(13)
        this.txnIdCounter += 1

        idData.txnId = `324596${this.txnIdCounter}`
        idData.roundId = `NTY2NTMxNC8zMjQ1OT${new ShortUniqueId({ length: 18 }).rnd()}`
        break
      }

      case 'LADYLUCK': { // Bet, CancelBet, Settle
        idData.txnId = uuidv4().split('-').slice(1, 5).join('')
        break
      }

      case 'VIACASINO': { // Bet, CancelBet, Settle
        if (this.txnIdCounter == null) this.txnIdCounter = getRandomNumberByDigit(12)
        this.txnIdCounter += 1
        if (this.roundIdCounter == null) this.roundIdCounter = getRandomNumberByDigit(6)
        this.roundIdCounter += 1

        idData.txnId = `1833400${this.txnIdCounter}`
        idData.roundId = `15070144${this.roundIdCounter}`
        break
      }

      // txnId, roundId are serial numbers from DB
      // Platforms: SEXYBCRT, SV388, E1SPORT, HORSEBOOK, VENUS, SABA, LUDO, AELIVECOMM
      default: {
        let roundId
        let roundIdIncr

        if (playerIndex) {
          if (options?.differentRound) {
            roundIdIncr = 1
          } else {
            ([{ roundId }] = this.betData[playerIndex - 1].slice(-1)[0].slice(-1))
          }
        } else if (betIndex) {
          if (options?.differentRound) {
            roundIdIncr = 1
          } else {
            ({ roundId } = lastTxn)
          }
        } else {
          roundIdIncr = 1
        }

        if (platform === 'SEXYBCRT_ONEDAY') platform = 'SEXYBCRT'
        if (platform === 'SABAOLD') platform = 'SABA'

        const { data } = await updatePlatform({
          platform,
          txnId: 1,
          ...(roundIdIncr && { roundId: roundIdIncr }),
        })

        const { txnId } = data
        if (data.roundId) ({ roundId } = data)

        idData.txnId = txnId
        idData.roundId = roundId
        break
      }
    }

    return idData
  }

  async setBetData({
    playerIndex = 0,
    betIndex,
    data = {},
    options,
  }) {
    const { platform } = this.formData
    let betAmount = Number((data.betAmount || this.formData.betAmount).toFixed(4))

    let betTime = moment().subtract(4, 'seconds').utcOffset(8).toISOString(true)
    let platformBetData = {}
    switch (platform) {
      case 'SEXYBCRT':
      case 'VENUS':
      case 'AELIVECOMM': {
        const {
          resettleResult,
          resettleAmountChange,
        } = options || {}

        let betType = 'Banker'
        if (resettleResult === 'win' && (resettleAmountChange === 'increased' || resettleAmountChange === 'decreased')) {
          betType = 'BankerBonus'
        }

        platformBetData = {
          betType,
          roundStartTime: moment().subtract(10, 'seconds').utcOffset(8).toISOString(true),
          denyTime: moment(betTime).subtract(1, 'seconds').utcOffset(8).toISOString(true),
        }
        break
      }

      case 'VIACASINO': {
        const {
          resettleResult,
          resettleAmountChange,
        } = options || {}

        let betType = 'BANKER'
        if (resettleResult === 'win' && (resettleAmountChange === 'increased' || resettleAmountChange === 'decreased')) {
          betType = 'BANKER,BANKER_PAIR'
        }

        platformBetData = {
          betType,
        }
        break
      }

      case 'HOTROAD': {
        platformBetData = {
          betType: 'Banker',
          roundStartTime: moment().subtract(10, 'seconds').utcOffset(8).toISOString(true),
        }
        break
      }

      case 'KINGMAKERMINI': {
        platformBetData = {
          betType: 'banker',
        }
        break
      }

      case 'HORSEBOOK': {
        platformBetData = {
          betType: 'WIN',
          runnerNo: '1',
          odds: 0.5,
        }
        break
      }

      case 'SV388': {
        const odds = options?.positiveOdds ? 0.78 : -0.94
        platformBetData = {
          odds,
          betType: 'WALA',
          realBetAmount: options?.positiveOdds ? betAmount * -1 : Number((betAmount * odds).toFixed(4)),
          adjustBetAmount: !options?.positiveOdds && Number(Math.abs(betAmount * odds).toFixed(4)),
          eventDate: moment().subtract(moment().hours() >= 16 ? 0 : 1, 'days').format('YYYY-MM-DDT16:00:00.000+0000'),
        }
        break
      }

      case 'E1SPORT': {
        const odds = options?.positiveOdds ? 0.58 : -0.45
        const marketType = data.marketType || 2
        const tournamentTime = marketType === 1 ? moment().subtract(10, 'minutes') : moment().add(10, 'minutes')

        platformBetData = {
          odds,
          adjustBetAmount: !options?.positiveOdds && Number(Math.abs(betAmount * odds).toFixed(4)),
          tournamentDate: tournamentTime.second(0).millisecond(0).utcOffset(0).format('YYYY-MM-DDTHH:mm:ss.SSS+0000'),
        }
        break
      }

      case 'AELOTTO': {
        platformBetData = {
          odds: 80,
          betItems: '13',
          roundDate: moment().second(0).add(1, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ'),
        }
        break
      }

      case 'BG': {
        betTime = moment(betTime).millisecond(0).utcOffset(8).toISOString(true)

        platformBetData = {
          betType: 'Banker',
        }
        break
      }

      case 'SABA':
      case 'SABAOLD': {
        const { oddsType } = data

        let odds
        let adjustBetAmount

        if (data.odds) {
          ({ odds } = data)
        } else if (data.isParlay) {
          odds = [1.91, 2.08, 3.75]
        } else {
          switch (oddsType) {
            case '1': // MY [+/-]
              if (options?.positiveOdds) {
                odds = 1.45
              } else {
                odds = -1.26
                adjustBetAmount = Number((Math.abs(betAmount * odds)).toFixed(4))
              }
              break

            case '2': // CN [+]
            default:
              odds = 0.66
              break

            case '3': // DEC [+] >= 1
              odds = 1.34
              break

            case '4': // IND [+/-] >= 1
              if (options?.positiveOdds) {
                odds = 1.92
              } else {
                odds = -2.23
                adjustBetAmount = Number((Math.abs(betAmount * odds)).toFixed(4))
              }
              break

            case '5': // US [+/-] >= 100
              if (options?.positiveOdds) {
                odds = 168
              } else {
                odds = -179
              }
              break
          }
        }

        if (odds < 0) {
          if (oddsType === '5') {
            adjustBetAmount = Number((Math.abs(betAmount * (odds / 100))).toFixed(4))
          } else {
            adjustBetAmount = Number((Math.abs(betAmount * odds)).toFixed(4))
          }
        }

        platformBetData = {
          odds,
          adjustBetAmount,
          matchDateTime: moment(betTime).minute(0).second(0)
            .millisecond(0)
            .utcOffset(8)
            .toISOString(true),
        }

        betAmount = odds < 0 ? adjustBetAmount : betAmount

        break
      }

      case 'JILITABLE':
      case 'SPADEEGAME':
      case 'SPADESLOT':
      case 'RTSLOT':
      case 'RTTABLE':
      case 'FASTSPINFISH':
      case 'FASTSPINSLOT':
      case 'PTLIVE':
      case 'PTSLOT':
      case 'VRLOTTO':
      case 'DRAGOONSOFT': {
        betTime = moment(betTime).millisecond(0).utcOffset(8).toISOString(true)
        break
      }

      case 'LUDO': {
        betTime = moment(betTime).millisecond(0).utcOffset(8).toISOString(true)

        platformBetData = {
          roundIdSuffix: Math.random().toString(36).slice(-8).toUpperCase(),
          sign: uuidv4().replaceAll('-', ''),
        }
        break
      }

      default:
        break
    }

    const settleTypePlatforms = {
      roundId: ['JILITABLE', 'LUCKYPOKER', 'PPEGAME', 'PPSLOT', 'PTLIVE'],
      refPlatformTxId: ['FASTSPINSLOT', 'KINGMAKERSLOT', 'LADYLUCK', 'PTSLOT'],
    }

    Object.entries(settleTypePlatforms).forEach(([settleType, platforms]) => {
      if (platforms.includes(platform)) platformBetData.settleType = settleType
    })

    const idData = await this.getIdData({ playerIndex, betIndex, options })

    this.betData[playerIndex] = this.betData[playerIndex] || []
    this.betData[playerIndex][betIndex] = [{
      userId: this.formData[`userId${playerIndex + 1}`],
      ...idData,
      ...platformBetData,
      ...data,
      betAmount,
      betTime,
    }]
  }

  async betAction(args) {
    const {
      action,
      playerIndexes,
      betIndexes = [0],
      timesLoop,
      data,
      options,
      settleResult, // for betNSettle
    } = args

    const playerIndex = playerIndexes ? playerIndexes[0] : 0
    const [betIndex] = betIndexes

    const capitalizedAction = upperFirst(action)

    if (!timesLoop || !this.tests[`${action}Data`]) {
      this[`${action}Data`][playerIndex] = this[`${action}Data`][playerIndex] || []

      if (!options?.[`afterCancel${capitalizedAction}`]) {
        await this[`set${capitalizedAction}Data`]({
          playerIndex,
          betIndex,
          data,
          options,
          settleResult, // for betNSettle
        })
      }

      this.tests[`${action}Data`] = this[`${action}Data`]
    }

    const res = await this.actions[action](this[`${action}Data`][playerIndex][betIndex])

    // for betNSettle
    if (options?.isAsync) {
      if (this.asyncSettleData.firstNo == null) this.asyncSettleData.firstNo = timesLoop

      if (!this.asyncSettleData.res) this.asyncSettleData.res = []
      this.asyncSettleData.res.push(res.data)
    }

    return this.requestPromise(
      {
        action,
        requestName: getRequestName(args),
      },
      res,
      () => this.tests[action]({
        requestNo: this.requestNo,
        res: res.data,
        playerIndex,
        betIndex,
        options,
        timesLoop,
      }),
    )
  }

  bet(args) {
    return this.betAction({
      ...args,
      action: 'bet',
    })
  }

  betForNegativeBalance({ data = {}, options = {}, ...args }) {
    const { betIndexes = [0] } = args;
    [this.negativeBalanceBetIndex] = betIndexes

    const lastBalance = this.tests.lastBalance[0]

    return this.betAction({
      ...args,
      action: 'bet',
      data: {
        odds: 0.1,
        ...data,
        // betAmount: 20,
        betAmount: lastBalance,
      },
      options: {
        ...options,
        betForNegativeBalance: true,
      },
    })
  }

  async getSettleData({
    action = 'settle',
    playerIndexes,
    betIndexes = [0],
    settleInfo = {},
    settleResult,
    options,
  }) {
    const { platform } = this.formData

    const settleData = await Promise.all(this.betData.map(async (playerBetData, playerIdx) => {
      let playerSettleData
      if (playerIndexes) {
        const settleDataInTests = this.tests.settleData
        playerSettleData = settleDataInTests ? settleDataInTests[playerIdx] : []
      }

      if (!playerIndexes || (playerIndexes && playerIndexes.includes(playerIdx))) {
        let updateTime
        return Promise.all(playerBetData.map(async (singleBetData, betIdx) => {
          if (betIndexes.includes(betIdx) || options?.async) {
            if (betIndexes[0] === betIdx) updateTime = moment().utcOffset(8).toISOString(true)

            // "Bet > (Network) Settle Win 5 times" requires the same updateTime for each repeated settle
            if (options?.isAsync) {
              if (this.asyncSettleData.updateTime) {
                updateTime = this.asyncSettleData.updateTime
              } else {
                this.asyncSettleData.updateTime = updateTime
              }
            }

            if (platform === 'JILITABLE' || platform === 'LUCKYPOKER') { // For combined calculation of multi bets
              let totalBetAmount = 0
              let totalWinAmount = 0

              if (betIndexes[0] === betIdx) {
                betIndexes.forEach((betIndex) => {
                  playerBetData[betIndex].forEach((txnBetData) => {
                    const { betAmount } = txnBetData

                    totalBetAmount += betAmount

                    const platformWinAmountLookup = {
                      JILITABLE: betAmount * 9,
                      LUCKYPOKER: betAmount * 2,
                    }

                    if (settleResult) {
                      if (settleResult === 'win') {
                        totalWinAmount += platformWinAmountLookup[platform]
                      } else {
                        totalWinAmount += betAmount * 0
                      }
                    } else {
                      const { winBetIndexes } = settleInfo
                      if (winBetIndexes.includes(betIndex)) {
                        totalWinAmount += platformWinAmountLookup[platform]
                      } else {
                        totalWinAmount += betAmount * 0
                      }
                    }
                  })
                })

                const platformDataLookup = {
                  JILITABLE: {
                    txnId: `${`${moment().valueOf()}`.slice(-11)}${getRandomNumberByDigit(7)}`,
                    turnover: Math.abs(totalBetAmount - totalWinAmount),
                  },
                  LUCKYPOKER: {
                    betCount: playerBetData.length,
                    turnover: totalBetAmount,
                  },
                }

                // "Bet > (Network) Settle Win 5 times"
                if (options?.isAsync && platformDataLookup[platform].txnId) {
                  if (this.asyncSettleData.txnId) {
                    platformDataLookup[platform].txnId = this.asyncSettleData.txnId
                  } else {
                    this.asyncSettleData.txnId = platformDataLookup[platform].txnId
                  }
                }

                return {
                  ...playerBetData[betIdx][0],
                  betAmount: totalBetAmount,
                  winAmount: totalWinAmount,
                  ...platformDataLookup[platform],
                  updateTime,
                }
              }
              return []
            }

            return singleBetData.map((txnBetData, txnIdx) => {
              const {
                txnId,
                betType,
                adjustBetAmount,
                settleType,
              } = txnBetData

              let {
                odds,
                betAmount,
              } = txnBetData

              const { resettleResult, resettleAmountChange } = options || {}

              let realSettleResult
              if (settleResult) {
                realSettleResult = settleResult
              } else if (settleInfo?.winBetIndexes) {
                const { winBetIndexes } = settleInfo
                if (winBetIndexes.includes(betIdx)) {
                  realSettleResult = 'win'
                } else {
                  realSettleResult = 'lose'
                }
              }

              let { unsettleData } = this.tests
              if (action === 'resettle') unsettleData = this.tests.settleData
              unsettleData = unsettleData?.[playerIdx]?.[betIdx]?.[txnIdx]

              let settleResultData = {} // the parameters that are changed by different settleResult
              switch (platform) {
                case 'SEXYBCRT':
                case 'VENUS':
                case 'AELIVECOMM':
                case 'VIACASINO': {
                  // consider action === 'resettle' || 'settle' after unsettle
                  let actionData
                  const { winner } = settleInfo || {}
                  if (winner) {
                    // eslint-disable-next-line no-nested-ternary
                    realSettleResult = winner === betType ? 'win' : winner === 'Tie' ? 'tie' : 'lose'

                    actionData = this.getSettleDataByBetTypeNWinner({
                      betType,
                      winner: unsettleData ? capitalize(unsettleData.winner) : winner,
                      resettleWinner: unsettleData ? winner : null,
                    })
                  } else {
                    let previousSettleResult = settleResult
                    if (unsettleData) {
                      if (platform === 'VIACASINO') {
                        const winnerSettleResultLookup = {
                          1: 'win',
                          2: 'lose',
                          3: 'tie',
                        }
                        previousSettleResult = winnerSettleResultLookup[unsettleData.resultData.winner] // eslint-disable-line no-param-reassign
                      } else {
                        previousSettleResult = lowerCase(unsettleData.status) // eslint-disable-line no-param-reassign
                      }
                    }

                    actionData = this.getSettleDataBySettleResult({
                      action: unsettleData ? 'resettle' : action,
                      settleResult: previousSettleResult,
                      resettleResult: unsettleData ? settleResult : resettleResult,
                      resettleAmountChange,
                    })
                  }

                  ({ odds } = actionData)

                  settleResultData = {
                    win: {
                      ...actionData,
                      ...(platform === 'AELIVECOMM' && {
                        rebate: Number((betAmount * 0.007).toFixed(4)),
                      }),
                    },
                    lose: {
                      ...actionData,
                      ...(platform === 'AELIVECOMM' && {
                        rebate: Number((betAmount * 0.007).toFixed(4)),
                      }),
                    },
                    tie: {
                      ...actionData,
                      ...(platform === 'AELIVECOMM' && {
                        rebate: 0,
                      }),
                    },
                  }
                  break
                }

                case 'PGSLOTOLD':
                case 'PGSLOTMIXED': {
                  odds = 3.3
                  break
                }

                case 'SV388': {
                  let status
                  let matchResult
                  if (settleResult) {
                    if (settleResult === 'win') {
                      matchResult = 'WALA'
                      status = 'WIN'
                    } else if (settleResult === 'lose') {
                      matchResult = 'MERON'
                      status = 'LOSE'
                    } else {
                      matchResult = 'BDD'
                      status = 'TIE'
                    }
                  } else {
                    ({ matchResult } = settleInfo)

                    if (matchResult === 'FTD') {
                      status = 'DRAW'
                      realSettleResult = 'tie'
                    } else if (betType === matchResult) {
                      status = 'WIN'
                      realSettleResult = 'win'
                    } else if (matchResult === 'BDD') {
                      status = 'DRAW'
                      realSettleResult = 'tie'
                    } else {
                      status = 'LOSE'
                      realSettleResult = 'lose'
                    }
                  }

                  settleResultData = {
                    win: {
                      winAmount: Number((betAmount * (1 + Math.abs(odds))).toFixed(4)),
                      turnover: betAmount * 1,
                      validBet1v1: betAmount * 1,
                      result: 1,
                      matchResult,
                      status,
                    },
                    lose: {
                      winAmount: betAmount * 0,
                      turnover: odds < 0 ? Number(Math.abs(betAmount * odds).toFixed(4)) : betAmount * 1,
                      validBet1v1: betAmount * 1,
                      result: -1,
                      matchResult,
                      status,
                    },
                    tie: {
                      winAmount: odds < 0 ? Number(Math.abs(betAmount * odds).toFixed(4)) : betAmount * 1,
                      turnover: betAmount * 0,
                      validBet1v1: betAmount * 0,
                      result: 0,
                      matchResult,
                      status,
                    },
                  }
                  break
                }

                case 'E1SPORT': {
                  const { gameNo } = txnBetData

                  let txnResult
                  if (settleResult) {
                    if (settleResult === 'win') {
                      txnResult = 'WON'
                    } else if (settleResult === 'lose') {
                      txnResult = 'LOSE'
                    } else {
                      txnResult = 'DRAW'
                    }
                  } else {
                    const { winGameNo } = settleInfo

                    if (!gameNo || winGameNo.includes(gameNo)) {
                      txnResult = 'WON'
                      realSettleResult = 'win'
                    } else {
                      txnResult = 'LOSE'
                      realSettleResult = 'lose'
                    }
                  }

                  settleResultData = {
                    win: {
                      txnResult,
                    },
                    tie: {
                      txnResult,
                    },
                    lose: {
                      txnResult,
                    },
                  }
                  break
                }

                case 'HORSEBOOK': {
                  if (!settleResult) {
                    const { runnerNo } = txnBetData
                    const { top3Data } = settleInfo

                    const top3Index = top3Data.findIndex(d => d.runnerNo === runnerNo)
                    const isWinWIN = betType === 'WIN' && top3Data[0].runnerNo === runnerNo
                    const isWinPLC = betType === 'PLC' && top3Index > -1

                    if (isWinWIN || isWinPLC) {
                      realSettleResult = 'win'
                      if (isWinPLC && top3Data[top3Index] && top3Data[top3Index].odds) odds = top3Data[top3Index].odds.PLC
                      if (isWinWIN && top3Data[0] && top3Data[0].odds) odds = top3Data[0].odds.WIN
                    } else {
                      realSettleResult = 'lose'
                    }
                  }

                  const { voidType } = this.tests.voidBetData?.[playerIdx]?.[betIdx]?.[txnIdx] || {}

                  settleResultData = {
                    win: {
                      odds,
                      voidType,
                    },
                    lose: {
                      odds,
                      voidType,
                    },
                  }
                  break
                }

                case 'KINGMAKERSLOT':
                case 'KINGMAKERTABLE': {
                  odds = 1.8
                  break
                }

                case 'YLEGAME': {
                  odds = 2.6
                  break
                }

                case 'SPADEEGAME':
                case 'SPADESLOT': {
                  odds = 1.6
                  break
                }

                case 'JDBTABLE': {
                  odds = 1.2
                  break
                }

                case 'BG': {
                  const BANKER_WIN_GAME_RESULT = 'MjY4NDM1NDY1'
                  const PLAYER_WIN_GAME_RESULT = 'MjY4NDM1NDc0'
                  const TIE_WIN_GAME_RESULT = 'MjY4NDM1NDY4'

                  let orderStatus
                  let gameResult
                  if (settleResult) {
                    if (settleResult === 'win') {
                      odds = 0.95
                      orderStatus = 2
                      gameResult = BANKER_WIN_GAME_RESULT
                    } else if (settleResult === 'tie') {
                      orderStatus = 3
                      gameResult = TIE_WIN_GAME_RESULT
                    } else {
                      odds = 1
                      orderStatus = 4
                      gameResult = PLAYER_WIN_GAME_RESULT
                    }
                  } else {
                    const { winner } = settleInfo

                    if (betType === winner) {
                      switch (betType) {
                        case 'Banker': {
                          odds = 0.95
                          gameResult = BANKER_WIN_GAME_RESULT
                          break
                        }
                        case 'Player': {
                          odds = 1
                          gameResult = PLAYER_WIN_GAME_RESULT
                          break
                        }
                        case 'Tie': {
                          odds = 8
                          gameResult = TIE_WIN_GAME_RESULT
                          break
                        }
                        default:
                          break
                      }
                      orderStatus = 2
                      realSettleResult = 'win'
                    } else if (winner === 'Tie') {
                      orderStatus = 3
                      gameResult = TIE_WIN_GAME_RESULT
                      realSettleResult = 'tie'
                    } else {
                      if (betType === 'Banker') gameResult = PLAYER_WIN_GAME_RESULT
                      if (betType === 'Player') gameResult = BANKER_WIN_GAME_RESULT
                      orderStatus = 4
                      realSettleResult = 'lose'
                    }
                  }

                  settleResultData = {
                    win: {
                      odds,
                      orderStatus,
                      gameResult,
                    },
                    lose: {
                      odds: -1,
                      orderStatus,
                      gameResult,
                    },
                    tie: {
                      odds: 0,
                      orderStatus,
                      gameResult,
                    },
                  }
                  break
                }

                case 'SABA':
                case 'SABAOLD': {
                  if (odds < 0) betAmount = this.formData.betAmount
                  const { oddsType, isParlay } = txnBetData

                  if (isParlay) {
                    odds = odds.reduce((acc, item) => acc * item, 1) - 1
                  } else if (oddsType === '3') {
                    odds -= 1
                  } else if (oddsType === '5') {
                    odds /= 100
                  }

                  if (resettleResult === 'win') {
                    if ((action === 'settle' && resettleAmountChange === 'increased')
                    || (action === 'resettle' && resettleAmountChange === 'decreased')) {
                      settleResultData = {
                        win: { // half won
                          winAmount: Number((odds < 0
                            ? (betAmount * (1 + Math.abs(odds) * 2)) / 2
                            : betAmount * (1 + odds / 2)).toFixed(4)),
                        },
                      }
                    }
                  }

                  break
                }

                case 'RTTABLE':
                case 'PTLIVE': {
                  odds = 0.95 // Bet on Banker
                  break
                }

                case 'LUDO': {
                  odds = 0
                  break
                }

                case 'PPLIVE': {
                  odds = odds ?? 1 // Bet on Player

                  if (settleResult === 'win' && resettleResult === 'win' && resettleAmountChange) {
                    if (action === 'settle') odds = 2
                    if (action === 'resettle') {
                      if (resettleAmountChange === 'increased') odds = 3
                      if (resettleAmountChange === 'decreased') odds = 1
                    }
                  }
                  break
                }

                case 'VRLOTTO': {
                  odds = 0.96

                  if (settleResult === 'win' && resettleResult === 'win' && resettleAmountChange) {
                    if (action === 'settle') {
                      if (resettleAmountChange === 'decreased') odds = 43.1
                    }
                    if (action === 'resettle') {
                      if (resettleAmountChange === 'increased') odds = 43.1
                      if (resettleAmountChange === 'decreased') odds = 0.96
                    }
                  }
                  break
                }

                case 'HOTROAD': {
                  settleResultData = {
                    win: {
                      odds: 0.95,
                      status: 'WIN',
                    },
                    lose: {
                      odds: 1,
                      status: 'LOSE',
                    },
                    tie: {
                      odds: 0,
                      status: 'TIE',
                    },
                  }
                  break
                }

                case 'KINGMAKERMINI': {
                  settleResultData = {
                    win: {
                      odds: 0.95,
                    },
                    lose: {
                      odds: 1,
                    },
                    tie: {
                      odds: 0,
                    },
                  }
                  break
                }

                default:
                  odds = odds ?? 1
                  break
              }

              let newOdds = settleResultData[realSettleResult]?.odds ?? odds
              if (newOdds < 0 && !['SEXYBCRT', 'VENUS', 'AELIVECOMM', 'VIACASINO'].includes(platform)) newOdds = Math.abs(newOdds)

              const winAmountLookup = {
                win: Number((betAmount * (1 + newOdds)).toFixed(4)),
                tie: adjustBetAmount || betAmount,
                lose: 0,
              }

              // the parameters that are fixed whatever settleResult is
              let platformSettleData = {
                betAmount: adjustBetAmount || betAmount,
                winAmount: winAmountLookup[realSettleResult],
                turnover: betAmount * 1,
                ...settleResultData[realSettleResult],
              }

              switch (platform) {
                case 'SEXYBCRT':
                case 'VENUS':
                case 'AELIVECOMM':
                  platformSettleData = {
                    ...platformSettleData,
                    turnover: Number((Math.abs(betAmount * newOdds)).toFixed(4)),
                    winLoss: Number((betAmount * newOdds).toFixed(4)),
                  }
                  break

                case 'VIACASINO': {
                  let turnover = Number((Math.abs(betAmount * newOdds)).toFixed(4))
                  const isMultiBetPlaces = betType.includes(',') // BANKER,BANKER_PAIR
                  if (isMultiBetPlaces) {
                    betAmount /= 2
                    winAmountLookup.win = Number((betAmount * (1 + newOdds)).toFixed(4))
                    platformSettleData.winAmount = winAmountLookup[realSettleResult]
                    turnover = betAmount * 0.95 + betAmount
                  }

                  platformSettleData = {
                    ...platformSettleData,
                    turnover,
                    ...(action === 'resettle' && {
                      resettleTime: moment(updateTime).subtract(200, 'milliseconds').utcOffset(8).toISOString(true),
                    }),
                  }
                  break
                }

                case 'YLEGAME':
                case 'YLFISH':
                  platformSettleData = {
                    ...platformSettleData,
                    profit: (realSettleResult === 'win' ? Number((betAmount * (1 + newOdds)).toFixed(4)) : betAmount * 0) - betAmount,
                  }
                  break

                case 'SPADESLOT':
                  platformSettleData = {
                    ...platformSettleData,
                    hasFreeSpin: options?.hasFreeSpin,
                  }
                  break

                case 'FASTSPINSLOT': {
                  this.txnIdCounter += 1

                  platformSettleData = {
                    ...platformSettleData,
                    refPlatformTxId: txnId,
                    txnId: `${moment().format('yyyyMMDDHHmmssSSS')}${this.txnIdCounter}`,
                    hasFreeSpin: options?.hasFreeSpin,
                  }
                  break
                }

                case 'AELOTTO': {
                  platformSettleData = {
                    ...platformSettleData,
                    resettleTime: unsettleData?.resettleTime,
                  }
                  break
                }

                case 'BG': {
                  platformSettleData = {
                    ...platformSettleData,
                    ...(unsettleData && {
                      resettleResult,
                      resettleTime: moment(updateTime).subtract(200, 'milliseconds').utcOffset(8).toISOString(true),
                    }),
                  }
                  break
                }

                case 'SABA':
                case 'SABAOLD': {
                  platformSettleData = {
                    ...platformSettleData,
                    turnover: odds < 0 ? betAmount * newOdds : betAmount,
                    txTime: moment().subtract(300000, 'milliseconds').utcOffset(8).toISOString(true),
                  }
                  break
                }

                case 'PPLIVE':
                case 'VRLOTTO': {
                  platformSettleData = {
                    ...platformSettleData,
                    resettleTime: moment(updateTime).subtract(200, 'milliseconds').utcOffset(8).toISOString(true),
                  }
                  break
                }

                case 'KINGMAKERSLOT': { // settleType = refPlatformTxId
                  platformSettleData = {
                    ...platformSettleData,
                    txnId: uuidv4().replaceAll('-', ''),
                    refPlatformTxId: txnId,
                    betAmount: this.tests.settleData ? 0 : betAmount,
                  }
                  break
                }

                case 'PTLIVE': { // settleType = roundId
                  this.txnIdCounter += 1
                  platformSettleData.txnId = `${this.txnIdCounter}`
                  break
                }

                case 'PTSLOT': { // settleType = refPlatformTxId
                  this.txnIdCounter += 1

                  platformSettleData = {
                    ...platformSettleData,
                    txnId: `${this.txnIdCounter}`,
                    refPlatformTxId: txnId,
                  }
                  break
                }

                case 'LADYLUCK': { // settleType = refPlatformTxId
                  platformSettleData = {
                    ...platformSettleData,
                    txnId: uuidv4(),
                    refPlatformTxId: txnId,
                  }
                  break
                }

                default:
                  break
              }

              // "Bet > (Network) Settle Win 5 times"
              if (options?.isAsync && (settleType === 'refPlatformTxId' || settleType === 'roundId')) {
                if (this.asyncSettleData.txnId) {
                  platformSettleData.txnId = this.asyncSettleData.txnId
                } else {
                  this.asyncSettleData.txnId = platformSettleData.txnId
                }
              }

              return {
                ...txnBetData,
                ...platformSettleData,
                updateTime,
              }
            })
          }
          return []
        }))
      }
      return playerSettleData || []
    }))

    return settleData
  }

  async settle(args = {}) {
    const {
      action = 'settle',
      playerIndexes,
      betIndexes,
      settleInfo = {},
    } = args

    const { platform } = this.formData

    const settleData = await this.getSettleData({
      action,
      playerIndexes,
      betIndexes,
      settleInfo,
    })

    this.tests[`${action}Data`] = settleData

    const playerRequestName = []
    settleData.forEach((playerSettleData, playerIdx) => {
      if (!playerIndexes || (playerIndexes && playerIndexes.includes(playerIdx))) {
        const splitBets = {}

        if (settleInfo.winBetIndexes) {
          const { winBetIndexes } = settleInfo
          splitBets.win = winBetIndexes
          splitBets.lose = difference(betIndexes, winBetIndexes)
        } else {
          playerSettleData.forEach((singleSettleData, betIdx) => {
            singleSettleData.forEach((txnSettleData, txnIdx) => {
              const { winAmount, status } = txnSettleData
              let settleResult = winAmount ? 'win' : 'lose'
              if (status) settleResult = lowerCase(status)

              if (!splitBets[settleResult]) splitBets[settleResult] = []
              splitBets[settleResult].push(singleSettleData.length > 1 ? [betIdx, txnIdx] : betIdx)
            })
          })
        }

        playerRequestName[playerIdx] = Object.entries(splitBets)
          .map(([key, value]) => getRequestName({
            ...args,
            action,
            playerIndexes: null,
            betIndexes: value,
            settleResult: key,
          })).join(' & ')
      }
    })

    let requestName
    if (playerIndexes) {
      requestName = playerRequestName.map((reqName, playerIdx) => `${getPlayerRequestName(playerIdx)}${reqName}`)
      if (playerIndexes.length > 1) {
        requestName.unshift('')
        requestName = requestName.join('\n')
      } else {
        requestName = requestName.toString()
      }
    } else {
      requestName = playerRequestName.toString()
    }

    const res = await this.actions[action](settleData.flat().flat())

    let extraData
    if (platform === 'JILITABLE' || platform === 'LUCKYPOKER') {
      const settleTxnIds = this.betData[0].flat().map(({ txnId, roundId }) => {
        if (platform === 'LUCKYPOKER') return `${this.actions.userRandomNo}-502-${roundId}-${txnId}`
        return txnId
      })

      extraData = { settleTxnIds }
    }

    return this.requestPromise(
      {
        action,
        requestName,
        extraData,
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async settleAction(args = {}) {
    const {
      action = 'settle',
      playerIndexes,
      betIndexes,
      settleResult,
      timesLoop,
      options,
    } = args

    const playerIndex = playerIndexes ? playerIndexes[0] : 0

    let actionData
    if (timesLoop && this.tests[`${action}Data`]) {
      actionData = this.tests[`${action}Data`]
    } else {
      actionData = await this.getSettleData({
        action,
        options,
        playerIndexes,
        betIndexes,
        settleResult,
      })

      this.tests[`${action}Data`] = actionData

      if (options?.async) actionData = betIndexes.map(betIndex => actionData[playerIndex][betIndex])
    }

    if (playerIndexes) actionData = playerIndexes.map(playerIdx => actionData[playerIdx])
    const res = await this.actions[action](actionData.flat().flat())

    if (options?.isAsync) {
      if (!this.asyncSettleData.res) this.asyncSettleData.res = []
      this.asyncSettleData.res.push(res.data)
    }

    return this.requestPromise(
      {
        action,
        requestName: getRequestName({ ...args, action }),
        ...(options?.async && res?.data?.data?.status !== '0000') && {
          extraData: {
            retrySettle: async () => {
              try {
                await this.settleWin({
                  betIndexes,
                  options: { async: true },
                })
                await this.getBalance({ action: 'settle' })
              } catch (e) {
                console.error(e)
              }
            },
          },
        },
      },
      res,
      () => this.tests[action]({
        requestNo: this.requestNo,
        res: res.data,
        options,
      }),
    )
  }

  settleWin(args) {
    return this.settleAction({ ...args, settleResult: 'win' })
  }

  settleLose(args) {
    return this.settleAction({ ...args, settleResult: 'lose' })
  }

  settleTie(args) {
    return this.settleAction({ ...args, settleResult: 'tie' })
  }

  async action(args = {}, callback) {
    const {
      action,
      playerIndexes,
      betIndexes = [0],
      timesLoop,
      data = {},
    } = args

    const playerIndex = playerIndexes ? playerIndexes[0] : 0

    let actionData
    if (timesLoop) {
      actionData = this.tests[`${action}Data`]
    } else {
      let mapData = this.betData
      if (action === 'unsettle' || action === 'voidSettle') mapData = this.tests.settleData

      actionData = mapData.map((playerBetData, playerIdx) => {
        let playerActionData
        if (playerIndexes) {
          const actionDataInTests = this.tests[`${action}Data`]
          playerActionData = actionDataInTests ? actionDataInTests[playerIdx] : []
        }

        if (!playerIndexes || (playerIndexes && playerIndexes.includes(playerIdx))) {
          let updateTime
          return playerBetData.map((singleBetData, betIdx) => {
            if (betIndexes.includes(betIdx)) {
              if (betIndexes[0] === betIdx) updateTime = moment().utcOffset(8).toISOString(true)

              return singleBetData.map((txnBetData, txnIdx) => ({
                ...callback(txnBetData, { txnIdx, betIdx, playerIdx }),
                ...data,
                updateTime,
              }))
            }
            return []
          })
        }
        return playerActionData || []
      })

      this.tests[`${action}Data`] = actionData

      if (playerIndexes) actionData = playerIndexes.map(playerIdx => actionData[playerIdx])
    }

    const res = await this.actions[action](actionData.flat().flat())

    return this.requestPromise(
      {
        action,
        requestName: getRequestName(args),
      },
      res,
      () => this.tests[action]({
        requestNo: this.requestNo,
        res: res.data,
        playerIndex,
      }),
    )
  }

  voidBet(args) {
    return this.action(
      {
        action: 'voidBet',
        ...args,
      },
      ({ betAmount, adjustBetAmount, ...txnBetData }) => ({
        ...txnBetData,
        betAmount: adjustBetAmount || betAmount,
        voidType: 2,
      }),
    )
  }

  unvoidBet(args) {
    return this.action(
      {
        action: 'unvoidBet',
        ...args,
      },
      ({ betAmount, adjustBetAmount, ...txnBetData }) => ({
        ...txnBetData,
        betAmount: adjustBetAmount || betAmount,
        voidType: 2,
      }),
    )
  }

  unsettle(args) {
    return this.action(
      {
        action: 'unsettle',
        ...args,
      },
      ({ betAmount, adjustBetAmount, ...txnBetData }) => {
        const resettleTime = moment().subtract(2, 'seconds').utcOffset(8).toISOString(true)

        return {
          ...txnBetData,
          betAmount: adjustBetAmount || betAmount,
          resettleTime, // For AELOTTO used
        }
      },
    )
  }

  resettle(args) {
    const { resettleResult } = args.options || {}

    return resettleResult ? this.settleAction({
      ...args,
      action: 'resettle',
      settleResult: resettleResult,
    }) : this.settle({
      ...args,
      action: 'resettle',
    })
  }

  voidSettle(args) {
    return this.action(
      {
        action: 'voidSettle',
        ...args,
      },
      ({ betAmount, adjustBetAmount, ...txnBetData }) => ({
        ...txnBetData,
        betAmount: adjustBetAmount || betAmount,
        voidType: 2,
      }),
    )
  }

  unvoidSettle(args) {
    return this.action(
      {
        action: 'unvoidSettle',
        ...args,
      },
      ({ betAmount, adjustBetAmount, ...txnBetData }) => ({
        ...txnBetData,
        betAmount: adjustBetAmount || betAmount,
        voidType: 2,
      }),
    )
  }

  async cancelAction(args = {}) {
    const {
      action: cancelAction,
      betIndexes = [0],
      timesLoop,
      data,
      options,
      settleResult = 'win', // for betNSettle
    } = args

    const [betIndex] = betIndexes
    const playerIndex = 0

    const capitalizedAction = cancelAction.replace('cancel', '')
    const action = lowerFirst(capitalizedAction)

    if (this.negativeBalanceBetIndex === betIndex) this.negativeBalanceBetIndex = null

    if (options?.[`before${capitalizedAction}`]) {
      await this[`set${capitalizedAction}Data`]({
        playerIndex,
        betIndex,
        data,
        options,
        settleResult, // for betNSettle
      })

      this.tests[`${action}Data`] = this[`${action}Data`]
    }

    let cancelData
    if (timesLoop) {
      cancelData = this.tests[`${cancelAction}Data`]
    } else {
      let updateTime
      cancelData = this[`${action}Data`][playerIndex].map((singleBetData, betIdx) => {
        let result = []
        this[`${cancelAction}Data`][playerIndex] = this[`${cancelAction}Data`][playerIndex] || []

        if (betIndexes.includes(betIdx)) {
          if (betIndexes[0] === betIdx) updateTime = moment().utcOffset(8).toISOString(true)
          result = singleBetData.map(txnBetData => ({
            ...txnBetData,
            updateTime,
          }))
          this[`${cancelAction}Data`][playerIndex][betIdx] = result
        }

        this[`${cancelAction}Data`][playerIndex][betIdx] = this[`${cancelAction}Data`][playerIndex][betIdx] || result
        return result
      })
      this.tests[`${cancelAction}Data`] = cancelData
    }

    const res = await this.actions[cancelAction](cancelData.flat())

    return this.requestPromise(
      {
        action: cancelAction,
        requestName: getRequestName(args),
      },
      res,
      () => this.tests[cancelAction]({
        requestNo: this.requestNo,
        res: res.data,
        betIndexes,
        timesLoop,
        options,
      }),
    )
  }

  cancelBet(args) {
    return this.cancelAction({
      ...args,
      action: 'cancelBet',
    })
  }

  async give(args = {}) {
    const { timesLoop, action = 'give' } = args
    const { userId1: userId, betAmount } = this.formData
    let { platform } = this.formData

    let giveData = [[]]
    if (timesLoop) {
      giveData = this.tests.giveData
    } else {
      let txTime = moment().subtract(4, 'seconds').utcOffset(8).toISOString(true)

      let promotionTxId
      switch (platform) {
        case 'KINGMAKERTABLE': {
          promotionTxId = uuidv4().replace(/-/g, '')
          break
        }

        case 'FASTSPINSLOT': {
          txTime = moment(txTime).millisecond(0).utcOffset(8).toISOString(true);
          ({ txnId: promotionTxId } = await this.getIdData())
          break
        }

        case 'SPADESLOT': {
          txTime = moment(txTime).millisecond(0).utcOffset(8).toISOString(true);
          ({ txnId: promotionTxId } = await this.getIdData())
          break
        }

        case 'PPSLOT': {
          ({ txnId: promotionTxId } = await this.getIdData())
          break
        }

        case 'PGSLOT': {
          promotionTxId = getRandomNumberByDigit(7)
          break
        }

        default: {
          if (platform.startsWith('FC')) platform = 'FC';

          ({ data: { promotionTxId } } = await updatePlatform({
            platform,
            promotionTxId: 1,
          }))
          break
        }
      }

      giveData[0][0] = [{
        userId,
        promotionTxId,
        betAmount,
        txTime,
      }]

      this.tests.giveData = giveData
    }

    const res = await this.actions.give(giveData[0][0])

    return this.requestPromise(
      {
        action: 'give',
        requestName: getRequestName({ ...args, action }),
      },
      res,
      () => this.tests.give({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async refund(args = {}) {
    const {
      action = 'refund',
      playerIndexes,
      timesLoop,
    } = args

    const { platform } = this.formData

    let refundData
    if (timesLoop) {
      refundData = this.tests.refundData
    } else {
      const TXNID_INCR = 2
      const playerLength = playerIndexes ? playerIndexes.length : 1

      let updateTime
      refundData = await Promise.all(Array.from({ length: TXNID_INCR }).map(async (_, i) => {
        if (!i) updateTime = moment().utcOffset(8).toISOString(true)

        const { data: { txnId } } = await updatePlatform({
          platform,
          txnId: playerLength,
        })

        return this.betData.map((playerBetData, playerIdx) => {
          let playerRefundData
          if (playerIndexes) {
            const refundDataInTests = this.tests.refundData
            playerRefundData = refundDataInTests ? refundDataInTests[playerIdx] : []
          }

          if (!playerIndexes || (playerIndexes && playerIndexes.includes(playerIdx))) {
            return playerBetData.map(singleBetData => singleBetData.map(({
              winAmount,
              betAmount,
              odds,
              ...txnBetData
            }) => {
              const { txnId: refundPlatformTxId } = txnBetData
              return {
                ...txnBetData,
                txnId: txnId - playerLength + playerIdx + 1,
                refundPlatformTxId,
                betAmount,
                winAmount: i ? betAmount * (1 + 1) : betAmount * (1 + Math.abs(odds)),
                turnover: betAmount * 0,
                odds: i ? odds : 1,
                updateTime,
              }
            })).flat()
          }
          return playerRefundData || []
        })
      }))

      refundData = Object.values(refundData.reduce((acc, iData) => {
        iData.forEach((playerData, playerIdx) => {
          acc[playerIdx] = acc[playerIdx] || []
          acc[playerIdx].push(playerData)
        })
        return acc
      }, {}))
    }

    this.tests.refundData = refundData

    const res = await this.actions.refund(refundData.flat().flat())

    return this.requestPromise(
      {
        action: 'refund',
        requestName: getRequestName({ ...args, action }),
      },
      res,
      () => this.tests.refund({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async setTipData({ data = {} }) {
    const { platform, userId1: userId, betAmount } = this.formData

    const playerIndex = 0
    const tipIndex = 0

    const { data: { tipTxnId } } = await updatePlatform({
      platform,
      tipTxnId: 1,
    })

    this.tipData[playerIndex] = this.tipData[playerIndex] || []
    this.tipData[playerIndex][tipIndex] = [{
      userId,
      tipTxnId,
      tip: betAmount,
      txTime: moment().subtract(4, 'seconds').utcOffset(8).toISOString(true),
      ...data,
    }]
  }

  tip(args) {
    return this.betAction({
      ...args,
      action: 'tip',
    })
  }

  cancelTip(args) {
    return this.cancelAction({
      ...args,
      action: 'cancelTip',
    })
  }

  adjustBet(args = {}) {
    const { options } = args
    const { betAmount } = this.formData

    return this.action(
      {
        action: 'adjustBet',
        ...args,
      },
      (txnBetData, { txnIdx, betIdx, playerIdx }) => {
        let {
          odds,
          adjustBetAmount = txnBetData.betAmount,
        } = txnBetData

        if (options?.forOddsChange) {
          let computedOdds = odds

          if (txnBetData.oddsType === '5') {
            odds += 3
            computedOdds = odds / 100
          } else if (txnBetData.foBetRule === 2) {
            computedOdds -= 0.03
            odds = Number(computedOdds.toFixed(2))
          } else {
            computedOdds += 0.03
            odds = Number(computedOdds.toFixed(2))
          }

          adjustBetAmount = odds < 0 ? Number(Math.abs(betAmount * computedOdds).toFixed(4)) : txnBetData.betAmount * 1

          this.betData[playerIdx][betIdx][txnIdx] = {
            ...txnBetData,
            odds,
            adjustBetAmount,
          }
        }

        return {
          ...txnBetData,
          odds,
          betAmount: adjustBetAmount || betAmount,
          adjustAmount: Number((txnBetData.betAmount - adjustBetAmount).toFixed(4)),
        }
      },
    )
  }

  freeSpin(args) {
    const { platform } = this.formData
    return this.action(
      {
        action: 'freeSpin',
        ...args,
      },
      ({
        txnId: refPlatformTxId,
        betAmount,
        winAmount,
        txnIdPrefix,
        ...txnBetData
      }) => {
        let platformFreeSpinData = {}

        switch (platform) {
          case 'SPADESLOT':
            platformFreeSpinData = {
              txnId: `${moment().format('yyyyMMDDHHmmssSSS')}${getRandomNumberByDigit(6)}`,
              refPlatformTxId,
              winAmount: betAmount * 1.6,
            }
            break

          case 'FASTSPINSLOT': {
            const { count, sequence } = args
            this.txnIdCounter += 1
            this.roundIdCounter += 1

            platformFreeSpinData = {
              txnId: this.txnIdCounter,
              roundId: this.roundIdCounter,
              txnIdPrefix: moment().format('yyyyMMDDHHmmssSSS'),
              refPlatformTxId: `${txnIdPrefix}${refPlatformTxId}`,
              count,
              sequence,
              winAmount: sequence % 2 ? betAmount * 2 : 0,
            }
            break
          }

          default:
            break
        }

        return {
          ...txnBetData,
          ...platformFreeSpinData,
        }
      },
    )
  }

  async setBetNSettleData({
    playerIndex = 0,
    betIndex,
    data = {},
    options,
    settleResult,
  }) {
    const { platform } = this.formData
    const betAmount = Number((data.betAmount || this.formData.betAmount).toFixed(4))

    const nowTime = moment()

    let betTime = moment(nowTime).subtract(100, 'milliseconds').utcOffset(8).toISOString(true)
    let updateTime = nowTime.utcOffset(8).toISOString(true)

    const idData = await this.getIdData({
      action: 'betNSettle',
      playerIndex,
      betIndex,
      options,
    })

    let odds = 1
    switch (platform) {
      case 'PGSLOTMIXED':
      case 'PGSLOT': {
        odds = 2.3
        break
      }

      case 'PGTABLE': {
        odds = 0.95 // Bet on Banker
        break
      }

      case 'YLFISH': {
        odds = 9.5
        break
      }

      case 'JILISLOT':
      case 'JILIFISH': {
        betTime = moment(betTime).millisecond(0).utcOffset(8).toISOString(true)
        odds = 3
        break
      }

      case 'AWSEGAME':
      case 'AWSSLOT':
      case 'AWSTABLE': {
        betTime = moment(betTime).millisecond(0).utcOffset(8).toISOString(true)
        odds = 0.3
        break
      }

      case 'SPADEFISH': {
        betTime = moment(betTime).millisecond(0).utcOffset(8).toISOString(true)
        odds = 0.6
        break
      }

      case 'ILOVEU':
      case 'JDBEGAME':
      case 'JDBSLOT':
      case 'JDBFISH':
      case 'GTFSLOT':
      case 'GTFFISH':
      case 'FCEGAME':
      case 'FCFISH':
      case 'FCSLOT': {
        betTime = moment(betTime).millisecond(0).utcOffset(8).toISOString(true)
        break
      }

      default:
        break
    }

    this.betNSettleData[playerIndex] = this.betNSettleData[playerIndex] || []

    // "(Network) BetNSettle Win 5 times" requires the same updateTime for each repeated settle
    if (options?.isAsync) {
      if (this.asyncSettleData.updateTime) {
        updateTime = this.asyncSettleData.updateTime
      } else {
        this.asyncSettleData.updateTime = updateTime
      }
    }

    this.betNSettleData[playerIndex][betIndex] = [{
      userId: this.formData[`userId${playerIndex + 1}`],
      ...idData,
      ...data,
      betAmount,
      winAmount: settleResult === 'win' ? Number((data.winAmount ?? betAmount * (1 + odds)).toFixed(4)) : 0,
      turnover: betAmount * 1,
      betTime,
      updateTime,
    }]
  }

  betNSettleWin(args) {
    return this.betAction({
      ...args,
      action: 'betNSettle',
      settleResult: 'win',
    })
  }

  betNSettleLose(args) {
    return this.betAction({
      ...args,
      action: 'betNSettle',
      settleResult: 'lose',
    })
  }

  betNSettleForNegativeBalance({ data = {}, options = {}, ...args }) {
    const { betIndexes = [0] } = args;
    [this.negativeBalanceBetIndex] = betIndexes

    const lastBalance = this.tests.lastBalance[0]

    return this.betAction({
      ...args,
      action: 'betNSettle',
      settleResult: 'lose',
      data: {
        ...data,
        // betAmount: 20,
        betAmount: lastBalance,
      },
      options: {
        ...options,
        betForNegativeBalance: true,
      },
    })
  }

  cancelBetNSettle(args) {
    return this.cancelAction({
      ...args,
      action: 'cancelBetNSettle',
    })
  }
}
