import PlatformActions from './PlatformActions'

export default class AELIVECOMMActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'LIVE',
      // gameName: this.gameName, // cancelBet do NOT have this field
      gameCode: 'AE-LIVE-001',
      platform: 'AELIVECOMM',
    }

    this.gameName = 'BaccaratClassic'

    this.constantGameInfo = {
      tableId: 1,
      dealerDomain: 'Mexico',
    }

    this.constantTipInfo = {
      giftId: 'edb85653-5464-4379-8e13-d414fc0b9b3b',
      giftName: 'Kiss',
      tableId: 2,
      dealerDomain: 'Mexico',
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      gameName = this.gameName,
      gameCode = this.constantTxnData.gameCode,
      userId,
      txnId,
      roundId,
      betType = 'Banker',
      betTime,
      betAmount,
      roundStartTime,
    }) => ({
      ...this.constantTxnData,
      gameName,
      gameCode,
      userId,
      platformTxId: `BAC-${txnId}`,
      roundId: `Mexico-01-GA${roundId}`,
      betType,
      currency,
      betTime,
      betAmount,
      gameInfo: {
        ...this.constantGameInfo,
        streamerId: '',
        roundStartTime,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      gameName = this.gameName,
      gameCode = this.constantTxnData.gameCode,
      userId,
      txnId,
      updateTime,
      roundId,
      betType = 'Banker',
      betTime,
      turnover,
      betAmount,
      winAmount,
      rebate,
      result,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData,
      gameName,
      gameCode,
      userId,
      platformTxId: `BAC-${txnId}`,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      roundId: `Mexico-01-GA${roundId}`,
      betType,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      rebate,
      gameInfo: {
        ...this.constantGameInfo,
        result,
        roundStartTime,
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      gameName = this.gameName,
      gameCode = this.constantTxnData.gameCode,
      userId,
      txnId,
      updateTime,
      roundId,
      betType = 'Banker',
      betTime,
      turnover,
      betAmount,
      winAmount,
      rebate,
      result,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData,
      gameName,
      gameCode,
      userId,
      platformTxId: `BAC-${txnId}`,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      roundId: `Mexico-01-GA${roundId}`,
      betType,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      rebate,
      gameInfo: {
        ...this.constantGameInfo,
        result,
        roundStartTime,
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `BAC-${txnId}`,
      roundId: `Mexico-01-GA${roundId}`,
      updateTime,
      voidType,
      betAmount,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      roundStartTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `BAC-${txnId}`,
      roundId: `Mexico-01-GA${roundId}`,
      gameInfo: {
        ...this.constantGameInfo,
        streamerId: '',
        roundStartTime,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getGiveMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      betAmount,
      promotionTxId,
      txTime,
    }) => ({
      platform: 'AELIVECOMM',
      currency,
      userId,
      amount: betAmount, // betAmount.toFixed(1)
      promotionTxId: `W${promotionTxId}`, // ex: W2626000000014
      promotionId: '336',
      promotionTypeId: '3',
      txTime,
    }))

    const message = {
      action: 'give',
      txns,
    }

    return JSON.stringify(message)
  }

  getTipMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      tipTxnId,
      tip,
      txTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${tipTxnId}`, // ex id: 6665
      tip,
      currency,
      txTime,
      tipInfo: this.constantTipInfo,
    }))

    const message = {
      action: 'tip',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelTipMessage(txnList) {
    const txns = txnList.map(({
      userId,
      tipTxnId,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${tipTxnId}`,
      tipInfo: this.constantTipInfo,
    }))

    const message = {
      action: 'cancelTip',
      txns,
    }

    return JSON.stringify(message)
  }
}
