import PlatformActions from './PlatformActions'

export default class YESBINGOFISHActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'FH',
      gameName: 'OceanPhoenix',
      gameCode: 'YesBingo-FH-001',
      platform: 'YESBINGO',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${txnId}`, // 1107055475
      roundId: `${roundId}`, // 89228771
      betType: null,
      currency,
      betTime, // 2022-07-07T18:05:24.138+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, // 2022-07-07T18:05:24.889+08:00
      requireAmount: betAmount,
      gameInfo: {
        roomType: 1,
      },
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${txnId}`,
      roundId: `${roundId}`,
      updateTime,
      betAmount,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
