<template>
  <div id="test-result">
    <AntResult :class="['test-result', testStatus === 'initial' && 'initial']" :status="status">
      <template v-if="testStatus === 'initial'" #icon>
        <FormIcon class="form-icon" />
      </template>

      <template #title>
        <div class="result-title-desc">
          {{ t(`test-result-${testStatus}`) }}
        </div>
      </template>

      <template #subTitle>
        <div class="result-subtitle-desc">
          {{ t(`result-subtitle-${testStatus}`)}}
        </div>

        <div v-if="testStatus !== 'initial'" class="statistic-or-error">
          <div class="result-platform">{{ platformData.name }}</div>

          <div v-if="errorData">
            <div class="error-message">
              <span v-if="['FetchError', 'ContentTypeError', 'AbortError'].includes(errorData.name)">
                {{ errorData.name && `${errorData.name}: ${errorData.message}` }}
              </span>
              <span
                v-else
                v-html="errorData.stack.replaceAll('\n', '<br>').replaceAll(' ', '\u{2000}')"
              ></span>
            </div>
            <div v-if="['FetchError', 'ContentTypeError', 'AbortError'].includes(errorData.name)" class="troubleshoot">
              <div class="troubleshoot-title">{{ t('common-causes-of-error') }}</div>
              <ul class="troubleshoot-list">
                <li>{{ t('cause-callbackURL') }} <span class="error-type">(FetchError)</span></li>
                <li>{{ t('cause-content-type') }} <code>application/json</code> <span class="error-type">(ContentTypeError)</span></li>
                <li>{{ t('cause-timeout') }} <span class="error-type">(AbortError)</span></li>
              </ul>
            </div>
          </div>

          <AntRow
            v-if="testStatus === 'pass' || testStatus === 'fail'"
            :gutter="16"
            justify="center"
            class="statistic-row"
          >
            <AntCol :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                <AntStatistic :title="t('passed-count')" value=" ">
                  <template #prefix>
                    <AntBadge
                      class="warning-count"
                      :title="t('warning')"
                      :count="warningMessages.length"
                      @click="onToggleWarningCount"
                    >
                      <span class="count-number">{{ passedCount }}</span>
                    </AntBadge>
                  </template>
                </AntStatistic>
            </AntCol>
            <AntCol :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
              <AntStatistic :title="t('failed-count')" :value="failedCount" />
            </AntCol>
            <slot></slot>
          </AntRow>
          <AntRow :gutter="16" justify="center" v-show="showWarningMessage">
            <AntAlert
              class="warning-message"
              type="warning"
              :message="t('warning')"
              @close="onToggleWarningCount"
              showIcon
            >
              <template #description>
                <div v-html="warningMessages.join('<br>')" />
              </template>
            </AntAlert>
          </AntRow>
        </div>
      </template>
    </AntResult>

    <table id="exportable-test-result-table" class="hide">
      <thead>
        <tr class="platform">
          <th colspan="2">{{ platformData.name }}</th>
        </tr>
        <tr class="result">
          <th colspan="2">
            <b>{{ t(`test-result-${testStatus}`) }}</b>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="errorData">
          <td
            colspan="2"
            class="error-message"
            v-html="errorData.stack.replaceAll('\n', '<br>')"
          >
          </td>
        </tr>
        <template v-else>
          <tr>
            <td>{{ t('passed-count') }}</td>
            <td>{{ t('failed-count') }}</td>
          </tr>
          <tr>
            <td class="counts">{{ passedCount }}{{ warningMessages.length ? ` (${t('warning')}${t('colon')}${warningMessages.length})` : '' }}</td>
            <td class="counts">{{ failedCount }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  ref,
  inject,
  watch,
} from 'vue'

import { isEqual, cloneDeep } from 'lodash'

import {
  Row as AntRow,
  Col as AntCol,
  Result as AntResult,
  Statistic as AntStatistic,
  Alert as AntAlert,
  Badge as AntBadge,
} from 'ant-design-vue'

import FormIcon from '@ant-design/icons-vue/lib/icons/FormOutlined'

export default {
  name: 'TestResult',
  components: {
    AntRow,
    AntCol,
    AntResult,
    AntStatistic,
    AntAlert,
    AntBadge,
    FormIcon,
  },
  props: {
    platformData: {
      type: Object,
      default() {
        return {}
      },
    },
    testReportData: Array,
    errorData: Object,
  },
  setup(props) {
    const t = inject('t')

    const testStatus = ref('initial')
    const status = ref()
    const passedCount = ref(0)
    const failedCount = ref(0)
    const warningMessages = ref([])
    const showWarningMessage = ref(false)

    watch(() => cloneDeep(props.errorData), (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal)) {
        testStatus.value = 'error'
        status.value = 'warning'
      }
    })

    watch(() => cloneDeep(props.testReportData), (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal) && !props.errorData) {
        warningMessages.value = []
        showWarningMessage.value = false

        let countTotal = 0
        let countFail = 0

        newVal.forEach(({ requests }, scenarioIdx) => {
          requests.forEach(({ tests }, requestIdx) => {
            countTotal += tests.length
            tests.forEach(({ passed, warning }, testIdx) => {
              if (!passed) countFail += 1
              if (warning) warningMessages.value.push(`${props.platformData.abbr}-${scenarioIdx + 1}-${requestIdx + 1}-${testIdx + 1}: ${warning}`)
            })
          })
        })

        if (countFail) {
          testStatus.value = 'fail'
          status.value = 'error'
        } else {
          testStatus.value = 'pass'
          status.value = 'success'
        }

        passedCount.value = countTotal - countFail
        failedCount.value = countFail
      }
    })

    const onToggleWarningCount = () => showWarningMessage.value = !showWarningMessage.value

    return {
      t,
      testStatus,
      status,
      passedCount,
      failedCount,
      warningMessages,
      showWarningMessage,
      onToggleWarningCount,
    }
  },
}
</script>

<style>
.test-result {
  padding: 0;
}

.test-result.initial {
  padding: 40px 0;
}

.form-icon {
  color: #1890ff;
}

.result-platform {
  width: 100%;
  color: #000;
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 1.2px;
  padding-top: 24px;
  margin-bottom: 12px;
  border-top: 3px #eee solid;
}

.result-title-desc {
  margin-bottom: 8px;
}

.result-subtitle-desc {
  margin-bottom: 28px;
}

.test-result .ant-result-content {
  padding: 28px 32px;
}

.statistic-row {
  margin-bottom: 20px;
}

.statistic-or-error {
  max-width: 80%;
  margin: auto auto 28px;
}

.count-number {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.warning-count .ant-badge-count {
  background: #faad14;
  cursor: pointer;
}

.warning-message {
  text-align: left;
}

.warning-message.ant-alert-with-description .ant-alert-message {
  font-size: 15px;
  color: rgb(0, 0, 0);
}

.warning-message.ant-alert-with-description .ant-alert-icon {
  font-size: 18px;
  margin-right: 8px;
}

.error-message {
  min-width: 60%;
  color: rgba(0, 0, 0, 0.85);
  background: #fff2f2;
  text-align: left;
  border-radius: 8px;
  padding: 20px 36px;
  margin-bottom: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.error-type {
  color: rgba(0, 0, 0, 0.45);
}

.troubleshoot {
  color: rgba(0, 0, 0, 0.85);
  padding: 0 36px;
  margin-bottom: 40px;
  text-align: left;
}

.troubleshoot-title {
  font-size: 15px;
  font-weight: 800;
  letter-spacing: 1px;
}

.troubleshoot-list {
  margin: 8px 0 0;
  font-size: 14px;
}

.hide {
  visibility: hidden;
  position: fixed;
  left: -10000px;
  top: 0px;
  border: 0px;
}

#exportable-test-result-table {
  font-size: 10px;
  text-align: center;
}

#exportable-test-result-table .platform {
  font-size: 14px;
}

#exportable-test-result-table .result {
  font-size: 14px;
  background: #fffddb;
}

#exportable-test-result-table .counts {
  width: 50%;
}
</style>
