import PlatformActions from './PlatformActions'

export default class KINGMAKERSLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'KM-SLOT-001',
      platform: 'KINGMAKER',
    }

    this.gameName = 'Sugar Blast'
  }

  getBetMessage(txnList) {
    const { currency, userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `SLOT-SB-${roundId}`, // SLOT-SB-rglM1J6mAMDVL
      roundId: `SLOT-SB-${roundId}`, // SLOT-SB-rglM1J6mAMDVL
      betType: null,
      currency,
      betTime, // 2023-01-18T12:35:05.005+08:00
      betAmount,
      gameInfo: {
        extension1: `${userIdPrefix}`,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      updateTime,
      turnover,
      betAmount,
      winAmount,
      isEndRound,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `slot-sb${txnId}`, // slot-sb0005f282572b86bb3416ae91888b0000
      refPlatformTxId: `SLOT-SB-${roundId}`,
      settleType: 'refPlatformTxId',
      updateTime, // 2023-01-18T12:34:07.646+08:00
      roundId: `SLOT-SB-${roundId}`, // SLOT-SB-rglM1J6mAMDVL
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        reason: isEndRound ? 'close' : 'settle',
        isEndRound: !!isEndRound,
        agentId: `${userIdPrefix}`,
        link: `https://fake.supports.kingdomhall729.com/plays/${roundId}/by_reference_number?transid=slot-${txnId}`,
        refId: `slot-sb${txnId}`,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `SLOT-SB-${roundId}`, // SLOT-SB-rglM1J6mAMDVL
      roundId: `SLOT-SB-${roundId}`, // SLOT-SB-rglM1J6mAMDVL
      gameInfo: {
        extension1: `${userIdPrefix}`,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
