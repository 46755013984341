const getAssertionResult = (actual, expected, testName, assertion) => ({
  passed: assertion && actual != null,
  message: testName,
  actual,
  expected,
})

export default {
  getAssertionResult,
  equal: (actual, expected, testName) => getAssertionResult(actual, expected, testName, actual === expected),
  aboutEqual: (actual, expected, tolerance = 0, testName) => {
    // actual += 0.1 // eslint-disable-line no-param-reassign
    let assertion = actual >= expected - tolerance && actual <= expected + tolerance
    if (expected < 1 && expected >= 0) assertion = actual >= 0 && actual <= expected + tolerance
    return getAssertionResult(actual, expected, testName, assertion)
  },
  below: (actual, expected, testName) => getAssertionResult(actual, expected, testName, actual < expected),
  above: (actual, expected, testName) => getAssertionResult(actual, expected, testName, actual > expected),
  aboveEqual: (actual, expected, testName) => getAssertionResult(actual, expected, testName, actual >= expected),
  match: (actual, expected, testName) => getAssertionResult(actual, expected, testName, new RegExp(expected).test(actual)),
}
