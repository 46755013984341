import axios from 'axios'

export default class PlatformActions {
  constructor(formData) {
    this.formData = formData
  }

  async sendRequest(message) {
    const { callbackUrl, key } = this.formData

    const reqBody = {
      key,
      message,
    }

    try {
      const res = await axios.post(`/proxy/${callbackUrl}`, reqBody)
      console.log('sendRequest res', res)
      return res
    } catch (error) { // 50X error
      const {
        name,
        message: msg,
        stack,
        config,
      } = error

      const res = {
        data: {
          error: {
            name,
            message: msg,
            stack,
          },
        },
        config,
      }

      console.log('sendRequest error', res)

      return res
    }
  }

  getGetBalanceMessage(userId) {
    const { userId1 } = this.formData

    const message = {
      action: 'getBalance',
      userId: userId || userId1,
    }
    return JSON.stringify(message)
  }

  getBalance(userId) {
    return this.sendRequest(this.getGetBalanceMessage(userId))
  }

  bet(txnList) {
    return this.sendRequest(this.getBetMessage(txnList))
  }

  settle(txnList) {
    return this.sendRequest(this.getSettleMessage(txnList))
  }

  voidBet(txnList) {
    return this.sendRequest(this.getVoidBetMessage(txnList))
  }

  unvoidBet(txnList) {
    return this.sendRequest(this.getUnvoidBetMessage(txnList))
  }

  unsettle(txnList) {
    return this.sendRequest(this.getUnsettleMessage(txnList))
  }

  resettle(txnList) {
    return this.sendRequest(this.getResettleMessage(txnList))
  }

  voidSettle(txnList) {
    return this.sendRequest(this.getVoidSettleMessage(txnList))
  }

  unvoidSettle(txnList) {
    return this.sendRequest(this.getUnvoidSettleMessage(txnList))
  }

  cancelBet(txnList) {
    return this.sendRequest(this.getCancelBetMessage(txnList))
  }

  adjustBet(txnList) {
    return this.sendRequest(this.getAdjustBetMessage(txnList))
  }

  betNSettle(txnList) {
    return this.sendRequest(this.getBetNSettleMessage(txnList))
  }

  cancelBetNSettle(txnList) {
    return this.sendRequest(this.getCancelBetNSettleMessage(txnList))
  }

  give(txnList) {
    return this.sendRequest(this.getGiveMessage(txnList))
  }

  tip(txnList) {
    return this.sendRequest(this.getTipMessage(txnList))
  }

  cancelTip(txnList) {
    return this.sendRequest(this.getCancelTipMessage(txnList))
  }

  refund(txnList) {
    return this.sendRequest(this.getRefundMessage(txnList))
  }

  freeSpin(txnList) {
    return this.sendRequest(this.getFreeSpinMessage(txnList))
  }

  adjustAmt(txnList) {
    return this.sendRequest(this.getAdjustAmtMessage(txnList))
  }

  cancelAdjustAmt(txnList) {
    return this.sendRequest(this.getCancelAdjustAmtMessage(txnList))
  }
}
