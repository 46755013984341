import PlatformActions from './PlatformActions'

export default class EVOLUTIONActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'LIVE',
      gameCode: 'EVOLUTION-LIVE-120',
      platform: 'EVOLUTION',
    }

    this.gameName = 'Baccarat A'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 692871043156208180, 692871043156210180
      roundId, // 177fcb9d6735173a3d1227e7
      betType: null,
      currency,
      betTime, // 2023-06-20T15:52:13.000+08:00
      betAmount,
      gameInfo: {
        betId: `D${txnId}`,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 692871043156208180, 692871043156210180
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2023-08-29T15:58:35.797+08:00
      roundId, // 177fcb9d6735173a3d1227e7
      betType: null,
      betTime, // 2023-08-29T15:58:01.036+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        betId: `D${txnId}`,
        settleId: `C${txnId}`,
        isEndRound: true,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId,
      gameInfo: {
        betId: `D${txnId}`,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
