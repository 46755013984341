export default [
  // {
  //   name: 'Mixed Bet + Settle and BetNSettle',
  //   functionName: 'mixed_bet_settle_betNSettle',
  // },
  // {
  //   name: 'Bet + Settle > BetNSettle (CancelBet) > Bet + Settle & CancelBetNSettle',
  //   functionName: 'mixed_bet_settle_betNSettle_2',
  // },
  {
    name: 'Place 30 bets > Settle (async)',
    functionName: 'mixed_bet30_settle30Async',
  },
]
