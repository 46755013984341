import PlatformAdjustBetTestScenarios from './PlatformAdjustBetTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class E1SPORTTestScenarios extends PlatformAdjustBetTestScenarios {
  async betOnMarketType1_adjustBet_settleWin_unsettle_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { marketType: 1 } })
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleLose_unsettle_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({
          data: { oddsMode: 3 },
          options: { positiveOdds: true },
        })
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleLose_unsettle_settleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({
          data: { oddsMode: 2 },
          options: { positiveOdds: true },
        })
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin_voidSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({
          data: { oddsMode: 3 },
          options: { positiveOdds: true },
        })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.voidSettle()
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async cancelBet_betOnHKOddsMode() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.cancelBet({
          data: { oddsMode: 2 },
          options: {
            beforeBet: true,
            positiveOdds: true,
          },
        })
        await this.requests.bet({ options: { afterCancelBet: true } })
        await this.requests.getBalance({ action: 'betAfterCancelBet' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_unsettle1_settleLose1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { oddsMode: 2 },
          options: { positiveOdds: true },
        })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          data: { oddsMode: 2 },
          options: {
            positiveOdds: true,
            differentRound: true,
          },
        })

        await this.requests.unsettle({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleLose({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_resettleLose1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { oddsMode: 2 },
          options: { positiveOdds: true },
        })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          data: { oddsMode: 2 },
          options: {
            positiveOdds: true,
            differentRound: true,
          },
        })

        await this.requests.resettle({
          betIndexes: [0],
          options: { resettleResult: 'lose' },
        })

        await this.requests.getBalance({
          action: 'resettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_adjustBet1_settleWin1_bet2_settleWin2_bet3_adjustBet3_voidBet3() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { gameNo: 1 },
        })
        await this.requests.adjustBet({ betIndexes: [0] })
        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.bet({
          betIndexes: [1],
          data: { gameNo: 2 },
          options: { positiveOdds: true },
        })
        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.bet({
          betIndexes: [2],
          data: { gameNo: 3 },
        })
        await this.requests.adjustBet({ betIndexes: [2] })
        await this.requests.voidBet({ betIndexes: [2] })
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async multiBets_bet20_settle20() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = 20
        const DIVISOR = 4

        const negativeOddsBetIndexArr = []
        for (let i = 0; i < BET_LENGTH / DIVISOR; i += 1) {
          negativeOddsBetIndexArr.push(DIVISOR * i)
        }

        const betIndexes = []
        for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
          const isNegativeOdds = betIndex % DIVISOR === 0

          await this.requests.bet({
            betIndexes: [betIndex],
            ...(!isNegativeOdds && {
              options: { positiveOdds: true },
              data: { gameNo: betIndex % DIVISOR },
            }),
          })

          if (isNegativeOdds) await this.requests.adjustBet({ betIndexes: [betIndex] })

          betIndexes.push(betIndex)
        }

        await this.requests.settle({
          betIndexes,
          settleInfo: { winGameNo: [2, 3] },
        })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }
}
