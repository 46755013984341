import JDBTABLEActions from './JDBTABLEActions'

export default class SPRIBEActions extends JDBTABLEActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'EGAME',
      gameCode: 'SPRIBE-EGAME-001',
      platform: 'SPRIBE',
    }

    this.gameName = 'Aviator'
  }
}
