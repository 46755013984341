export default [
  {
    name: 'Bet > Settle Win',
    functionName: 'bet_settleWin',
  },
  {
    name: 'Bet > Settle Lose',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Win 5 times',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times',
    functionName: 'bet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > Settle Lose > Unsettle > Settle Win',
    functionName: 'bet_settleLose_unsettle_settleWin',
  },
  {
    name: 'Bet > Settle Win > Unsettle > Settle Lose',
    functionName: 'bet_settleWin_unsettle_settleLose',
  },
  {
    name: 'Bet > VoidBet',
    functionName: 'bet_voidBet',
  },
  {
    name: 'Bet > VoidBet 5 times',
    functionName: 'bet_voidBet5Times',
  },
  // {
  //   name: 'Bet > Settle Win > Unsettle > VoidBet',
  //   functionName: 'bet_settleWin_unsettle_voidBet',
  // },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Multi Bets - Place 5 bets > Settle Win on TXN 5 & Settle Lose on TXN 1, TXN 2, TXN 3, TXN 4',
    functionName: 'multiBets_bet1_bet2_bet3_bet4_bet5_settle',
  },
  {
    name: 'Multi Bets - Place 5 bets > VoidBet',
    functionName: 'multiBets_bet1_bet2_bet3_bet4_bet5_voidBet',
  },
  {
    name: 'Multi Bets - Place 1 bet with 5 TXN > Settle',
    functionName: 'multiTxnsIn1Bet_bet_settle',
  },
  {
    name: 'Multi Bets - Place 1 bet with 5 TXN > VoidBet',
    functionName: 'multiTxnsIn1Bet_bet_voidBet',
  },
  {
    name: 'Multi Bets - Place 1 bet with 5 TXN > CancelBet',
    functionName: 'multiTxnsIn1Bet_bet_cancelBet',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
