import PlatformActions from './PlatformActions'

export default class GTFSLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameName: 'The Third Prince',
      gameCode: 'GTF-SLOT-007',
      platform: 'GTF',
    }

    this.constantGameInfo = {
      isEndRound: true,
      hasFreeGame: '0',
      denom: '0.2',
      systemTakeWin: '0',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 2203196
      roundId, // 5250178407290
      betType: '',
      currency,
      betTime, // 2024-03-29T11:00:56.000+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      jackpotBetAmount: 0,
      jackpotWinAmount: 0,
      updateTime, // 2024-03-29T11:00:57.007+08:00
      requireAmount: betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId,
      updateTime,
      betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
