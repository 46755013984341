export default [
  {
    name: 'Bet > AdjustBet > Settle Win',
    functionName: 'bet_adjustBet_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose',
    functionName: 'bet_adjustBet_settleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie',
    functionName: 'bet_adjustBet_settleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Win 5 times',
    functionName: 'bet_adjustBet_settleWin5Times',
  },
  {
    name: 'Bet > AdjustBet > (Network) Settle Win 5 times',
    functionName: 'bet_adjustBet_asyncSettleWin5Times',
  },
  // {
  //   name: 'Bet > AdjustBet > Settle Lose > Unsettle > Settle Win',
  //   functionName: 'bet_adjustBet_settleLose_unsettle_settleWin',
  // },
  // {
  //   name: 'Bet > Settle Lose > Unsettle > Settle Win (EU oddsMode = 3)',
  //   functionName: 'bet_settleLose_unsettle_settleWin',
  // },
  // {
  //   name: 'Bet > Settle Lose > Unsettle > Settle Tie (HK oddsMode = 2)',
  //   functionName: 'bet_settleLose_unsettle_settleTie',
  // },
  // {
  //   name: 'Bet > AdjustBet > Settle Win > Unsettle > Settle Lose',
  //   functionName: 'bet_adjustBet_settleWin_unsettle_settleLose',
  // },
  // {
  //   name: 'Bet > AdjustBet > Settle Win > Unsettle > Settle Lose (marketType = 1)',
  //   functionName: 'betOnMarketType1_adjustBet_settleWin_unsettle_settleLose',
  // },
  // {
  //   name: 'Bet > AdjustBet > Settle Win > Unsettle > Settle Tie',
  //   functionName: 'bet_adjustBet_settleWin_unsettle_settleTie',
  // },
  // {
  //   name: 'Bet > AdjustBet > Settle Tie > Unsettle > Settle Win',
  //   functionName: 'bet_adjustBet_settleTie_unsettle_settleWin',
  // },
  {
    name: 'Bet > AdjustBet > VoidBet',
    functionName: 'bet_adjustBet_voidBet',
  },
  {
    name: 'Bet > AdjustBet > VoidBet 5 times',
    functionName: 'bet_adjustBet_voidBet5Times',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > VoidSettle',
    functionName: 'bet_adjustBet_settleWin_voidSettle',
  },
  {
    name: 'Bet > Settle Win > VoidSettle (EU oddsMode = 3)',
    functionName: 'bet_settleWin_voidSettle',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > VoidSettle',
    functionName: 'bet_adjustBet_settleLose_voidSettle',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > VoidSettle 5 times',
    functionName: 'bet_adjustBet_settleWin_voidSettle5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'CancelBet > Bet (HK oddsMode = 2)',
    functionName: 'cancelBet_betOnHKOddsMode',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  // {
  //   name: 'Negative Balance - Bet > AdjustBet > Settle Win > Unsettle > Settle Lose',
  //   functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_unsettle1_settleLose1_settleWin2',
  // },
  // {
  //   name: 'Negative Balance - Bet > Settle Win > Unsettle > Settle Lose (HK oddsMode = 2)',
  //   functionName: 'negativeBalance_bet1_settleWin1_bet2_unsettle1_settleLose1_settleWin2',
  // },
  {
    name: 'Multi Bets - Place 3 bets on 3 games > Settle Win on Game 1, Game 2 > VoidBet on Game 3',
    functionName: 'multiBets_bet1_adjustBet1_settleWin1_bet2_settleWin2_bet3_adjustBet3_voidBet3',
  },
  {
    name: 'Multi Bets - Place 20 bets > Settle 20 bets',
    functionName: 'multiBets_bet20_settle20',
  },
  {
    name: 'Odds Change - Bet > AdjustBet > Settle Win (negative odds)',
    functionName: 'bet_adjustBetForOddsChange_settleWin',
  },
  {
    name: 'Odds Change - Bet > AdjustBet > Settle Win (positive odds)',
    functionName: 'betOnPositiveOdds_adjustBetForOddsChange_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Tie',
    functionName: 'bet_adjustBet_settleWin_resettleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Lose',
    functionName: 'bet_adjustBet_settleWin_resettleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Win',
    functionName: 'bet_adjustBet_settleLose_resettleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Tie',
    functionName: 'bet_adjustBet_settleLose_resettleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie > Resettle Win',
    functionName: 'bet_adjustBet_settleTie_resettleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie > Resettle Lose',
    functionName: 'bet_adjustBet_settleTie_resettleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Win 5 times',
    functionName: 'bet_adjustBet_settleLose_resettleWin5Times',
  },
  {
    name: 'Negative Balance - Bet > AdjustBet > Settle Win > Resettle Lose',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_resettleLose1_settleWin2',
  },
  {
    name: 'Negative Balance - Bet > Settle Win > Resettle Lose (HK oddsMode = 2)',
    functionName: 'negativeBalance_bet1_settleWin1_bet2_resettleLose1_settleWin2',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
