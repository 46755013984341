import PlatformActions from './PlatformActions'

export default class FASTSPINFISHActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'FH',
      gameName: 'Fishing Treasure',
      gameCode: 'FastSpin-FISH-001',
      platform: 'FASTSPIN',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 20221020153655934123698
      roundId: txnId, // 20221020153655934123698
      betType: null,
      currency,
      betTime, // 2022-10-20T15:36:52.000+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, // 2022-10-20T15:36:56.427+08:00
      gameInfo: {},
      requireAmount: betAmount,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 20221020153655934123698
      roundId: txnId, // 20221020153655934123698
      updateTime,
      betAmount,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
