import PlatformActions from './PlatformActions'

export default class PLAY8SLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameName: 'WhiteKing',
      gameCode: 'P8-SLOT-024',
      platform: 'PLAY8',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 80b6fb48bdbe208a297641ca805001cc7b9c
      roundId: `r-${txnId}`, // r-80b6fb48bdbe208a297641ca805001cc7b9c
      betType: null,
      currency,
      betTime, // 2022-07-04T11:20:30.165+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, // 2022-07-04T11:20:30.213+08:00
      requireAmount: betAmount,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId: `r-${txnId}`,
      updateTime,
      betAmount,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
