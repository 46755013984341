import PlatformActions from './PlatformActions'

export default class VENUSActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnDataByGame = {
      baccarat: {
        gameName: 'BaccaratClassic',
        gameCode: 'VN-LIVE-001',
        txnIdPrefix: 'BAC',
      },
      roulette: {
        gameName: 'Roulette',
        gameCode: 'VN-LIVE-009',
        txnIdPrefix: 'RL',
      },
    }

    this.constantGameInfoByGame = {
      baccarat: {
        streamerId: '',
        tableId: '4',
        dealerDomain: 'Bavet',
      },
      roulette: {
        tableId: '71',
        dealerDomain: 'Bavet',
      },
    }
  }

  constantTxnData(game) {
    return {
      gameType: 'LIVE',
      // gameName: this.constantTxnDataByGame[game], // cancelBet do NOT have this field
      gameCode: this.constantTxnDataByGame[game].gameCode,
      platform: 'VENUS',
    }
  }

  getPlatformTxIdAndRoundId({ txnId, roundId, game }) {
    const { txnIdPrefix } = this.constantTxnDataByGame[game]
    const { dealerDomain, tableId } = this.constantGameInfoByGame[game]
    const tableIdInRound = tableId.padStart(2, '0')

    return {
      platformTxId: `${txnIdPrefix}-${txnId}`, // BAC-8511001601
      roundId: `${dealerDomain}-${tableIdInRound}-GA${roundId}`, // Bavet-04-GA262720036
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      betType = 'Banker',
      betTime,
      betAmount,
      roundStartTime,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      betType,
      currency,
      betTime,
      betAmount,
      gameInfo: {
        roundStartTime, // 2021-11-18T16:33:50.774+08:00
        ...this.constantGameInfoByGame[game],
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      updateTime,
      roundId,
      betType = 'Banker',
      betTime,
      turnover,
      betAmount,
      winAmount,
      result,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      betType,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime, // 11/18/2021 15:53:44.175
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  // //win
  // betAmount = formData
  // turnover = betAmount * odds
  // winAmount = betAmount * (1+odds)
  // result = ["H02","H04","","D09","H11",""]
  // winner = BANKER
  // winLoss = betAmount * odds
  // status = WIN
  // //lose
  // betAmount = formData
  // turnover = betAmount * 1
  // winAmount = betAmount * 0
  // result = ["C13","D04","H12","H08","D04","H13"]
  // winner = PLAYER
  // odds = -1.0
  // winLoss = betAmount * odds
  // status = LOSE
  // // tie
  // betAmount = formData
  // turnover = betAmount * 0
  // winAmount = betAmount * 1
  // result = ["C12","D07","","D08","D09",""]
  // winner = TIE
  // odds = 0.0
  // winLoss = betAmount * odds
  // status = TIE

  getUnsettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      result,
      betAmount,
      updateTime,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      updateTime,
      betAmount,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime, // 10/13/2021 22:24:49.827
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'unsettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      updateTime,
      roundId,
      betType = 'Banker',
      betTime,
      turnover,
      betAmount,
      winAmount,
      result,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      betType,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime, // 11/18/2021 15:53:44.175
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      roundStartTime,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      betAmount,
      updateTime,
      voidType: 2,
      gameInfo: {
        // result,
        roundStartTime, // 2021-11-17T20:25:33.710+08:00
        // winner,
        // ip: '172.168.0.1',
        ...this.constantGameInfoByGame[game],
        voidType: 2,
        // winLoss,
        // status,
        realWinAmt: 0,
      },
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      betType = 'Banker',
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      updateTime,
      betType,
      voidType: 2,
      betAmount,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      roundStartTime,
    }) => ({
      ...this.constantTxnData(game),
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      gameInfo: {
        roundStartTime,
        ...this.constantGameInfoByGame[game],
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
