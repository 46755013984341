import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class BGTestScenarios extends PlatformTestScenarios {
  async multiBets_bet1_bet2_bet3_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_TYPES = ['Banker', 'Player', 'Player']
        const betIndexes = []
        for (let betIndex = 0; betIndex < BET_TYPES.length; betIndex += 1) {
          await this.requests.bet({
            betIndexes: [betIndex],
            data: {
              betType: BET_TYPES[betIndex],
            },
          })

          betIndexes.push(betIndex)
        }

        await this.requests.settle({
          betIndexes,
          settleInfo: { winner: 'Banker' },
        })

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_TYPES = ['Banker', 'Player', 'Player']
        const betIndexes = []
        for (let betIndex = 0; betIndex < BET_TYPES.length; betIndex += 1) {
          await this.requests.bet({
            betIndexes: [betIndex],
            data: {
              betType: BET_TYPES[betIndex],
            },
          })

          betIndexes.push(betIndex)
        }

        await this.requests.voidBet({ betIndexes })
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async multiTxnsIn1Bet_bet_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.multiBets()
        await this.requests.multiSettle()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiTxnsIn1Bet_bet_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.multiBets()
        await this.requests.multiVoidBets()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async multiTxnsIn1Bet_bet_cancelBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.multiBets()
        await this.requests.multiCancelBets()
      },
    )
  }
}
