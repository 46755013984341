import moment from 'moment'
import PlatformActions from './PlatformActions'

export default class RTTABLEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'TABLE',
      gameCode: 'RT-TABLE-001',
      platform: 'RT',
    }

    this.gameName = 'Baccarat'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 4abe79d5-9c4f-4267-59a7-08d9daf02de6
      roundId: `qm3-${roundId}`, // qm3-1865430445
      betType: null,
      currency,
      betTime, // 2022-02-08T13:59:34.000+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 4abe79d5-9c4f-4267-59a7-08d9daf02de6
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2022-02-08T13:51:30.613+08:00
      roundId: `qm3-${roundId}`, // qm3-1865430445
      betType: null,
      betTime, // 2022-02-08T13:51:30.000+08:00
      txTime: betTime, // 2022-02-08T13:51:30.000+08:00
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ggr: betAmount - winAmount,
        unsettledbets: -betAmount,
        gametype: 1, // 0: Slot; 1: Table
        senton: moment(betTime).format('YYYY-MM-DDTHH:mm:ssZ'), // 2022-02-08T13:51:30+08:00
        platformtype: 0, // 0: Desktop; 1: Mobile
        brandcode: 'awc',
        bonustype: 0,
        ...(winAmount ? { desc: 'Win from bet on banker' } : {}),
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId: `qm3-${roundId}`,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId,
      roundId: `qm3-${roundId}`,
      betAmount,
      updateTime,
      voidType,
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
