<template>
  <AntLayoutHeader class="header">
    <div class="header-title">{{`${t('single-wallet-testing')} - AWC API`}}</div>
    <AntMenu
      theme="dark"
      mode="horizontal"
      v-model:selectedKeys="menuSelectedKeys"
    >
      <AntMenuItem v-for="({ id, name }) in localeList" :key="id" @click="changeLocale(id)">
        {{ name }}
      </AntMenuItem>
    </AntMenu>
  </AntLayoutHeader>
</template>

<script>
import { ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  Layout as AntLayout,
  Menu as AntMenu,
} from 'ant-design-vue'

export default {
  name: 'Header',
  components: {
    AntLayoutHeader: AntLayout.Header,
    AntMenu,
    AntMenuItem: AntMenu.Item,
  },
  setup() {
    const t = inject('t')
    const { locale } = useI18n({ useScope: 'global' })

    const menuSelectedKeys = ref([locale.value])

    const localeList = [
      {
        id: 'en',
        name: 'English',
      },
      {
        id: 'cn',
        name: '简体中文',
      },
    ]

    const changeLocale = value => locale.value = value

    return {
      t,
      locale,
      localeList,
      menuSelectedKeys,
      changeLocale,
    }
  },
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.header-title {
  font-size: 20px;
  color: #ffffff;
}
</style>
