import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class VRLOTTOTestScenarios extends PlatformTestScenarios {
  async multiBets_bet1_bet2_bet3_bet4_bet5_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        for (let betIndex = 0; betIndex < 5; betIndex += 1) {
          await this.requests.bet({ betIndexes: [betIndex] })
        }

        await this.requests.settleWin({ betIndexes: [1, 3] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.settleLose({ betIndexes: [0, 2, 4] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_bet4_bet5_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = 5
        const betIndexes = []
        for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
          await this.requests.bet({ betIndexes: [betIndex] })
          betIndexes.push(betIndex)
        }

        await this.requests.voidBet({ betIndexes })
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_bet4_bet5_voidBet12_settle345() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = 5
        const betIndexes = []
        for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
          await this.requests.bet({ betIndexes: [betIndex] })
          betIndexes.push(betIndex)
        }

        await this.requests.voidBet({ betIndexes: [0, 1] })
        await this.requests.getBalance({ action: 'voidBet' })

        await this.requests.settleWin({ betIndexes: [2] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.settleLose({ betIndexes: [3, 4] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiTxnsIn1Bet_bet_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.multiBets()

        await this.requests.multiSettle({ settleResult: 'win' })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.multiSettle({ settleResult: 'lose' })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiTxnsIn1Bet_bet_cancelBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.multiBets()
        await this.requests.multiCancelBets()
      },
    )
  }

  async multiTxnsIn1Bet_bet_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.multiBets()
        await this.requests.multiVoidBets()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }
}
