<template>
  <AntConfigProvider :locale="localeModule">
    <AntLayout class="layout">
      <Header />
      <AntLayoutContent>
        <slot></slot>
      </AntLayoutContent>
      <Footer />
    </AntLayout>
  </AntConfigProvider>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

import {
  ConfigProvider as AntConfigProvider,
  Layout as AntLayout,
} from 'ant-design-vue'

import Header from './Header'
import Footer from './Footer'

export default {
  name: 'Layout',
  components: {
    AntConfigProvider,
    AntLayout,
    AntLayoutContent: AntLayout.Content,
    Header,
    Footer,
  },
  setup() {
    const { locale } = useI18n({ useScope: 'global' })
    const localeModule = computed(() => (locale.value === 'en' ? enUS : zhCN))

    return {
      localeModule,
    }
  },

}
</script>

<style scoped>
.layout {
  min-height: 100vh;
}
</style>
