import moment from 'moment'

import SEXYBCRTRequests from './SEXYBCRTRequests'
import { getRequestName } from './PlatformRequests'

import { ALL_BET_TYPE_WINNERS } from './constants/roulette'

export default class AELIVECOMMRequests extends SEXYBCRTRequests {
  async multiBets(args) {
    const { data } = args

    await super.multiBets({
      ...args,
      data: {
        ...data,
        gameCode: 'AE-LIVE-009',
      },
    })
  }

  async multiSettle() {
    const winner = 20
    const winBetTypes = Object.keys(ALL_BET_TYPE_WINNERS).filter(betType => ALL_BET_TYPE_WINNERS[betType].includes(winner))

    const playerIndexes = []

    let updateTime
    const settleData = this.betData.map((playerBetData, playerIdx) => {
      playerIndexes.push(playerIdx)

      return playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
        const {
          betAmount,
          betType,
          odds,
          roundStartTime,
        } = txnBetData

        const isWin = winBetTypes.includes(betType)
        if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)

        return {
          ...txnBetData,
          odds: isWin ? odds : -1,
          winAmount: isWin ? betAmount * (1 + odds) : 0,
          winLoss: isWin ? betAmount * odds : betAmount * -1,
          turnover: betAmount * 1,
          winner,
          result: [`${winner}`],
          status: isWin ? 'WIN' : 'LOSE',
          rebate: betAmount * 0.007,
          updateTime,
          roundStartTime: moment(roundStartTime).format('MM/DD/YYYY HH:mm:ss.SSS'),
        }
      }))
    })

    this.tests.settleData = settleData

    const res = await this.actions.settle(settleData.flat().flat())

    return this.requestPromise(
      {
        action: 'settle',
        requestName: getRequestName({
          action: 'settle',
          playerIndexes,
        }),
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async multiVoidSettle() {
    const playerIndexes = []

    let updateTime
    const voidSettleData = this.betData.map((playerBetData, playerIdx) => {
      playerIndexes.push(playerIdx)

      return playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
        if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)
        return {
          ...txnBetData,
          voidType: 2,
          updateTime,
        }
      }))
    })

    this.tests.voidSettleData = voidSettleData

    const res = await this.actions.voidSettle(voidSettleData.flat().flat())

    return this.requestPromise(
      {
        action: 'voidSettle',
        requestName: getRequestName({
          action: 'voidSettle',
          playerIndexes,
        }),
      },
      res,
      () => this.tests.voidSettle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }
}
