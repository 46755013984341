import PlatformActions from './PlatformActions'

export default class PPEGAMEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'EGAME',
      gameCode: 'PP-EGAME-001',
      platform: 'PP',
    }

    this.gameName = 'Spaceman'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 635a2bd84567ee5c73bdb36b
      roundId: `${roundId}`, // 144542301808
      betType: null,
      currency,
      betTime, // 2022-10-27T15:00:10.050+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${roundId}`, // 144542301808
      refPlatformTxId: null,
      settleType: 'roundId',
      updateTime, // 2022-10-27T14:57:57.450+08:00
      roundId: `${roundId}`, // 144542301808
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 635a2c7a01041c0c744f5e70
      roundId: `${roundId}`, // 144542701808
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
