import PlatformActions from './PlatformActions'

export default class PLAY8FISHActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'FH',
      gameName: 'OceanKing',
      gameCode: 'P8-FISH-001',
      platform: 'PLAY8',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 80b6fb4abdbe208a29767b78839001cc7bf4
      roundId: `${roundId}-2-0-0-0-0`, // 1656905772-2-0-0-0-0
      betType: null,
      currency,
      betTime, // 2022-07-04T11:36:25.929+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, // 2022-07-04T11:36:25.967+08:00
      requireAmount: betAmount,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId: `${roundId}-2-0-0-0-0`,
      updateTime,
      betAmount,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
