import moment from 'moment'
import assert from './assert'

export default class Validations {
  constructor(formData) {
    this.formData = formData
  }

  // -------------- common --------------
  responseTime(action) {
    const { platform, key } = this.formData
    const { duration } = this.res

    const ACTION_TIMEOUT = {
      getBalance: 3000,
      bet: 5000,
      adjustBet: 6000,
      cancelBet: 6000,
      settle: 20000,
      voidBet: 20000,
      unvoidBet: 20000,
      unsettle: 20000,
      resettle: 20000,
      voidSettle: 20000,
      unvoidSettle: 20000,
      refund: 20000,
      freeSpin: 20000,
      tip: 6000,
      cancelTip: 6000,
      give: 20000,
      betNSettle: 2000,
      cancelBetNSettle: 2000,
      adjustAmt: 6000,
      cancelAdjustAmt: 6000,
    }

    const ACTION_PLATFORM_TIMEOUT = {
      getBalance: {
        BTG: 2000,
        EVOLUTION: 2000,
        NETENTSLOT: 2000,
        NETENTTABLE: 2000,
        NLC: 2000,
        RTSLOT: 2000,
        RTTABLE: 2000,
      },
      bet: {
        BTG: 2000,
        EVOLUTION: 2000,
        JILITABLE: 1500,
        NETENTSLOT: 2000,
        NETENTTABLE: 2000,
        NLC: 2000,
        PTLIVE: 2000,
        PTSLOT: 2000,
        RTSLOT: 2000,
        RTTABLE: 2000,
        LUCKYPOKER: 2000,
        YESBINGOBINGO: 1500,
        YESBINGOSLOT: 1500,
      },
      cancelBet: {
        BTG: 2000,
        EVOLUTION: 2000,
        NETENTSLOT: 2000,
        NETENTTABLE: 2000,
        NLC: 2000,
        RTSLOT: 2000,
        RTTABLE: 2000,
      },
      settle: {
        BTG: 2000,
        EVOLUTION: 2000,
        NETENTSLOT: 2000,
        NETENTTABLE: 2000,
        NLC: 2000,
        RTSLOT: 2000,
        RTTABLE: 2000,
      },
      betNSettle: {
        AWSEGAME: 1500,
        AWSSLOT: 1500,
        AWSTABLE: 1500,
        JILIFISH: 1500,
        JILISLOT: 1500,
        SPADEFISH: 1500,
        YLFISH: 1500,
        PGSLOT: 1500,
        PGTABLE: 1500,
        YESBINGOFISH: 1500,
        ILOVEU: 5000,
      },
      cancelBetNSettle: {
        AWSEGAME: 1500,
        AWSSLOT: 1500,
        AWSTABLE: 1500,
        JILIFISH: 1500,
        JILISLOT: 1500,
        SPADEFISH: 1500,
        YLFISH: 1500,
        PGSLOT: 1500,
        PGTABLE: 1500,
        YESBINGOFISH: 1500,
        ILOVEU: 5000,
      },
    }

    this.actionTimeoutData.forEach(({
      action: timeoutAction,
      platform: timeoutPlatform,
      clientCert,
      timeout,
    }) => {
      if (key === `${clientCert}` || !clientCert) {
        if (timeoutPlatform) {
          if (!ACTION_PLATFORM_TIMEOUT[timeoutAction]) ACTION_PLATFORM_TIMEOUT[timeoutAction] = {}
          ACTION_PLATFORM_TIMEOUT[timeoutAction][timeoutPlatform] = timeout
        } else {
          ACTION_TIMEOUT[timeoutAction] = timeout
        }
      }
    })

    const actionPlatformTimeout = ACTION_PLATFORM_TIMEOUT[action]
    const timeoutPeriod = (actionPlatformTimeout && actionPlatformTimeout[platform]) || ACTION_TIMEOUT[action]

    const testName = 'response time should be less than timeout period'
    const testTitle = `${testName} (responseTime: ${duration}, timeoutPeriod: ${timeoutPeriod})`

    return assert.below(duration, timeoutPeriod, testTitle)
  }

  status() {
    const { data: { status } } = this.res

    const testName = 'status should be 0000'
    const testTitle = `${testName} (status: ${status})`

    return assert.equal(status, '0000', testTitle)
  }

  statusForInsufficientBalance() {
    const { data: { status } } = this.res

    const testName = 'status should be 1018 when balance is not enough'
    const testTitle = `${testName} (status: ${status})`

    return assert.equal(status, '1018', testTitle)
  }

  statusForPreviousAsyncSettle() {
    const { asyncSettleRes } = this.res
    const statuses = asyncSettleRes.map(({ data: { status } }) => status)

    const isAnySettleAccepted = statuses.includes('0000')
    const testName = isAnySettleAccepted ? 'At least one of 5 times of async settle status is 0000' : 'All of 5 times of async settle statuses are not 0000'
    const testTitle = `${testName} (status: ${statuses})`
    const assertion = isAnySettleAccepted ? statuses.includes('0000') : !statuses.includes('0000')

    return assert.getAssertionResult(statuses, null, testTitle, assertion)
  }

  statusString() {
    const { data: { status } } = this.res
    const statusType = typeof status

    const testName = 'status type should be a string'
    const testTitle = `${testName} (statusType: ${statusType})`

    return assert.equal(statusType, 'string', testTitle)
  }

  positiveBalance() {
    const { balance } = this.res.data

    const testName = 'balance should be greater than 0'
    const testTitle = `${testName} (balance: ${balance})`

    return assert.above(Number(balance), 0, testTitle)
  }

  notNegativeBalance() {
    const { balance } = this.res.data

    const testName = 'balance should be greater than or equal to 0'
    const testTitle = `${testName} (balance: ${balance})`

    return assert.aboveEqual(Number(balance), 0, testTitle)
  }

  zeroBalance() {
    const { balance } = this.res.data

    const testName = 'balance should be 0'
    const testTitle = `${testName} (balance: ${balance})`

    return assert.equal(Number(balance), 0, testTitle)
  }

  negativeBalance() {
    const { balance } = this.res.data

    const testName = 'balance should be negative'
    const testTitle = `${testName} (balance: ${balance})`

    return assert.below(Number(balance), 0, testTitle)
  }

  balanceNumber() {
    const { balance } = this.res.data
    const balanceType = typeof balance

    const testName = 'balance type should be a numebr'
    const testTitle = `${testName} (balanceType: ${balanceType})`

    return assert.equal(balanceType, 'number', testTitle)
  }

  balanceFormat() {
    const { balance } = this.res.data
    const { balanceDecimalPlaces } = this.formData

    const decimalPlaseMsg = balanceDecimalPlaces ? `rounded to ${balanceDecimalPlaces} decimal place${balanceDecimalPlaces > 1 ? 's' : ''}` : 'an integer'
    const testName = `balance format should be correct and ${decimalPlaseMsg}`
    const testTitle = `${testName} (balance: ${balance})`

    const balanceRegex = new RegExp(`^(-|\\+)?\\d+(\\.\\d{0,${balanceDecimalPlaces}})${balanceDecimalPlaces ? '?' : '{0}'}$`)

    return assert.match(balance, balanceRegex, testTitle)
  }

  balanceTs() {
    const { responseTs } = this.res
    const { balanceTs } = this.res.data

    const nowValue = moment(responseTs).valueOf()
    const balanceTsValue = balanceTs ? moment(balanceTs).valueOf() : 0

    const testName = 'balanceTs should match the current time'
    const testTitle = `${testName} (currentUnix: ${nowValue}, balanceTsUnix: ${balanceTsValue}, interval: ${nowValue - balanceTsValue})`

    return assert.below(nowValue - balanceTsValue, 60000, testTitle)
  }

  balanceTsFormat() {
    const { balanceTs } = this.res.data

    const testName = 'balanceTs format should be correct'
    const testTitle = `${testName} (balanceTs: ${balanceTs})`

    const timeRegex = /^[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}\.[\d]{3}(([-|+][\d]{2}:[\d]{2})|Z)$/
    return assert.match(balanceTs, timeRegex, testTitle)
  }

  balanceTsSequence() {
    const { lastBalanceTs } = this
    const { balanceTs } = this.res.data

    const testName = 'balanceTs should be later than the last balanceTs'
    const testTitle = `${testName} (balanceTs: ${balanceTs}, lastBalanceTs: ${lastBalanceTs})`

    const balanceTsValue = moment(balanceTs).valueOf()
    const lastBalanceTsValue = moment(lastBalanceTs).valueOf()

    return {
      ...assert.aboveEqual(balanceTsValue, lastBalanceTsValue, testTitle),
      ...(balanceTsValue === lastBalanceTsValue && {
        warning: 'balanceTs is equal to the last balanceTs',
      }),
    }
  }

  balanceFromGetBalanceAfterAction(actionType, testData) {
    const { lastBalance } = this
    const { balance } = this.res.data
    const { balanceDecimalPlaces } = this.formData

    const {
      betAmount,
      winAmount,
      giveAmount,
      oldWinAmount,
    } = testData || {}

    const rebate = testData?.rebate ?? 0
    const oldRebate = testData?.oldRebate ?? 0

    let expectedValue
    let testName = `balance after ${actionType}`
    switch (actionType) {
      case 'settle':
        expectedValue = lastBalance + winAmount + rebate
        break

      case 'asyncSettle': {
        const statuses = this.res.asyncSettleRes.map(({ data: { status } }) => status)
        const isAnySettleAccepted = statuses.includes('0000')

        if (isAnySettleAccepted) {
          expectedValue = lastBalance + winAmount + rebate
        } else {
          expectedValue = lastBalance
        }
        testName = 'balance after (network) settle'
        break
      }

      case 'unsettle':
        expectedValue = lastBalance - winAmount
        break

      case 'resettle':
        expectedValue = lastBalance + winAmount + rebate - oldWinAmount - oldRebate
        break

      case 'voidBet':
        expectedValue = lastBalance + betAmount
        break

      case 'unvoidBet':
        expectedValue = lastBalance - betAmount
        break

      case 'voidSettle':
        expectedValue = lastBalance + betAmount - winAmount - oldRebate
        break

      case 'unvoidSettle':
        expectedValue = lastBalance - betAmount + winAmount
        break

      case 'give':
        expectedValue = lastBalance + giveAmount
        break

      case 'refund':
        expectedValue = lastBalance - betAmount + winAmount
        break

      case 'freeSpin':
        expectedValue = lastBalance + winAmount
        break

      case 'betAfterCancelBet':
        expectedValue = lastBalance
        testName = 'balance for bet after cancelBet'
        break

      case 'tipAfterCancelTip':
        expectedValue = lastBalance
        testName = 'balance for tip after cancelTip'
        break

      case 'adjustAmtAfterCancelAdjustAmt':
        expectedValue = lastBalance
        testName = 'balance for adjustAmt after cancelAdjustAmt'
        break

      case 'betNSettle':
        expectedValue = lastBalance
        break

      case 'asyncBetNSettle': {
        const statuses = this.res.asyncSettleRes.map(({ data: { status } }) => status)
        const isAnySettleAccepted = statuses.includes('0000')

        if (isAnySettleAccepted) {
          expectedValue = lastBalance - betAmount + winAmount
        } else {
          expectedValue = lastBalance
        }
        testName = 'balance after (network) betNSettle'
        break
      }

      case 'cancelBetNSettle':
        expectedValue = lastBalance
        break

      case 'betNSettleAfterCancelBetNSettle':
        expectedValue = lastBalance
        testName = 'balance for betNSettle after cancelBetNSettle'
        break

      default:
        break
    }

    expectedValue = Number(expectedValue.toFixed(balanceDecimalPlaces))
    testName = `${testName} should be ${expectedValue}`

    const strArr = [`balance: ${balance}`, `lastBalance: ${lastBalance}`].concat(Object.entries(testData || {}).map(([key, value]) => `${key}: ${value}`))
    const testTitle = `${testName} (${strArr.join(', ')})`

    return assert.aboutEqual(Number(balance), expectedValue, 0.1, testTitle)
  }

  balanceFromAction(actionType, testData) {
    const { lastBalance } = this
    const { balance } = this.res.data
    const { balanceDecimalPlaces } = this.formData

    const {
      betAmount,
      winAmount,
      tip,
      adjustAmount,
    } = testData || {}

    let expectedValue
    let testName = `balance after ${actionType}`
    switch (actionType) {
      case 'bet':
        expectedValue = lastBalance - betAmount
        break

      case 'betAfterCancelBet':
        testName = 'balance for bet after cancelBet'
        expectedValue = lastBalance
        break

      case 'cancelBet':
        if (testData) {
          expectedValue = lastBalance + betAmount
        } else {
          expectedValue = lastBalance
        }
        break

      case 'betNSettle':
        if (testData) {
          expectedValue = lastBalance - betAmount + winAmount
        } else {
          expectedValue = lastBalance
        }
        break

      case 'betNSettleAfterCancelBetNSettle':
        testName = 'balance for betNSettle after cancelBetNSettle'
        expectedValue = lastBalance
        break

      case 'cancelBetNSettle':
        if (testData) {
          expectedValue = lastBalance + betAmount - winAmount
        } else {
          expectedValue = lastBalance
        }
        break

      case 'tip':
        expectedValue = lastBalance - tip
        break

      case 'tipAfterCancelTip':
        testName = 'balance for tip after cancelTip'
        expectedValue = lastBalance
        break

      case 'cancelTip':
        if (testData) {
          expectedValue = lastBalance + tip
        } else {
          expectedValue = lastBalance
        }
        break

      case 'adjustAmt':
        expectedValue = lastBalance + adjustAmount
        break

      case 'adjustAmtAfterCancelAdjustAmt':
        testName = 'balance for adjustAmt after cancelAdjustAmt'
        expectedValue = lastBalance
        break

      case 'cancelAdjustAmt':
        if (testData) {
          expectedValue = lastBalance - adjustAmount
        } else {
          expectedValue = lastBalance
        }
        break

      case 'adjustBet':
        expectedValue = lastBalance + adjustAmount
        break

      default:
        break
    }

    expectedValue = Number(expectedValue.toFixed(balanceDecimalPlaces))
    testName = `${testName} should be ${expectedValue}`

    const strArr = [`balance: ${balance}`, `lastBalance: ${lastBalance}`].concat(Object.entries(testData || {}).map(([key, value]) => `${key}: ${value}`))
    const testTitle = `${testName} (${strArr.join(', ')})`

    return assert.aboutEqual(Number(balance), expectedValue, 0.1, testTitle)
  }
}
