import PlatformActions from './PlatformActions'

export default class AELOTTOActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'LOTTO',
      gameCode: 'AE-LOTTO-066',
      platform: 'AE',
    }

    this.gameName = 'HN 45s'
    this.gameInfo = {
      win: '2',
      lose: '3',
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType = 'vn2dbottom',
      odds,
      betAmount,
      betTime,
      roundDate,
      betItems,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `LOT_${txnId}`, // LOT_1543315663354827
      roundId, // 202112201552
      currency,
      betType,
      betAmount,
      betTime, // 2021-12-20T19:23:45.315+08:00,
      gameInfo: {
        odds: [`${(odds + 1).toFixed(4)}`],
        betItems,
        roundDate, // 2021-12-20T19:24:00+08:00,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType = 'vn2dbottom',
      odds,
      betAmount,
      winAmount,
      turnover,
      betTime,
      updateTime,
      betItems,
      roundDate,
      resettleTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      currency,
      platformTxId: `LOT_${txnId}`,
      settleType: 'platformTxId',
      refPlatformTxId: null,
      roundId,
      betType,
      betAmount,
      winAmount,
      turnover,
      betTime, // 2021-12-20T19:23:45.315+08:00
      txTime: betTime, // 2021-12-20T19:24:00.348+08:00
      updateTime, // 2021-12-20T19:24:00.687+08:00
      gameInfo: {
        odds: [`${(odds + 1).toFixed(4)}`],
        betItems,
        roundDate, // 2021-12-20T19:24:00+08:00,
        overWinPrizeDeduct: 0,
        gameInfo: winAmount ? '2' : '3',
        singleBetPrizeDeduct: 0,
        resettleTime, // 2022-01-08T03:31:26.353+08:00
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getUnsettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      odds,
      betAmount,
      updateTime,
      betItems,
      roundDate,
      resettleResult,
      resettleTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `LOT_${txnId}`,
      roundId,
      betAmount,
      updateTime,
      gameInfo: {
        odds: [`${(odds + 1).toFixed(4)}`],
        betItems,
        roundDate,
        overWinPrizeDeduct: 0,
        gameInfo: this.gameInfo[resettleResult],
        singleBetPrizeDeduct: 0,
        resettleTime, // 2022-01-08T03:31:26.353+08:00
      },
    }))

    const message = {
      action: 'unsettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      odds,
      betItems,
      roundDate,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `LOT_${txnId}`,
      roundId,
      gameInfo: {
        odds: [`${(odds + 1).toFixed(4)}`],
        betItems,
        roundDate,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      odds,
      betAmount,
      updateTime,
      betItems,
      roundDate,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `LOT_${txnId}`,
      roundId,
      betAmount,
      updateTime,
      voidType: 2,
      gameInfo: {
        odds: [`${(odds + 1).toFixed(4)}`],
        betItems,
        roundDate,
      },
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
