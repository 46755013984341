import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class PlatformBetNSettleTestScenarios extends PlatformTestScenarios {
  async betNSettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.betNSettleWin()
        await this.requests.getBalance({ action: 'betNSettle' })
      },
    )
  }

  async betNSettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.betNSettleLose()
        await this.requests.getBalance({ action: 'betNSettle' })
      },
    )
  }

  async betNSettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.betNSettleWin({ timesLoop })
        }
        await this.requests.getBalance({ action: 'betNSettle' })
      },
    )
  }

  async asyncBetNSettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await Promise.all(Array.from({ length: 5 }).map(async (_, timesLoop) => {
          await this.requests.betNSettleWin({
            timesLoop,
            options: { isAsync: true },
          })
          return true
        }))

        await this.requests.getBalance({ action: 'asyncBetNSettle' })
      },
    )
  }

  async betNSettleWin_cancelBetNSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.betNSettleWin()
        await this.requests.getBalance({ action: 'betNSettle' })
        await this.requests.cancelBetNSettle()
        await this.requests.getBalance({ action: 'cancelBetNSettle' })
      },
    )
  }

  async betNSettleLose_cancelBetNSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.betNSettleLose()
        await this.requests.getBalance({ action: 'betNSettle' })
        await this.requests.cancelBetNSettle()
        await this.requests.getBalance({ action: 'cancelBetNSettle' })
      },
    )
  }

  async betNSettleWin_cancelBetNSettle5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.betNSettleWin()
        await this.requests.getBalance({ action: 'betNSettle' })
        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.cancelBetNSettle({ timesLoop })
        }
        await this.requests.getBalance({ action: 'cancelBetNSettle' })
      },
    )
  }

  async cancelBetNSettleOnly() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.cancelBetNSettle({ options: { beforeBetNSettle: true } })
        await this.requests.getBalance({ action: 'cancelBetNSettle' })
      },
    )
  }

  async cancelBetNSettle_betNSettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.cancelBetNSettle({ options: { beforeBetNSettle: true } })
        await this.requests.betNSettleWin({ options: { afterCancelBetNSettle: true } })
        await this.requests.getBalance({ action: 'betNSettleAfterCancelBetNSettle' })
      },
    )
  }

  async betNSettleWin10Times_cancelBetNSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = 10
        for (let i = 0; i < BET_LENGTH; i += 1) {
          await this.requests.betNSettleWin({
            betIndexes: [i],
            options: { differentRound: true },
          })

          await this.requests.getBalance({ action: 'betNSettle' })
        }

        await this.requests.cancelBetNSettle({ betIndexes: Array.from({ length: BET_LENGTH }).map((_, i) => i) })
        await this.requests.getBalance({ action: 'cancelBetNSettle' })
      },
    )
  }

  async betNSettleWin10Times_cancelBetNSettle_sameRound() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = 10
        for (let i = 0; i < BET_LENGTH; i += 1) {
          await this.requests.betNSettleWin({ betIndexes: [i] })
          await this.requests.getBalance({ action: 'betNSettle' })
        }

        await this.requests.cancelBetNSettle({ betIndexes: Array.from({ length: BET_LENGTH }).map((_, i) => i) })
        await this.requests.getBalance({ action: 'cancelBetNSettle' })
      },
    )
  }

  async negativeBalance_betNSettleWin_cancelBetNSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.betNSettleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'betNSettle' })

        await this.requests.betNSettleForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })
        await this.requests.getBalance({
          action: 'betNSettle',
          options: { betForNegativeBalance: true },
        })

        await this.requests.cancelBetNSettle({
          betIndexes: [0],
          options: { validateNegativeBalance: true },
        })
        await this.requests.getBalance({
          action: 'cancelBetNSettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.cancelBetNSettle({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'cancelBetNSettle' })
      },
    )
  }

  async positiveBalance_sufficient_betNSettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance({ options: { validatePositiveBalance: true } })

        const balance = this.requests.tests.lastBalance[0]
        const { betAmount } = this.formData

        await this.requests.betNSettleWin({
          data: {
            betAmount: balance + betAmount,
            winAmount: balance + betAmount,
            requireAmount: balance,
          },
        })

        await this.requests.getBalance({
          action: 'betNSettle',
          options: { validatePositiveBalance: true },
        })
      },
    )
  }

  async positiveBalance_insufficient_betNSettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance({ options: { validatePositiveBalance: true } })

        const balance = this.requests.tests.lastBalance[0]
        const { betAmount } = this.formData

        await this.requests.betNSettleWin({
          data: {
            betAmount: balance + betAmount,
            winAmount: balance + betAmount * 2,
            // requireAmount: balance + betAmount,
          },
          options: { validateInsufficientBalance: true },
        })

        await this.requests.getBalance({
          action: 'betNSettle',
          options: { validatePositiveBalance: true },
        })
      },
    )
  }

  async positiveBalance_insufficient_betNSettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance({ options: { validatePositiveBalance: true } })

        const balance = this.requests.tests.lastBalance[0]
        const { betAmount } = this.formData

        await this.requests.betNSettleLose({
          data: {
            betAmount: balance + betAmount,
            // winAmount: 0,
            // requireAmount: balance + betAmount,
          },
          options: { validateInsufficientBalance: true },
        })

        await this.requests.getBalance({
          action: 'betNSettle',
          options: { validatePositiveBalance: true },
        })
      },
    )
  }

  async zeroBalance_betNSettleWin() {
    await this.setTestScenario(
      async () => {
        await this.insufficientBalanceBetTestPromise('zero')

        await this.requests.getBalance({ options: { validateZeroBalance: true } })
        await this.requests.betNSettleWin({ options: { validateInsufficientBalance: true } })

        await this.requests.getBalance({
          action: 'betNSettle',
          options: { validateZeroBalance: true },
        })
      },
      this.finishInsufficientBalanceBetTest,
    )
  }

  async zeroBalance_betNSettleLose() {
    await this.setTestScenario(
      async () => {
        await this.insufficientBalanceBetTestPromise('zero')

        await this.requests.getBalance({ options: { validateZeroBalance: true } })
        await this.requests.betNSettleLose({ options: { validateInsufficientBalance: true } })

        await this.requests.getBalance({
          action: 'betNSettle',
          options: { validateZeroBalance: true },
        })
      },
      this.finishInsufficientBalanceBetTest,
    )
  }

  async negativeBalance_betNSettleWin() {
    await this.setTestScenario(
      async () => {
        await this.insufficientBalanceBetTestPromise('negative')

        await this.requests.getBalance({ options: { validateNegativeBalance: true } })
        await this.requests.betNSettleWin({ options: { validateInsufficientBalance: true } })

        await this.requests.getBalance({
          action: 'betNSettle',
          options: { validateNegativeBalance: true },
        })
      },
      this.finishInsufficientBalanceBetTest,
    )
  }

  async negativeBalance_betNSettleLose() {
    await this.setTestScenario(
      async () => {
        await this.insufficientBalanceBetTestPromise('negative')

        await this.requests.getBalance({ options: { validateNegativeBalance: true } })
        await this.requests.betNSettleLose({ options: { validateInsufficientBalance: true } })

        await this.requests.getBalance({
          action: 'betNSettle',
          options: { validateNegativeBalance: true },
        })
      },
      this.finishInsufficientBalanceBetTest,
    )
  }
}
