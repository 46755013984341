import moment from 'moment'
import PlatformActions from './PlatformActions'

export default class SABAOLDActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'VIRTUAL',
      // gameName: 'Virtual Sports', // cancelBet do NOT have this field
      gameCode: 'SABA-VIRTUAL-001',
      platform: 'SABA',
    }

    this.constantGameInfo = {
      sportTypeName: 'Soccer',
      oddsId: '391438849',
      sportType: '1',
      homeName: 'Russia (V)',
      awayId: '676190',
      betChoice: 'Russia (V)',
      leagueName: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
      awayName: 'Slovakia (V)',
      leagueId: '95731',
      homeId: '674776',
    }

    this.now = moment()

    this.getParlayGameInfo = ({ betAmount, odds }) => ({
      txnDetail: {
        parlayType: 'Parlay_Combo',
        detail: [{
          type: 1,
          betCount: 1,
          stake: betAmount,
          name: 'Trebles',
        }],
      },
      ticketDetails: {
        ticketDetail: [
          {
            matchId: 49511590,
            homeId: 674773,
            awayId: 674777,
            homeName: 'Poland (V)',
            awayName: 'Spain (V)',
            kickOffTime: moment(this.now).subtract(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss.000+08:00'),
            sportType: 1,
            sportTypeName: 'Soccer',
            betType: 1,
            oddsId: 393165044,
            odds: odds[0],
            oddsType: 3,
            betChoice: 'Poland (V)',
            leagueId: 95731,
            leagueName: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
            point: '0.00',
            point2: '',
            betTeam: 'h',
            homeScore: 0,
            awayScore: 1,
            baStatus: false,
            excluding: '',
            leagueName_en: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
            sportTypeName_en: 'Soccer',
            betTypeName_en: 'Handicap',
            homeName_en: 'Poland (V)',
            awayName_en: 'Spain (V)',
          },
          {
            matchId: 49511605,
            homeId: 674797,
            awayId: 674850,
            homeName: 'Switzerland (V)',
            awayName: 'Argentina (V)',
            kickOffTime: moment(this.now).subtract(10, 'minutes').format('YYYY-MM-DDTHH:mm:ss.000+08:00'),
            sportType: 1,
            sportTypeName: 'Soccer',
            betType: 7,
            oddsId: 393165356,
            odds: odds[1],
            oddsType: 3,
            betChoice: 'Switzerland (V)',
            leagueId: 95731,
            leagueName: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
            point: '0.00',
            point2: '',
            betTeam: 'h',
            homeScore: 0,
            awayScore: 1,
            baStatus: false,
            excluding: '',
            leagueName_en: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
            sportTypeName_en: 'Soccer',
            betTypeName_en: '1H Handicap',
            homeName_en: 'Switzerland (V)',
            awayName_en: 'Argentina (V)',
          },
          {
            matchId: 49510246,
            homeId: 674926,
            awayId: 674751,
            homeName: 'Real Sociedad (V)',
            awayName: 'Bayern Munchen (V)',
            kickOffTime: moment(this.now).subtract(15, 'minutes').format('YYYY-MM-DDTHH:mm:ss.000+08:00'),
            sportType: 1,
            sportTypeName: 'Soccer',
            betType: 3,
            oddsId: 393444506,
            odds: odds[2],
            oddsType: 3,
            betChoice: 'Over',
            leagueId: 95730,
            leagueName: 'SABA CLUB FRIENDLY Virtual PES 21 - 15 Mins Play',
            point: '3.50',
            point2: '',
            betTeam: 'h',
            homeScore: 1,
            awayScore: 2,
            baStatus: false,
            excluding: '',
            leagueName_en: 'SABA CLUB FRIENDLY Virtual PES 21 - 15 Mins Play',
            sportTypeName_en: 'Soccer',
            betTypeName_en: 'Over/Under',
            homeName_en: 'Real Sociedad (V)',
            awayName_en: 'Bayern Munchen (V)',
          },
        ],
      },
    })
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      oddsType = '2',
      odds,
      isParlay,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'VIRTUAL',
      gameName: 'Virtual Sports',
      // gameCode: 'SABA-VIRTUAL-001',
      userId,
      // platform: 'SABA',
      platformTxId: `${txnId}`, // 7926
      roundId: isParlay ? null : `${roundId}`, // 49307808
      betType: isParlay ? null : '1',
      currency,
      betTime,
      betAmount,
      gameInfo: isParlay ? this.getParlayGameInfo({ betAmount, odds }) : {
        ...this.constantGameInfo,
        oddsType,
        odds: `${odds}`,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      betTime,
      txTime,
      turnover,
      betAmount,
      winAmount,
      oddsType = '2',
      odds,
      isParlay,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'VIRTUAL',
      gameName: 'Virtual Sports',
      // gameCode: 'SABA-VIRTUAL-001',
      userId,
      // platform: 'SABA',
      platformTxId: `${txnId}`, // 788663
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      roundId: isParlay ? null : `${roundId}`, // 49307808
      betType: isParlay ? null : '1',
      betTime, // 2021-12-27T01:09:37.170+08:00
      txTime, // 2021-12-27T01:14:56.210+08:00
      turnover,
      betAmount,
      winAmount,
      gameInfo: isParlay ? this.getParlayGameInfo({ betAmount, odds }) : {
        ...this.constantGameInfo,
        oddsType,
        odds: `${odds}`,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      oddsType = '2',
      odds,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'VIRTUAL',
      gameName: 'Virtual Sports',
      // gameCode: 'SABA-VIRTUAL-001',
      userId,
      // platform: 'SABA',
      platformTxId: `${txnId}`,
      updateTime,
      roundId: `${roundId}`,
      betAmount,
      voidType: 2,
      gameInfo: {
        ...this.constantGameInfo,
        oddsType,
        odds: `${odds}`,
      },
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getUnsettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      oddsType = '2',
      odds,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'VIRTUAL',
      gameName: 'Virtual Sports',
      // gameCode: 'SABA-VIRTUAL-001',
      userId,
      // platform: 'SABA',
      platformTxId: `${txnId}`,
      updateTime,
      roundId: `${roundId}`,
      betAmount,
      gameInfo: {
        ...this.constantGameInfo,
        oddsType,
        odds: `${odds}`,
      },
    }))

    const message = {
      action: 'unsettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'VIRTUAL',
      gameName: 'Virtual Sports',
      // gameCode: 'SABA-VIRTUAL-001',
      userId,
      // platform: 'SABA',
      platformTxId: `${txnId}`,
      roundId: `${roundId}`,
      updateTime,
      voidType: 2,
      betAmount,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      oddsType = '2',
      odds,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'VIRTUAL',
      // gameCode: 'SABA-VIRTUAL-001',
      userId,
      // platform: 'SABA',
      platformTxId: `${txnId}`,
      roundId: `${roundId}`,
      gameInfo: {
        ...this.constantGameInfo,
        oddsType,
        odds: `${odds}`,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
