import PlatformActions from './PlatformActions'

export default class PTLIVEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'LIVE',
      gameCode: 'PT-LIVE-001',
      platform: 'PT',
    }

    this.gameName = 'Baccarat'

    this.constantGameInfo = {
      roundType: 'normal',
      additionalData: {
        launchAlias: 'bal_sohobaccarat',
        tableId: '1295',
      },
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `pt${txnId}`, // pt67184226
      roundId: `live_${roundId}`, // live_87613108595
      betType: 'bet',
      currency,
      betTime, // 2022-07-24T16:19:29.000+08:00
      betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: winAmount ? `pt${txnId}` : `ptlive_${roundId}win`, // WIN: pt67184227, LOSE: ptlive_87613108595win
      refPlatformTxId: null,
      settleType: 'roundId',
      updateTime, // 2022-07-24T16:19:40.776+08:00
      roundId: `live_${roundId}`, // live_87613108595
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        betInfo: [JSON.stringify(this.constantGameInfo)],
        settleInfo: JSON.stringify({
          jpw: 0,
          detailUrl: 'https://fake.extstg3-cashier01.ptstaging.eu/getgamehistoryurl.php',
          ...this.constantGameInfo,
          jpc: 0,
        }),
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `pt${txnId}`,
      roundId: `live_${roundId}`,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
