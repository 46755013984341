import PlatformActions from './PlatformActions'

export default class VRLOTTOActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'LOTTO',
      gameCode: 'VRLOTTO-LOTTO-005',
      platform: 'VRLOTTO',
    }

    this.gameName = 'VR Mark Six'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 11021162116037920422
      roundId: `16-${roundId}`, // 16-20230224077
      betType: null,
      currency,
      betTime, // 2022-10-21T16:21:16.000+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 11021162116037920422
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2022-10-21T16:26:10.406+08:00
      roundId: `16-${roundId}`, // 16-20230224077
      betType: null,
      betTime, // 2022-10-21T16:21:16.000+08:00
      txTime: betTime, // 2022-10-21T16:21:16.000+08:00
      turnover,
      betAmount,
      winAmount,
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
      resettleTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 11021162116037920422
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2022-10-21T16:26:10.406+08:00
      roundId: `16-${roundId}`, // 16-20230224077
      betType: null,
      betTime, // 2022-10-21T16:21:16.000+08:00
      txTime: betTime, // 2022-10-21T16:21:16.000+08:00
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        resettleTime,
      },
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId: `16-${roundId}`, // 16-20230224077
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId,
      roundId: `16-${roundId}`, // 16-20230224077
      betAmount,
      updateTime,
      voidType,
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
