export default [
  {
    name: 'Bet > AdjustAmt > AdjustAmt > AdjustAmt > Settle',
    functionName: 'bet_adjustAmt1_adjustAmt2_adjustAmt3_settle',
  },
  {
    name: 'Bet > AdjustAmt > AdjustAmt > CancelAdjustAmt > AdjustAmt > CancelAdjustAmt > Settle',
    functionName: 'bet_adjustAmt1_adjustAmt2_cancelAdjustAmt2_adjustAmt3_cancelAdjustAmt3_settle',
  },
  {
    name: 'Bet > AdjustAmt > CancelAdjustAmt 5 times > Settle',
    functionName: 'bet_adjustAmt_cancelAdjustAmt5Times_settle',
  },
  {
    name: 'Bet > CancelAdjustAmt only > Settle',
    functionName: 'bet_cancelAdjustAmtOnly_settle',
  },
  {
    name: 'Bet > CancelAdjustAmt > AdjustAmt > Settle',
    functionName: 'bet_cancelAdjustAmt_adjustAmt_settle',
  },
]
