export default [
  {
    name: 'Bet > Settle Win > Settle Win > Settle Close',
    functionName: 'bet_settleWin_settleWin_settleClose',
  },
  {
    name: 'Bet > Settle Lose & Close',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Win 5 times > Settle Close',
    functionName: 'bet_settleWin5Times_settleClose',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times > Settle Close',
    functionName: 'bet_asyncSettleWin5Times_settleClose',
  },
  {
    name: 'Bet > Settle Win > Settle Close 5 times',
    functionName: 'bet_settleWin_settleClose5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
