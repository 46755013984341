import PlatformActions from './PlatformActions'

export default class BGActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'LIVE',
      gameCode: 'BG-LIVE-002',
      platform: 'BG',
    }

    this.constantGameInfo = {
      fromIp: '172.168.0.1',
      orderFrom: 1, // 注單來源，1: PC Web,  2: PC Client, 3: Android APP, 4: iOS APP, 5: Android Pad, 6: iOS Pad, 7: System Backend, 8: HTML 5 (mobile), 9: WeChat
    }

    this.playId = {
      // Baccarat
      AnyPair: '268435458',
      PlayerPair: '268435712',
      Player: '268435457',
      Tie: '268435460',
      Banker: '268435968',
      PerfectPair: '268435584',
      BankerPair: '268435520',
      // Roulette
      Direct: '536870913',
      Separate: '536870914',
      Street: '536870916',
      Triangle: '536870920',
      Corner: '536870928',
      FourNumbers: '536870944',
      Line: '536870976',
      Column: '536871040',
      Dozen: '536871168',
      Red: '536871424',
      Black: '536871424',
      Odd: '536871936',
      Even: '536871936',
      Big: '536872960',
      Small: '536872960',
    }

    this.orderStatus = {
      win: 2,
      tie: 3,
      lose: 4,
      void: 7,
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      gameName = 'Baccarat',
      gameCode = this.constantTxnData.gameCode,
      userId,
      txnId,
      roundId,
      betType,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName,
      gameCode,
      userId,
      platformTxId: `${txnId}`, // 9617168986
      roundId: `BGA01${roundId}`, // BGA01211227181 (BG A01桌號 211227日期 181)
      betType: null,
      currency,
      betTime, // 2021-12-27T17:45:38.000+08:00
      betAmount,
      gameInfo: {
        ...this.constantGameInfo,
        playId: this.playId[betType],
        userId, // 459812505
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      gameName = 'Baccarat',
      gameCode = this.constantTxnData.gameCode,
      userId,
      txnId,
      roundId,
      betType,
      betAmount,
      winAmount,
      turnover,
      betTime,
      updateTime,
      orderStatus,
      gameResult,
      resettleTime,
    }) => ({
      ...this.constantTxnData,
      gameName,
      gameCode,
      userId,
      platformTxId: `${txnId}`,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      roundId: `BGA01${roundId}`,
      betType: null,
      turnover,
      betAmount,
      winAmount,
      betTime,
      txTime: betTime,
      updateTime, // 2021-10-01T13:11:16.017+08:00
      gameInfo: {
        ...this.constantGameInfo,
        playId: this.playId[betType],
        userId,
        sn: 'au05',
        orderStatus, // 0: 注單不存在；1: 未結算；2: 結算贏；3: 結算和；4: 結算輸；5: 用戶取消；6: 過期；7: 系統取消
        gameResult, // MjY4NDM1NDY2
        resettleTime, // 2022-01-18T01:33:32.000+08:00
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  // getUnsettleMessage(txnList) {
  //   const txns = txnList.map(({
  //     gameName = 'Baccarat',
  //     gameCode = this.constantTxnData.gameCode,
  //     userId,
  //     txnId,
  //     roundId,
  //     betAmount,
  //     updateTime,
  //     gameResult,
  //     resettleTime,
  //     resettleResult,
  //   }) => ({
  //     ...this.constantTxnData,
  //     gameName,
  //     gameCode,
  //     userId,
  //     platformTxId: `${txnId}`,
  //     updateTime,
  //     roundId: `BGA01${roundId}`,
  //     betAmount,
  //     gameInfo: {
  //       ...this.constantGameInfo,
  //       // playId: this.playId[betType],
  //       userId,
  //       orderStatus: this.orderStatus[resettleResult], // 0: 注單不存在；1: 未結算；2: 結算贏；3: 結算和；4: 結算輸；5: 用戶取消；6: 過期；7: 系統取消
  //       gameResult, // MjY4NDM1NDY2
  //       resettleTime, // 2022-01-18T01:33:32.000+08:00
  //     },
  //   }))

  //   const message = {
  //     action: 'unsettle',
  //     txns,
  //   }

  //   return JSON.stringify(message)
  // }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      gameName = 'Baccarat',
      gameCode = this.constantTxnData.gameCode,
      userId,
      txnId,
      roundId,
      betType,
      betAmount,
      winAmount,
      turnover,
      updateTime,
      gameResult,
      resettleTime,
      resettleResult,
    }) => ({
      ...this.constantTxnData,
      gameName,
      gameCode,
      userId,
      platformTxId: `${txnId}`,
      updateTime,
      roundId: `BGA01${roundId}`,
      betType: null,
      betAmount,
      winAmount,
      turnover,
      gameInfo: {
        ...this.constantGameInfo,
        playId: this.playId[betType],
        userId,
        sn: 'au05',
        orderStatus: this.orderStatus[resettleResult], // 0: 注單不存在；1: 未結算；2: 結算贏；3: 結算和；4: 結算輸；5: 用戶取消；6: 過期；7: 系統取消
        gameResult, // MjY4NDM1NDY2
        resettleTime, // 2022-01-18T01:33:32.000+08:00
      },
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      gameName = 'Baccarat',
      gameCode = this.constantTxnData.gameCode,
      userId,
      txnId,
      roundId,
      betType,
      betAmount,
      voidType,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      gameName,
      gameCode,
      userId,
      platformTxId: `${txnId}`,
      roundId: `BGA01${roundId}`,
      betAmount,
      updateTime,
      voidType,
      gameInfo: {
        ...this.constantGameInfo,
        playId: this.playId[betType],
        userId,
      },
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      gameName = 'Baccarat',
      gameCode = this.constantTxnData.gameCode,
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
    }) => ({
      ...this.constantTxnData,
      gameName,
      gameCode,
      userId,
      platformTxId: `${txnId}`,
      updateTime,
      roundId: `BGA01${roundId}`,
      voidType,
      betAmount,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      gameCode = this.constantTxnData.gameCode,
      userId,
      txnId,
      roundId,
      betType,
    }) => ({
      ...this.constantTxnData,
      gameCode,
      userId,
      platformTxId: `${txnId}`,
      roundId: `BGA01${roundId}`,
      gameInfo: {
        ...this.constantGameInfo,
        playId: this.playId[betType],
        userId,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
