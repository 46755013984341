import moment from 'moment'

import PlatformRequests from './PlatformRequests'

import {
  ALL_BET_TYPE_WINNERS,
  ALL_BET_TYPE_ODDS,
} from './constants/roulette'

export default class BGRequests extends PlatformRequests {
  async multiBets() {
    const {
      userId1: userId,
      betAmount,
    } = this.formData

    const playerIndex = 0

    const PLACE_BET_TYPES = ['Red', 'Even', 'Small', 'Dozen 2nd', 'Column 3rd', 'Triangle 0,1,2', 'Four Numbers 0,1,2,3', 'Direct 11', 'Direct 20', 'Separate 5,8', 'Line 28,29,30,31,32,33', 'Direct 36', 'Line 19,20,21,22,23,24', 'Line 13,14,15,16,17,18', 'Direct 23', 'Separate 13,16', 'Separate 3,6', 'Separate 10,11', 'Separate 11,12', 'Direct 21', 'Direct 13', 'Direct 30', 'Direct 31', 'Separate 7,8', 'Separate 11,12', 'Separate 16,19', 'Separate 25,26', 'Separate 34,35', 'Street 10,11,12', 'Street 13, 14,15', 'Street 16,17,18', 'Corner 4,5,7,8', 'Corner 7,8,10,11', 'Corner 16,17,19,20', 'Corner 19,20,22,23', 'Corner 31,32,34,35', 'Separate 32,33', 'Direct 15', 'Direct 18', 'Direct 19', 'Direct 28', 'Line 19,20,21,22,23,24', 'Line 1,2,3,4,5,6', 'Direct 27', 'Direct 28']
    const { txnId, roundId } = await this.getIdData()

    let betTime
    this.betData[playerIndex] = this.betData[playerIndex] || []
    this.betData[playerIndex][0] = PLACE_BET_TYPES.map((betType, txnIdx) => {
      const placedBetType = betType
      /* eslint-disable no-param-reassign */
      if (betType.startsWith('Four Numbers')) {
        betType = 'FourNumbers'
      } else {
        [betType] = betType.split(' ')
      }
      /* eslint-enable no-param-reassign */

      if (!txnIdx) betTime = moment().subtract(4, 'seconds').utcOffset(8).toISOString(true)
      return {
        gameName: 'Roulette',
        gameCode: 'BG-LIVE-003',
        userId,
        roundId,
        txnId: txnId + txnIdx,
        odds: ALL_BET_TYPE_ODDS[placedBetType],
        betType,
        placedBetType,
        betAmount,
        betTime,
      }
    })

    this.tests.betData = this.betData

    const res = await this.actions.bet(this.betData[playerIndex].flat())

    return this.requestPromise(
      {
        action: 'bet',
        requestName: 'Bet',
      },
      res,
      () => this.tests.bet({
        requestNo: this.requestNo,
        res: res.data,
        playerIndex,
      }),
    )
  }

  async multiSettle() {
    const winner = 20
    const winBetTypes = Object.keys(ALL_BET_TYPE_WINNERS).filter(betType => ALL_BET_TYPE_WINNERS[betType].includes(winner))

    let updateTime
    const settleData = this.betData.map((playerBetData, playerIdx) => playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
      const { betAmount, odds, placedBetType } = txnBetData

      const isWin = winBetTypes.includes(placedBetType)
      if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)
      return {
        ...txnBetData,
        odds: isWin ? odds : -1,
        winAmount: isWin ? betAmount * (1 + odds) : 0,
        winLoss: isWin ? betAmount * odds : betAmount * -1,
        turnover: betAmount * 1,
        orderStatus: isWin ? 2 : 4,
        gameResult: 'MjQ=',
        updateTime,
      }
    })))

    this.tests.settleData = settleData

    const res = await this.actions.settle(settleData.flat().flat())

    return this.requestPromise(
      {
        action: 'settle',
        requestName: 'Settle',
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async multiVoidBets() {
    let updateTime
    const voidBetData = this.betData.map((playerBetData, playerIdx) => playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
      if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)
      return {
        ...txnBetData,
        voidType: 2,
        updateTime,
      }
    })))

    this.tests.voidBetData = voidBetData

    const res = await this.actions.voidBet(voidBetData.flat().flat())

    return this.requestPromise(
      {
        action: 'voidBet',
        requestName: 'VoidBet',
      },
      res,
      () => this.tests.voidBet({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async multiCancelBets() {
    const cancelBetData = this.betData.map(playerBetData => playerBetData.map(singleBetData => singleBetData.map(txnBetData => txnBetData)))

    this.tests.cancelBetData = cancelBetData

    const res = await this.actions.cancelBet(cancelBetData.flat().flat())

    return this.requestPromise(
      {
        action: 'cancelBet',
        requestName: 'CancelBet',
      },
      res,
      () => this.tests.cancelBet({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }
}
