export default [
  {
    name: 'Bet > AdjustBet > Settle Win',
    functionName: 'bet_adjustBet_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose',
    functionName: 'bet_adjustBet_settleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie',
    functionName: 'bet_adjustBet_settleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Win 5 times',
    functionName: 'bet_adjustBet_settleWin5Times',
  },
  {
    name: 'Bet > AdjustBet > (Network) Settle Win 5 times',
    functionName: 'bet_adjustBet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Unsettle > Settle Win',
    functionName: 'bet_adjustBet_settleLose_unsettle_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Unsettle > Settle Lose',
    functionName: 'bet_adjustBet_settleWin_unsettle_settleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Unsettle > Settle Tie',
    functionName: 'bet_adjustBet_settleWin_unsettle_settleTie',
  },
  {
    name: 'Bet > AdjustBet > VoidBet',
    functionName: 'bet_adjustBet_voidBet',
  },
  {
    name: 'Bet > AdjustBet > VoidBet 5 times',
    functionName: 'bet_adjustBet_voidBet5Times',
  },
  {
    name: 'Bet > AdjustBet > VoidBet > UnvoidBet > Settle Win',
    functionName: 'bet_adjustBet_voidBet_unvoidBet_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Unsettle > VoidBet > UnvoidBet > Settle Win',
    functionName: 'bet_adjustBet_settleWin_unsettle_voidBet_unvoidBet_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > VoidSettle > UnvoidSettle',
    functionName: 'bet_adjustBet_settleWin_voidSettle_unvoidSettle',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > VoidSettle 5 times',
    functionName: 'bet_adjustBet_settleWin_voidSettle5Times',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Refund',
    functionName: 'bet_adjustBet_settleLose_refund',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Refund 5 times',
    functionName: 'bet_adjustBet_settleLose_refund5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Negative Balance - Settle Win > Unsettle > Settle Lose',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_unsettle1_settleLose1_settleWin2',
  },
  {
    name: 'Negative Balance - Settle Win > Unsettle > Settle Tie',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_unsettle1_settleTie1_settleWin2',
  },
  {
    name: 'Negative Balance - Settle Win > Unsettle > VoidBet > UnvoidBet > Settle Win',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_unsettle1_voidBet1_unvoidBet1_settleWin1_settleWin2',
  },
  {
    name: 'Multi Bets - Place 3 bets on WALA / MERON / BDD > Settle Win on WALA',
    functionName: 'multiBets_bet1_adjustBet1_bet2_bet3_settleWinOnWALA',
  },
  {
    name: 'Multi Bets - Place 3 bets on WALA / MERON / BDD > Settle Win on MERON',
    functionName: 'multiBets_bet1_bet2_bet3_settleWinOnMERON',
  },
  {
    name: 'Multi Bets - Place 3 bets on WALA / MERON / BDD > Settle Win on BDD',
    functionName: 'multiBets_bet1_adjustBet1_bet2_bet3_settleWinOnBDD',
  },
  {
    name: 'Multi Bets - Place 3 bets on WALA / MERON / BDD > Settle Win on FTD',
    functionName: 'multiBets_bet1_adjustBet1_bet2_bet3_settleWinOnFTD',
  },
  {
    name: 'Multi Bets with 3 Players - Place 20 bets > Settle 20 bets',
    functionName: 'multiPlayers_multiBets_bet20_settle20',
  },
  {
    name: 'Multi Bets with 3 Players - Place 3 bets > Settle > Unsettle > Settle',
    functionName: 'multiPlayers_multiBets_bet1_bet2_bet3_settle_unsettle_settle',
  },
  {
    name: 'Multi Players - Bet > AdjustBet > Settle Lose > Refund',
    functionName: 'multiPlayers_bet_adjustBet_settleLose_refund',
  },
  {
    name: 'Odds Change - Bet > AdjustBet > Settle Win (negative odds)',
    functionName: 'bet_adjustBetForOddsChange_settleWin',
  },
  {
    name: 'Odds Change - Bet > AdjustBet > Settle Win (positive odds)',
    functionName: 'betOnPositiveOdds_adjustBetForOddsChange_settleWin',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
