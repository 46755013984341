<template>
  <AntTable
    :rowKey="(record, index) => `scenario${scenarioIndex}-request${index}`"
    :columns="requestColumns"
    :dataSource="requests"
    :pagination="false"
    :defaultExpandAllRows="false"
    :expandedRowKeys="expandedRowKeys.request"
    @expandedRowsChange="onExpandedRowsChange"
    size="small"
  >
    <template #passed="{ record }">
      <v-switch :case="getRequestResult(record).toString()">
        <template #warning>
          <AntBadge status="warning" />{{ t('pass') }}
        </template>
        <template #true>
          <AntBadge status="success" />{{ t('pass') }}
        </template>
        <template #false>
          <AntBadge status="error" />{{ t('fail') }}
        </template>
      </v-switch>
    </template>

     <template #resquestName="{ text, record, index }">
      <div
        v-if="record.extraData?.retrySettle && !retriedButtonIndex.includes(`scenario${scenarioIndex}-request${index}`)"
        class="retry-btn"
      >
        <AntButton
          class="retry-btn"
          size="small"
          shape="circle"
          @click="() => onClickRetry(record.extraData.retrySettle, `scenario${scenarioIndex}-request${index}`)"
          type="primary"
          :disabled="retryLoading"
        >
          <template #icon>
            <RetryIcon />
          </template>
        </AntButton>
      </div>
      <span v-html="text.replaceAll('\n', '<br>')"></span>
      <div v-if="record.extraData?.settleTxnIds" class="settle-txnIds-msg">
        {{ t('confirm-settled-platformTxId-msg1') }}
        <div>
          <div
            v-for="txnId in record.extraData.settleTxnIds"
            :key="txnId"
            class="settle-txnId"
          >
            {{ txnId }}
          </div>
        </div>
        {{ t('confirm-settled-platformTxId-msg2') }}
      </div>
    </template>

    <template #responseCode="{ text }">
      <AntTag v-if="text" :color="text.includes('20') ? '' : 'red'">{{ text }}</AntTag>
    </template>

    <template #duration="{ text }">
      <span v-if="text">{{ text }} ms</span>
    </template>

    <template #expandedRowRender="{ record, index }">
      <slot name="expandedRowRender" :record="record" :index="index"></slot>
    </template>
  </AntTable>
</template>

<script>
import {
  ref,
  reactive,
  inject,
  watch,
  computed,
  toRaw,
} from 'vue'

import VSwitch from '@lmiller1990/v-switch'

import {
  Table as AntTable,
  Badge as AntBadge,
  Button as AntButton,
  Tag as AntTag,
} from 'ant-design-vue'

import RetryIcon from '@ant-design/icons-vue/lib/icons/ReloadOutlined'

export default {
  name: 'TestRequestTable',
  components: {
    VSwitch,
    AntTable,
    AntBadge,
    AntButton,
    AntTag,
    RetryIcon,
  },
  props: {
    record: {
      type: Object,
      default() {
        return {}
      },
    },
    scenarioIndex: {
      type: Number,
      default: 0,
    },
    expandedRowKeys: Object,
  },
  setup(props) {
    const t = inject('t')
    const requests = ref(props.record.requests)
    const expandedRowKeys = reactive(props.expandedRowKeys)
    const retriedButtonIndex = ref([])
    const retryLoading = ref(false)

    const requestColumns = computed(() => [
      {
        key: 'requestPassed',
        slots: { customRender: 'passed' },
        width: 100,
      },
      {
        title: t('resquest-name'),
        dataIndex: 'name',
        key: 'resquestName',
        slots: { customRender: 'resquestName' },
      },
      {
        title: t('response-code'),
        dataIndex: 'responseCode',
        key: 'responseCode',
        slots: { customRender: 'responseCode' },
        align: 'center',
        width: 180,
      },
      {
        title: t('duration'),
        dataIndex: 'duration',
        key: 'duration',
        slots: { customRender: 'duration' },
        align: 'right',
        width: 100,
      },
    ])

    const onExpandedRowsChange = rowKeys => expandedRowKeys.request = rowKeys

    const getRequestResult = ({ passed, tests }) => {
      const haveWarning = tests.some(({ warning }) => !!warning)

      // eslint-disable-next-line no-nested-ternary
      return passed
        ? haveWarning ? 'warning' : passed
        : passed
    }

    watch(() => props.record, () => {
      retriedButtonIndex.value = []
      requests.value = [...toRaw(props.record.requests)]
    })

    const onClickRetry = async (retrySettle, index) => {
      retryLoading.value = true
      await retrySettle()
      requests.value = [...toRaw(props.record.requests)]
      retryLoading.value = false
      retriedButtonIndex.value.push(index)
    }

    return {
      t,
      requests,
      requestColumns,
      retryLoading,
      retriedButtonIndex,
      onExpandedRowsChange,
      getRequestResult,
      onClickRetry,
    }
  },
}
</script>

<style scoped>
.settle-txnIds-msg {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
}

.settle-txnId {
  display: inline-block;
  margin: 0 4px 2px 0;
  padding: 0 4px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.retry-btn.ant-btn .anticon {
  line-height: 0;
  vertical-align: middle;
}

.retry-btn {
  display: inline-block;
  margin-right: 4px;
}
</style>
