import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class KINGMAKERTABLETestScenarios extends PlatformTestScenarios {
  async bet_refSettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { settleType: 'refPlatformTxId' } })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_refSettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { settleType: 'refPlatformTxId' } })
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_refSettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.settleWin({ timesLoop })
        }

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_asyncRefSettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        await Promise.all(Array.from({ length: 5 }).map(async (_, timesLoop) => {
          await this.requests.settleWin({
            timesLoop,
            options: { isAsync: true },
          })
          return true
        }))

        await this.requests.getBalance({ action: 'asyncSettle' })
      },
    )
  }

  async multiBets_bet1_bet2_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        for (let roundIdx = 0; roundIdx < 2; roundIdx += 1) {
          await this.requests.bet({
            betIndexes: [roundIdx],
            options: { differentRound: true },
          })
        }

        await this.requests.settleWin({ betIndexes: [0, 1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_cancelBet3_cancelBet2_cancelBet1() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = 3
        for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
          await this.requests.bet({ betIndexes: [betIndex] })
        }

        for (let betIndex = BET_LENGTH - 1; betIndex >= 0; betIndex -= 1) {
          await this.requests.cancelBet({ betIndexes: [betIndex] })
        }
      },
    )
  }

  async multiBets_bet1_bet2_bet3_cancelBet3_settle12() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        for (let betIndex = 0; betIndex < 3; betIndex += 1) {
          await this.requests.bet({ betIndexes: [betIndex] })
        }

        await this.requests.cancelBet({ betIndexes: [2] })

        await this.requests.settleWin({ betIndexes: [0, 1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_bet4_cancelBet1234() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        for (let betIndex = 0; betIndex < 4; betIndex += 1) {
          await this.requests.bet({ betIndexes: [betIndex] })
        }

        await this.requests.cancelBet({ betIndexes: [0, 1, 2, 3] })
      },
    )
  }
}
