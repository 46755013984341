<template>
  <AntLayoutFooter style="text-align: center">
    Copyright © 2021 AWC
  </AntLayoutFooter>
</template>

<script>
import { Layout as AntLayout } from 'ant-design-vue'

export default {
  name: 'Footer',
  components: {
    AntLayoutFooter: AntLayout.Footer,
  },
}
</script>

<style scoped>
</style>
