import PlatformActions from './PlatformActions'

export default class PGSLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'PG-SLOT-054',
      platform: 'PG',
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'SLOT',
      gameName: 'dream-of-macau',
      // gameCode: 'PG-SLOT-054',
      userId,
      // platform: 'PG',
      platformTxId: `${txnId}`, // ex: 1443805690186534912
      roundId: `${txnId}-${txnId}-201-0`, // ex: 1443805690186534912-1443805690186534912-201-0
      betType: '1',
      currency,
      betTime, // ex: 2021-10-01T13:11:15.798+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'SLOT',
      gameName: 'dream-of-macau',
      // gameCode: 'PG-SLOT-054',
      userId,
      // platform: 'PG',
      platformTxId: `${txnId}`,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // ex: 2021-10-01T13:11:16.017+08:00
      roundId: `${txnId}-${txnId}-201-0`,
      betType: '1',
      betTime, // ex: 2021-10-01T13:11:15.798+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        freeGameTransactionId: '',
        bonusTransactionId: '',
        isFeature: false,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'SLOT',
      // gameCode: 'PG-SLOT-054',
      userId,
      // platform: 'PG',
      platformTxId: `${txnId}`,
      roundId: `${txnId}-${txnId}-201-0`,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
