import PlatformActions from './PlatformActions'

export default class JDBFISHActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'FH',
      gameName: 'Cai Shen Fishing',
      gameCode: 'JDB-FISH-008',
      platform: 'JDB',
    }

    this.constantGameInfo = {
      isEndRound: true,
      denom: 0.01,
      systemTakeWin: 0,
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
      requireAmount,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 842858
      roundId, // 5250171846865
      betType: '',
      currency,
      betTime, // 2021-11-27T08:57:18.000+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      jackpotBetAmount: 0,
      jackpotWinAmount: 0,
      updateTime, // 2021-11-27T08:57:19.650+08:00
      requireAmount: requireAmount ?? betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId,
      updateTime,
      betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
