import moment from 'moment'
import PlatformRequests, { getRequestName } from './PlatformRequests'

import { updatePlatform } from '../../../api/platform'

export default class HORSEBOOKRequests extends PlatformRequests {
  async betOnWP(args = {}) {
    const { betIndexes = [0], data } = args

    const {
      platform,
      userId1: userId,
      betAmount,
    } = this.formData

    const [betIndex] = betIndexes

    const PLACE_BET_TYPES = ['WIN', 'PLC']
    const betTypeLength = PLACE_BET_TYPES.length

    const { data: { txnId, roundId } } = await updatePlatform({
      platform,
      txnId: betTypeLength,
      roundId: 1,
    })

    let betTime
    this.betData[0] = this.betData[0] || []
    this.betData[0][betIndex] = PLACE_BET_TYPES.map((betType, i) => {
      if (!i) betTime = moment().subtract(4, 'seconds').utcOffset(8).toISOString(true)
      return {
        userId,
        roundId,
        txnId: txnId - betTypeLength + 1 + i,
        odds: 0.5,
        betType,
        betAmount,
        betTime,
        runnerNo: '1',
        ...data,
      }
    })

    this.tests.betData = this.betData

    const res = await this.actions.bet(this.betData[0][betIndex], {
      platform,
      txnId,
      roundId,
    })

    return this.requestPromise(
      {
        action: 'bet',
        requestName: getRequestName({ ...args, action: 'bet on WP' }),
      },
      res,
      () => this.tests.bet({
        requestNo: this.requestNo,
        res: res.data,
        betIndex,
      }),
    )
  }

  async settleOnWP() {
    const settleData = await this.getSettleData({
      settleInfo: {
        top3Data: [
          { runnerNo: '3' },
          { runnerNo: '2' },
          { runnerNo: '1' },
        ],
      },
    })

    this.tests.settleData = settleData

    const res = await this.actions.settle(settleData[0].flat())

    return this.requestPromise(
      {
        action: 'settle',
        requestName: 'Settle Win on PLC & Settle Lose on WIN',
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }
}
