import PlatformActions from './PlatformActions'

export default class KINGMAKERTABLEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      platformTxId: {
        gameType: 'TABLE',
        gameCode: 'KM-TABLE-009',
        platform: 'KINGMAKER',
      },
      refPlatformTxId: {
        gameType: 'TABLE',
        gameCode: 'KM-TABLE-066',
        platform: 'KINGMAKER',
      },
    }

    this.gameName = {
      platformTxId: 'thai-hi-lo2',
      refPlatformTxId: 'Coin Pusher',
    }
  }

  getBetMessage(txnList) {
    const { currency, userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      settleType = 'platformTxId',
    }) => {
      const isTxIdSettleType = settleType === 'platformTxId'

      return {
        ...this.constantTxnData[settleType],
        gameName: this.gameName[settleType],
        userId,
        platformTxId: isTxIdSettleType ? txnId : `SLOT-CD-${roundId}`, // 61a47b069d20f800b03cb82e | SLOT-CD-zgJoZrYaWaW0X
        roundId: isTxIdSettleType ? `hl-2-${roundId}` : `SLOT-CD-${roundId}`, // hl-2-qk5QZ3r0 | SLOT-CD-zgJoZrYaWaW0X
        betType: null,
        currency,
        betTime, // 2021-10-01T13:11:15.798+08:00
        betAmount,
        gameInfo: {
          extension1: `${userIdPrefix}`,
          ...(isTxIdSettleType && { betType: 'hl-2 : 10.0' }),
        },
      }
    })

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      betTime,
      turnover,
      betAmount,
      winAmount,
      settleType = 'platformTxId',
    }) => {
      const isTxIdSettleType = settleType === 'platformTxId'

      return {
        ...this.constantTxnData[settleType],
        gameName: this.gameName[settleType],
        userId,
        platformTxId: isTxIdSettleType ? txnId : `slot-${txnId}`, // 61a47b069d20f800b03cb82e | slot-cd000615061753a552691361a15a490000
        refPlatformTxId: isTxIdSettleType ? null : `SLOT-CD-${roundId}`, // SLOT-CD-zgJoZrYaWaW0X
        settleType,
        updateTime, // 2021-11-02T00:55:56.211+08:00
        roundId: isTxIdSettleType ? `hl-2-${roundId}` : `SLOT-CD-${roundId}`, // hl-2-qk5QZ3r0 | SLOT-CD-zgJoZrYaWaW0X
        betType: null,
        betTime,
        txTime: betTime,
        turnover, // 50
        betAmount, // 50
        winAmount, // 97.5
        gameInfo: {
          reason: isTxIdSettleType ? 'payout' : 'close',
          agentId: `${userIdPrefix}`,
          // https://uat.supports.kingdomhall729.com/plays/hl-2-qk5QZ3r0/by_reference_number?transId=61a47b069d20f800b03cb82e
          // https://uat.supports.kingdomhall729.com/plays/SLOT-CD-zgJoZrYaWaW0X/by_reference_number?transid=slot-cd000615061753a552691361a15a490000
          ...(isTxIdSettleType ? {
            extension1: `${userIdPrefix}`,
            betType: 'hl-2 : 10.0',
            commission: 0,
          } : {
            isEndRound: true,
            refId: `slot-${txnId}`,
          }),
          link: `https://fake.supports.kingdomhall729.com/plays/${isTxIdSettleType ? 'hl-2-' : 'SLOT-CD-'}${roundId}/by_reference_number?transid=${isTxIdSettleType ? '' : 'slot-'}${txnId}`,
          profit: winAmount - betAmount, // 47.5
        },
      }
    })

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      settleType = 'platformTxId',
    }) => ({
      ...this.constantTxnData[settleType],
      userId,
      platformTxId: txnId, // 61a47b069d20f800b03cb82e
      roundId: `hl-2-${roundId}`, // hl-2-qk5QZ3r0
      gameInfo: {
        extension1: `${userIdPrefix}`,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
      settleType = 'platformTxId',
    }) => ({
      ...this.constantTxnData[settleType],
      gameName: this.gameName,
      userId,
      platformTxId: txnId,
      roundId: `hl-2-${roundId}`, // hl-2-qk5QZ3r0
      betAmount,
      updateTime,
      voidType,
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getGiveMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      betAmount,
      promotionTxId,
      txTime,
    }) => ({
      platform: this.constantTxnData.platformTxId.platform,
      currency,
      userId,
      amount: betAmount,
      promotionTxId, // 458f66657f8c11ec960e06e4f9340382
      promotionId: '657',
      promotionTypeId: '17',
      txTime,
    }))

    const message = {
      action: 'give',
      txns,
    }

    return JSON.stringify(message)
  }
}
