import EVOLUTIONActions from './EVOLUTIONActions'

export default class BTGActions extends EVOLUTIONActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'BTG-SLOT-017',
      platform: 'BTG',
    }

    this.gameName = 'Big Bad Bison'
  }
}
