import moment from 'moment'
import { capitalize } from 'lodash'

import PlatformRequests from './PlatformRequests'

export default class AELOTTORequests extends PlatformRequests {
  async multiBets() {
    const {
      userId1: userId,
      betAmount,
    } = this.formData

    const playerIndex = 0
    const PLACE_BET_ITEMS = ['04', '13', '36', '61', '89']

    const { txnId, roundId } = await this.getIdData()

    let betTime
    let roundDate

    this.betData[playerIndex] = this.betData[playerIndex] || []
    this.betData[playerIndex][0] = PLACE_BET_ITEMS.map((betItems, txnIdx) => {
      if (!txnIdx) {
        betTime = moment().subtract(4, 'seconds').utcOffset(8).toISOString(true)
        roundDate = moment().second(0).add(1, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ')
      }
      return {
        txnId: txnId + txnIdx,
        roundId,
        userId,
        odds: 80,
        betAmount,
        betTime,
        betItems,
        roundDate,
      }
    })

    this.tests.betData = this.betData

    const res = await this.actions.bet(this.betData[playerIndex].flat())

    return this.requestPromise(
      {
        action: 'bet',
        requestName: 'Bet',
      },
      res,
      () => this.tests.bet({
        requestNo: this.requestNo,
        res: res.data,
        playerIndex,
      }),
    )
  }

  async multiSettle({ settleResult }) {
    const winner = '13'

    let updateTime
    const settleData = this.betData.map((playerBetData, playerIdx) => playerBetData.map((singleBetData, betIdx) => {
      const txnsData = []
      singleBetData.forEach((txnBetData, txnIdx) => {
        const {
          betAmount,
          odds,
          betItems,
        } = txnBetData

        const isWin = betItems === winner
        if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)

        if ((settleResult === 'win' && isWin) || (settleResult === 'lose' && !isWin)) {
          txnsData.push({
            ...txnBetData,
            winAmount: isWin ? betAmount * (1 + odds) : 0,
            turnover: betAmount * 1,
            updateTime,
          })
        }
      })
      return txnsData
    }))

    this.tests.settleData = settleData

    const res = await this.actions.settle(settleData.flat().flat())

    return this.requestPromise(
      {
        action: 'settle',
        requestName: `Settle ${capitalize(settleResult)}`,
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async multiCancelBets() {
    const cancelBetData = this.betData.map(playerBetData => playerBetData.map(singleBetData => singleBetData.map(txnBetData => txnBetData)))

    this.tests.cancelBetData = cancelBetData

    const res = await this.actions.cancelBet(cancelBetData.flat().flat())

    return this.requestPromise(
      {
        action: 'cancelBet',
        requestName: 'CancelBet',
      },
      res,
      () => this.tests.cancelBet({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async multiVoidBets() {
    let updateTime
    const voidBetData = this.betData.map((playerBetData, playerIdx) => playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
      if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)
      return {
        ...txnBetData,
        voidType: 2,
        updateTime,
      }
    })))

    this.tests.voidBetData = voidBetData

    const res = await this.actions.voidBet(voidBetData.flat().flat())

    return this.requestPromise(
      {
        action: 'voidBet',
        requestName: 'VoidBet',
      },
      res,
      () => this.tests.voidBet({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }
}
