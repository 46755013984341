import PlatformActions from './PlatformActions'

export default class YESBINGOSLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'EGAME',
      gameCode: 'YesBingo-EGAME-001',
      platform: 'YESBINGO',
    }

    this.gameName = 'Crazy Color'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 1411560809
      roundId: `${roundId}`, // 3566558193
      betType: null,
      currency,
      betTime, // 2024-08-06T13:52:57.042+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`,
      refPlatformTxI: null,
      settleType: 'platformTxId',
      updateTime, // 2024-08-06T13:53:59.648+08:00
      roundId: `${roundId}`,
      betType: null,
      betTime, // 2024-08-06T13:52:57.042+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {},
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${txnId}`,
      roundId: `${roundId}`,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
