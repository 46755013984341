export const ALL_BET_TYPE_WINNERS = {
  // 黑紅，2組合
  Red: [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
  Black: [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],

  // 單雙，2組合
  Even: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
  Odd: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35],

  // 大小，2組合
  Small: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  Big: [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],

  // 三角 2組合
  'Triangle 0,1,2': [0, 1, 2],
  'Triangle 0,2,3': [0, 2, 3],

  // 四個號碼 1組合
  'Four Numbers 0,1,2,3': [0, 1, 2, 3],

  // 行一行二行三，3組合
  'Column 1st': [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
  'Column 2nd': [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
  'Column 3rd': [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],

  // 打一打二打三，3組合
  'Dozen 1st': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  'Dozen 2nd': [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  'Dozen 3rd': [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],

  // 1個數字，37組合
  'Direct 0': [0],
  'Direct 1': [1],
  'Direct 2': [2],
  'Direct 3': [3],
  'Direct 4': [4],
  'Direct 5': [5],
  'Direct 6': [6],
  'Direct 7': [7],
  'Direct 8': [8],
  'Direct 9': [9],
  'Direct 10': [10],
  'Direct 11': [11],
  'Direct 12': [12],
  'Direct 13': [13],
  'Direct 14': [14],
  'Direct 15': [15],
  'Direct 16': [16],
  'Direct 17': [17],
  'Direct 18': [18],
  'Direct 19': [19],
  'Direct 20': [20],
  'Direct 21': [21],
  'Direct 22': [22],
  'Direct 23': [23],
  'Direct 24': [24],
  'Direct 25': [25],
  'Direct 26': [26],
  'Direct 27': [27],
  'Direct 28': [28],
  'Direct 29': [29],
  'Direct 30': [30],
  'Direct 31': [31],
  'Direct 32': [32],
  'Direct 33': [33],
  'Direct 34': [34],
  'Direct 35': [35],
  'Direct 36': [36],

  // 6個數字，11組合
  'Line 1,2,3,4,5,6': [1, 2, 3, 4, 5, 6],
  'Line 4,5,6,7,8,9': [4, 5, 6, 7, 8, 9],
  'Line 7,8,9,10,11,12': [7, 8, 9, 10, 11, 12],
  'Line 10,11,12,13,14,15': [10, 11, 12, 13, 14, 15],
  'Line 13,14,15,16,17,18': [13, 14, 15, 16, 17, 18],
  'Line 16,17,18,19,20,21': [16, 17, 18, 19, 20, 21],
  'Line 19,20,21,22,23,24': [19, 20, 21, 22, 23, 24],
  'Line 22,23,24,25,26,27': [22, 23, 24, 25, 26, 27],
  'Line 25,26,27,28,29,30': [25, 26, 27, 28, 29, 30],
  'Line 28,29,30,31,32,33': [28, 29, 30, 31, 32, 33],
  'Line 31,32,33,34,35,36': [31, 32, 33, 34, 35, 36],

  // 直或橫2個數字，42組合
  'Separate 1,2': [1, 2], // 1st
  'Separate 2,3': [2, 3],
  'Separate 4,5': [4, 5],
  'Separate 5,6': [5, 6],
  'Separate 1,4': [1, 4],
  'Separate 2,5': [2, 5],
  'Separate 3,6': [3, 6],
  'Separate 4,7': [4, 7], // 1st-2nd
  'Separate 5,8': [5, 8],
  'Separate 6,9': [6, 9],
  'Separate 7,8': [7, 8], // 2nd
  'Separate 8,9': [8, 9],
  'Separate 10,11': [10, 11],
  'Separate 11,12': [11, 12],
  'Separate 7,10': [7, 10],
  'Separate 8,11': [8, 11],
  'Separate 9,12': [9, 12],
  'Separate 10,13': [10, 13], // 2nd-3rd
  'Separate 11,14': [11, 14],
  'Separate 12,15': [12, 15],
  'Separate 13,14': [13, 14], // 3rd
  'Separate 14,15': [14, 15],
  'Separate 16,17': [16, 17],
  'Separate 17,18': [17, 18],
  'Separate 13,16': [13, 16],
  'Separate 14,17': [14, 17],
  'Separate 15,18': [15, 18],
  'Separate 16,19': [16, 19], // 3rd-4th
  'Separate 17,20': [17, 20],
  'Separate 18,21': [18, 21],
  'Separate 19,20': [19, 20], // 4th
  'Separate 20,21': [20, 21],
  'Separate 22,23': [22, 23],
  'Separate 23,24': [23, 24],
  'Separate 19,22': [19, 22],
  'Separate 20,23': [20, 23],
  'Separate 21,24': [21, 24],
  'Separate 22,25': [22, 25], // 4-5th
  'Separate 23,26': [23, 26],
  'Separate 24,27': [24, 27],
  'Separate 25,26': [25, 26], // 5th
  'Separate 26,27': [26, 27],
  'Separate 28,29': [28, 29],
  'Separate 29,30': [29, 30],
  'Separate 25,28': [25, 28],
  'Separate 26,29': [26, 29],
  'Separate 27,30': [27, 30],
  'Separate 28,31': [28, 31], // 5-6th
  'Separate 29,32': [29, 32],
  'Separate 30,33': [30, 33],
  'Separate 31,32': [31, 32], // 6th
  'Separate 32,33': [32, 33],
  'Separate 34,35': [34, 35],
  'Separate 35,36': [35, 36],
  'Separate 31,34': [31, 34],
  'Separate 32,35': [32, 35],
  'Separate 33,36': [33, 36],

  // 直欄，12組合
  'Street 1,2,3': [1, 2, 3],
  'Street 4,5,6': [4, 5, 6],
  'Street 7,8,9': [7, 8, 9],
  'Street 10,11,12': [10, 11, 12],
  'Street 13,14,15': [13, 14, 15],
  'Street 16,17,18': [16, 17, 18],
  'Street 19,20,21': [19, 20, 21],
  'Street 22,23,24': [22, 23, 24],
  'Street 25,26,27': [25, 26, 27],
  'Street 28,29,30': [28, 29, 30],
  'Street 31,32,33': [31, 32, 33],
  'Street 34,35,36': [34, 35, 36],

  // 十字4個數字，22組合
  'Corner 1,2,4,5': [1, 2, 4, 5],
  'Corner 2,3,5,6': [2, 3, 5, 6],
  'Corner 4,5,7,8': [4, 5, 7, 8],
  'Corner 5,6,8,9': [5, 6, 8, 9],
  'Corner 7,8,10,11': [7, 8, 10, 11],
  'Corner 8,9,11,12': [8, 9, 11, 12],
  'Corner 10,11,13,14': [10, 11, 13, 14],
  'Corner 11,12,14,15': [11, 12, 14, 15],
  'Corner 13,14,16,17': [13, 14, 16, 17],
  'Corner 14,15,17,18': [14, 15, 17, 18], // 10
  'Corner 16,17,19,20': [16, 17, 19, 20],
  'Corner 17,18,20,21': [17, 18, 20, 21],
  'Corner 19,20,22,23': [19, 20, 22, 23],
  'Corner 20,21,23,24': [20, 21, 23, 24],
  'Corner 22,23,25,26': [22, 23, 25, 26],
  'Corner 23,24,26,27': [23, 24, 26, 27],
  'Corner 25,26,28,29': [25, 26, 28, 29],
  'Corner 26,27,29,30': [26, 27, 29, 30],
  'Corner 28,29,31,32': [28, 29, 31, 32],
  'Corner 29,30,32,33': [29, 30, 32, 33], // 20
  'Corner 31,32,34,35': [31, 32, 34, 35],
  'Corner 32,33,35,36': [32, 33, 35, 36],
}

export const BET_TYPE_ODDS = {
  Red: 1,
  Black: 1,
  Even: 1,
  Odd: 1,
  Small: 1,
  Big: 1,
  Triangle: 11,
  'Four Numbers': 8,
  Column: 2,
  Dozen: 2,
  Direct: 35,
  Line: 5,
  Separate: 17,
  Street: 11,
  Corner: 8,
}

export const ALL_BET_TYPE_ODDS = {} // { 'Column 1st': 2,  'Column 2nd': 2,  'Column 3rd': 2,  ... }
Object.keys(ALL_BET_TYPE_WINNERS).forEach((betType) => {
  const betTypeOddsEntries = Object.entries(BET_TYPE_ODDS)
  const oddIndex = betTypeOddsEntries.findIndex(([betType2]) => betType.startsWith(betType2))
  // eslint-disable-next-line prefer-destructuring
  ALL_BET_TYPE_ODDS[betType] = betTypeOddsEntries[oddIndex][1]
})
