import PlatformActions from './PlatformActions'

export default class HORSEBOOKActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'LIVE',
      // gameName: 'Horsebook', // cancelBet do NOT have this field
      gameCode: 'HRB-LIVE-001',
      platform: 'HORSEBOOK',
    }

    this.constantGameInfo = {
      locationName: 'AU(Har)',
      locationNameCn: '澳洲(马车)',
      countryNameCn: '澳洲51',
      runnerName: '---',
      locationId: 51,
      countryCode: 'AU',
      countryName: 'AU(Har)51',
      runnerType: 'Harness',
      raceNo: 9,
      foBetRule: 0,
    }

    this.categoryTypeIDList = {
      WIN: '1',
      PLC: '2',
    }

    this.currencyIDList = {
      SGD: 0,
      MYR: 1,
      HKD: 2,
      CNY: 3,
      JPY: 4,
      AUD: 5,
      IDR: 6,
      USD: 7,
      KRW: 8,
      THB: 9,
      TWD: 10,
      VND: 11,
      NZD: 12,
      VNDK: 13,
      IDRK: 14,
      INR: 15,
    }
  }

  getBetMessage(txnList) {
    const { userIdPrefix, currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType = 'WIN',
      betTime,
      betAmount,
      odds,
      runnerNo = '1',
      foBetRule,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'Horsebook',
      // gameCode: 'HRB-LIVE-001',
      userId,
      // platform: 'HORSEBOOK',
      platformTxId: `WIN/PLC${foBetRule != null ? '/FO' : ''}-${txnId}`,
      roundId: `${roundId}`,
      betType,
      currency,
      betTime,
      betAmount,
      gameInfo: {
        ...this.constantGameInfo,
        runnerNo,
        raceId: `${roundId}`,
        // locationName: 'AU(Har)',
        // locationNameCn: '澳洲(马车)',
        // countryNameCn: '澳洲51',
        ...(foBetRule != null && {
          foBetRule,
          fo: true,
          odds,
        }),
        gameMethod: `WIN_PLC${foBetRule != null ? '_FO' : ''}`,
        userId: `${userIdPrefix}${userId}`,
        stake: betAmount,
        // runnerName: '---',
        // locationId: 51,
        // countryCode: 'AU',
        categoryTypeID: this.categoryTypeIDList[betType],
        currency,
        // countryName: 'AU(Har)51',
        // runnerType: 'Harness',
        currencyID: this.currencyIDList[currency],
        // raceNo: 9,
        txnId,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getAdjustBetMessage(txnList) {
    const { userIdPrefix, currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType = 'WIN',
      updateTime,
      adjustAmount,
      betAmount,
      odds,
      runnerNo = '1',
      foBetRule,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'Horsebook',
      // gameCode: 'HRB-LIVE-001',
      userId,
      // platform: 'HORSEBOOK',
      platformTxId: `WIN/PLC${foBetRule != null ? '/FO' : ''}-${txnId}`,
      roundId: `${roundId}`,
      updateTime,
      adjustAmount,
      betAmount,
      gameInfo: {
        ...this.constantGameInfo,
        runnerNo,
        raceId: `${roundId}`,
        // locationName: 'AU(Har)',
        // locationNameCn: '澳洲(马车)',
        // countryNameCn: '澳洲51',
        ...(foBetRule != null && {
          foBetRule,
          fo: true,
          odds,
        }),
        gameMethod: `WIN_PLC${foBetRule != null ? '_FO' : ''}`,
        userId: `${userIdPrefix}${userId}`,
        stake: betAmount,
        // runnerName: '---',
        // locationId: 51,
        // countryCode: 'AU',
        categoryTypeID: this.categoryTypeIDList[betType],
        currency,
        // countryName: 'AU(Har)51',
        // runnerType: 'Harness',
        currencyID: this.currencyIDList[currency],
        // raceNo: 9,
        txnId,
      },
    }))

    const message = {
      action: 'adjustBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const { userIdPrefix, currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType = 'WIN',
      betTime,
      betAmount,
      winAmount,
      turnover,
      updateTime,
      voidType,
      odds,
      runnerNo = '1',
      foBetRule,
    }) => {
      const result = {
        ...this.constantTxnData,
        // gameType: 'LIVE',
        gameName: 'Horsebook',
        // gameCode: 'HRB-LIVE-001',
        userId,
        // platform: 'HORSEBOOK',
        platformTxId: `WIN/PLC${foBetRule != null ? '/FO' : ''}-${txnId}`,
        refPlatformTxId: null,
        settleType: 'platformTxId',
        updateTime,
        roundId: `${roundId}`,
        betType,
        betTime,
        txTime: betTime,
        turnover,
        betAmount,
        winAmount,
        gameInfo: {
          ...this.constantGameInfo,
          runnerNo,
          raceId: `${roundId}`,
          // locationName: 'AU(Har)',
          // locationNameCn: '澳洲(马车)',
          // countryNameCn: '澳洲51',
          ...(foBetRule != null && {
            foBetRule,
            fo: true,
          }),
          gameMethod: `WIN_PLC${foBetRule != null ? '_FO' : ''}`,
          userId: `${userIdPrefix}${userId}`,
          stake: betAmount,
          // runnerName: '---',
          // locationId: 51,
          // countryCode: 'AU',
          categoryTypeID: this.categoryTypeIDList[betType],
          currency,
          // countryName: 'AU(Har)51',
          // runnerType: 'Harness',
          currencyID: this.currencyIDList[currency],
          // raceNo: 9,
          txnId,
          ...((winAmount || foBetRule != null) && { odds }),
        },
      }

      // Settle after unvoidBet
      if (voidType) {
        result.gameInfo.voidType = voidType
        result.gameInfo.realWinAmt = 0
      }

      return result
    })

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const { userIdPrefix, currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType = 'WIN',
      betAmount,
      updateTime,
      voidType,
      odds,
      runnerNo = '1',
      foBetRule,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'Horsebook',
      // gameCode: 'HRB-LIVE-001',
      userId,
      // platform: 'HORSEBOOK',
      platformTxId: `WIN/PLC${foBetRule != null ? '/FO' : ''}-${txnId}`,
      roundId: `${roundId}`,
      betAmount,
      updateTime,
      voidType,
      gameInfo: {
        ...this.constantGameInfo,
        runnerNo,
        raceId: `${roundId}`,
        // locationName: 'AU(Har)',
        // locationNameCn: '澳洲(马车)',
        // countryNameCn: '澳洲51',
        ...(foBetRule != null && {
          foBetRule,
          fo: true,
          odds,
        }),
        gameMethod: `WIN_PLC${foBetRule != null ? '_FO' : ''}`,
        userId: `${userIdPrefix}${userId}`,
        stake: betAmount,
        // runnerName: '---',
        // locationId: 51,
        // countryCode: 'AU',
        categoryTypeID: this.categoryTypeIDList[betType],
        currency,
        // countryName: 'AU(Har)51',
        // runnerType: 'Harness',
        currencyID: this.currencyIDList[currency],
        // raceNo: 9,
        txnId,
      },
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getUnvoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      voidType,
      foBetRule,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'Horsebook',
      // gameCode: 'HRB-LIVE-001',
      userId,
      // platform: 'HORSEBOOK',
      platformTxId: `WIN/PLC${foBetRule != null ? '/FO' : ''}-${txnId}`,
      updateTime,
      roundId: `${roundId}`,
      voidType,
    }))

    const message = {
      action: 'unvoidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getUnsettleMessage(txnList) {
    const { userIdPrefix, currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType = 'WIN',
      betAmount,
      updateTime,
      odds,
      runnerNo = '1',
      foBetRule,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'Horsebook',
      // gameCode: 'HRB-LIVE-001',
      userId,
      // platform: 'HORSEBOOK',
      platformTxId: `WIN/PLC${foBetRule != null ? '/FO' : ''}-${txnId}`,
      updateTime,
      roundId: `${roundId}`,
      betAmount,
      gameInfo: {
        ...this.constantGameInfo,
        runnerNo,
        raceId: `${roundId}`,
        // locationName: 'AU(Har)',
        // locationNameCn: '澳洲(马车)',
        // countryNameCn: '澳洲51',
        ...(foBetRule != null && {
          foBetRule,
          fo: true,
          odds,
        }),
        gameMethod: `WIN_PLC${foBetRule != null ? '_FO' : ''}`,
        userId: `${userIdPrefix}${userId}`,
        stake: betAmount,
        // runnerName: '---',
        // locationId: 51,
        // countryCode: 'AU',
        categoryTypeID: this.categoryTypeIDList[betType],
        currency,
        // countryName: 'AU(Har)51',
        // runnerType: 'Harness',
        currencyID: this.currencyIDList[currency],
        // raceNo: 9,
        txnId,
      },
    }))

    const message = {
      action: 'unsettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
      foBetRule,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'Horsebook',
      // gameCode: 'HRB-LIVE-001',
      userId,
      // platform: 'HORSEBOOK',
      platformTxId: `WIN/PLC${foBetRule != null ? '/FO' : ''}-${txnId}`,
      roundId: `${roundId}`,
      updateTime,
      voidType,
      betAmount,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const { userIdPrefix, currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType = 'WIN',
      betAmount,
      odds,
      runnerNo = '1',
      foBetRule,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      // gameCode: 'HRB-LIVE-001',
      userId,
      // platform: 'HORSEBOOK',
      platformTxId: `WIN/PLC${foBetRule != null ? '/FO' : ''}-${txnId}`,
      roundId: `${roundId}`,
      gameInfo: {
        ...this.constantTxnData,
        runnerNo,
        raceId: `${roundId}`,
        // locationName: 'AU(Har)',
        // locationNameCn: '澳洲(马车)',
        // countryNameCn: '澳洲51',
        ...(foBetRule != null && {
          foBetRule,
          fo: true,
          odds,
        }),
        gameMethod: `WIN_PLC${foBetRule != null ? '_FO' : ''}`,
        userId: `${userIdPrefix}${userId}`,
        stake: betAmount,
        // runnerName: '---',
        // locationId: 51,
        // countryCode: 'AU',
        categoryTypeID: this.categoryTypeIDList[betType],
        currency,
        // countryName: 'AU(Har)51',
        // runnerType: 'Harness',
        currencyID: this.currencyIDList[currency],
        // raceNo: 9,
        txnId,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
