import PlatformActions from './PlatformActions'

export default class PTSLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'PT-SLOT-006',
      platform: 'PT',
    }

    this.gameName = 'Great Blue'

    this.constantGameInfo = {
      roundType: 'normal',
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 3164290470
      roundId: txnId, // 3164290470
      betType: 'bet',
      currency,
      betTime, // 2022-07-25T11:52:03.000+08:00
      betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      refPlatformTxId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: winAmount ? `pt${roundId}` : `pt${txnId}win`, // WIN: pt5322306865, LOSE: pt3164290470win
      refPlatformTxId,
      settleType: 'refPlatformTxId',
      updateTime, // 2022-07-25T11:52:05.700+08:00
      roundId: txnId, // 3164290470
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        jpw: 0,
        detailUrl: 'https://fake.extstg3-cashier01.ptstaging.eu/getgamehistoryurl.php',
        ...this.constantGameInfo,
        jpc: 0,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId: txnId,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
