import PlatformActions from './PlatformActions'

export default class SV388Actions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'LIVE',
      // gameName: 'CockFight', // cancelBet do NOT have this field
      gameCode: 'SV-LIVE-001',
      platform: 'SV388',
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType = 'WALA',
      betTime,
      betAmount,
      odds,
      realBetAmount,
      eventDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'CockFight',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      roundId: `${roundId}`, // 1095855
      betType, // 3 bettypes: WALA, MERON, BDD
      currency,
      betTime, // 2021-09-29T00:21:40.398+08:00
      betAmount,
      gameInfo: {
        arenaCode: '_GO1',
        odds: `${odds}`,
        matchno: 1,
        location: 'PHI',
        realBetAmount, // -4350.0 (bet * -odds)
        live: 0,
        matchId: `${roundId}`,
        eventDate, // 2021-09-27T16:00:00.000+0000
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getAdjustBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      adjustAmount,
      betAmount,
      odds,
      realBetAmount,
      eventDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'CockFight',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      roundId: `${roundId}`, // 1095855
      updateTime, // 2021-09-29T00:21:40.570+08:00
      adjustAmount, // -odds: betAmount * (1 - abs(odds))  +odds: 0
      betAmount, // betAmount * abs(odds)
      gameInfo: {
        arenaCode: '_GO1',
        odds: `${odds}`,
        matchno: '1',
        location: 'PHI',
        realBetAmount: `${realBetAmount}`, // -4350.0 (bet * -odds)
        live: '0',
        matchId: `${roundId}`,
        eventDate, // 2021-09-27T16:00:00.000+0000
      },
    }))

    const message = {
      action: 'adjustBet',
      txns,
    }
    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      betType = 'WALA',
      betTime,
      turnover,
      betAmount,
      winAmount,
      result,
      matchResult,
      odds,
      validBet1v1,
      status,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'CockFight',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2021-09-28T13:35:21.676+08:00
      roundId: `${roundId}`, // 1095855
      betType, // super(bet)
      betTime, // 2021-09-28T13:35:02.130+08:00
      txTime: betTime, // 2021-09-28T13:35:02.130+08:00
      turnover,
      betAmount, // betAmount * abs(odds)
      winAmount,
      gameInfo: {
        result, // lose:-1 win:1 DRAW:0
        arenaCode: '_GO1',
        settled: 1,
        matchResult, // WALA, MERON, BDD, FTD
        odds: `${odds}`,
        ip: '192.168.0.1',
        matchno: 1,
        oddsMode: 0,
        arena: 'Agoo Cockpit Arena',
        validBet1v1, // betAmount.toFixed(1) 2000.0
        bddType: 0,
        status, // WIN, LOSE, DRAW
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  // [ - ]
  // bet
  // betAmount = formData
  // realBetAmount = betAmount * odds

  // adjustBet
  // adjustAmount = betAmount * (1 + odds)
  // betAmount = betAmount * Math.abs(odds)
  // realBetAmount = betAmount * odds

  // settle
  // turnover(win) = betAmount * 1
  // turnover(lose) = betAmount * Math.abs(odds)
  // turnover(draw) = betAmount * 0
  // betAmount(win/lose/draw) = betAmount * Math.abs(odds)
  // winAmount(win) = betAmount * (1 + Math.abs(odds))
  // winAmount(lose) = betAmount * 0
  // winAmount(draw) = betAmount * Math.abs(odds)
  // validBet1v1(win/lose) = betAmount * 1
  // validBet1v1(draw) = betAmount * 0

  // [ + ]
  // bet
  // betAmount = formData
  // realBetAmount = betAmount * -1

  // adjustBet(if odds change)
  // adjustAmount = betAmount * 0
  // betAmount = betAmount * 1
  // realBetAmount = betAmount * -1

  // settle
  // turnover(win/lose) = betAmount * 1
  // turnover(draw) = betAmount * 0
  // betAmount(win/lose/draw) = betAmount * 1
  // winAmount(win) = betAmount * (1 + odds )
  // winAmount(lose) = betAmount * 0
  // winAmount(draw) = betAmount * 1
  // validBet1v1(win/lose) = betAmount * 1
  // validBet1v1(draw) = betAmount * 0

  getUnsettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      betAmount,
      result,
      matchResult,
      odds,
      validBet1v1,
      status,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'CockFight',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      updateTime, // 2021-09-20T13:56:03.884+08:00
      roundId: `${roundId}`, // 1095855
      betAmount,
      gameInfo: {
        result,
        arenaCode: '_GO1',
        settled: 1,
        matchResult,
        odds: `${odds}`,
        ip: '192.168.0.1',
        matchno: 1,
        oddsMode: 0,
        arena: 'Agoo Cockpit Arena',
        validBet1v1, // betAmount.toFixed(1) 2000.0
        bddType: 0,
        status,
      },
    }))

    const message = {
      action: 'unsettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      betAmount,
      odds,
      realBetAmount,
      eventDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'CockFight',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      updateTime, // ex format('YYYY-MM-DDTHH:mm:ss.SSS+08:00')
      roundId: `${roundId}`, // 1095855
      betAmount, // betAmount * abs(odds)
      voidType: 2,
      gameInfo: {
        arenaCode: '_GO1',
        odds: `${odds}`,
        matchno: '1',
        location: 'PHI',
        realBetAmount: `${realBetAmount}`, // -4350.0 (bet * -odds)
        live: '0',
        matchId: `${roundId}`,
        eventDate, // 2021-09-27T16:00:00.000+0000
        voidType: 2,
        realWinAmt: 0,
      },
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getUnvoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'CockFight',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      roundId: `${roundId}`, // 1095855
      updateTime, // ex format('YYYY-MM-DDTHH:mm:ss.SSS+08:00')
      voidType: 2,
    }))

    const message = {
      action: 'unvoidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'CockFight',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      roundId: `${roundId}`, // 1095855
      updateTime, // ex format('YYYY-MM-DDTHH:mm:ss.SSS+08:00')
      voidType: 9,
      betAmount, // betAmount * abs(odds)
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getUnvoidSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'CockFight',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      roundId: `${roundId}`, // 1095855
      updateTime, // ex format('YYYY-MM-DDTHH:mm:ss.SSS+08:00')
      voidType: 9,
    }))

    const message = {
      action: 'unvoidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      odds,
      realBetAmount,
      eventDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      roundId: `${roundId}`,
      gameInfo: {
        arenaCode: '_GO1',
        odds: `${odds}`,
        matchno: 1,
        location: 'PHI',
        realBetAmount, // -4350.0 (bet * -odds)
        live: 0,
        matchId: `${roundId}`,
        eventDate, // 2021-09-27T16:00:00.000+0000
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getRefundMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      refundPlatformTxId,
      betType = 'WALA',
      betTime,
      turnover,
      betAmount,
      winAmount,
      odds,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'LIVE',
      gameName: 'CockFight',
      // gameCode: 'SV-LIVE-001',
      userId,
      // platform: 'SV388',
      platformTxId: `TXN${txnId}`, // TXN939902256
      updateTime, // 2021-09-28T13:35:21.676+08:00
      roundId: `${roundId}`, // 1095855
      refundPlatformTxId: `TXN${refundPlatformTxId}`, // the txnid of last losebet
      betType,
      betTime, // 2021-09-28T13:35:02.130+08:00
      turnover,
      betAmount, // formdata
      winAmount,
      gameInfo: {
        result: 1,
        arenaCode: '_RFD',
        settled: 1,
        matchResult: 'WALA',
        odds: `${odds}`,
        matchno: 1,
        location: 'PHI',
        refundId: `TXN${refundPlatformTxId}`,
        live: 0,
        oddsMode: 0,
        arena: 'Refund',
        bddType: 0,
        status: 'WIN',
      },
    }))

    const message = {
      action: 'refund',
      txns,
    }

    return JSON.stringify(message)
  }
}
