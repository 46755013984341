import EVOLUTIONActions from './EVOLUTIONActions'

export default class NETENTSLOTActions extends EVOLUTIONActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'NETENT-SLOT-002',
      platform: 'NETENT',
    }

    this.gameName = 'Dracula'
  }
}
