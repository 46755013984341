import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class KINGMAKERSLOTTestScenarios extends PlatformTestScenarios {
  async bet_settleWin_settleWin_settleClose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.settleClose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin5Times_settleClose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.settleWin({ timesLoop })
        }
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.settleClose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_asyncSettleWin5Times_settleClose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        await Promise.all(Array.from({ length: 5 }).map(async (_, timesLoop) => {
          await this.requests.settleWin({
            timesLoop,
            options: { isAsync: true },
          })
          return true
        }))
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.settleClose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin_settleClose5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.settleClose({ timesLoop })
        }
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }
}
