import Validations from './Validations'
import AssertionError from './AssertionError'

export default class PlatformTests {
  constructor(formData) {
    this.validations = new Validations(formData)

    this.testsInRequest = []
    this.lastBalance = []
    this.lastBalanceTs = []
  }

  testsPromise() {
    const deleteAssertionResultsInTests = () => this.testsInRequest.forEach(test => delete test.result)

    return new Promise((resolve, reject) => {
      const failedTests = this.testsInRequest.filter(({ passed }) => !passed)
      if (!failedTests.length) {
        deleteAssertionResultsInTests()
        resolve()
      } else {
        const assertionErrors = failedTests.map(({ result }) => new AssertionError(result))
        deleteAssertionResultsInTests()
        reject(assertionErrors)
      }
    })
  }

  pushTest(requestNo, result) {
    const { message, passed, warning } = result
    const testIdx = this.testsInRequest.length + 1

    this.testsInRequest.push({
      name: `${requestNo}-${testIdx}. ${message}`,
      passed,
      warning,
      result, // Used for throwing AssertionError
    })
  }

  emptyTests() {
    this.testsInRequest = []
  }

  updateLastBalance(balance, index) {
    const idx = index != null ? index : 0
    this.lastBalance[idx] = Number(balance)
    // console.log('updateLastBalance', this.lastBalance)
  }

  updateLastBalanceTs(balanceTs, index) {
    const idx = index != null ? index : 0
    this.lastBalanceTs[idx] = balanceTs
    // console.log('updateLastBalanceTs', this.lastBalanceTs)
  }

  updateValidations(res, playerIndex) {
    this.validations.res = res

    if (playerIndex != null) {
      this.validations.lastBalance = this.lastBalance[playerIndex]
      this.validations.lastBalanceTs = this.lastBalanceTs[playerIndex]
    }
  }

  testsForStatus({ requestNo, isInsufficientBalance }) {
    if (!isInsufficientBalance) {
      this.pushTest(requestNo, this.validations.status())
    } else {
      this.pushTest(requestNo, this.validations.statusForInsufficientBalance())
    }

    this.pushTest(requestNo, this.validations.statusString())
  }

  testsForBalance({
    requestNo,
    testData,
    options = {},
    ...arg
  }) {
    if (options.validateZeroBalance) {
      this.pushTest(requestNo, this.validations.zeroBalance())
    } else if (options.validateNegativeBalance) {
      this.pushTest(requestNo, this.validations.negativeBalance())
    } else if (options.validatePositiveBalance) {
      this.pushTest(requestNo, this.validations.positiveBalance())
    } else if (!options.validateInsufficientBalance && !options.betForNegativeBalance) {
      this.pushTest(requestNo, this.validations.notNegativeBalance())
    }

    const actionResponseWithBalance = [
      'bet',
      'betAfterCancelBet',
      'cancelBet',
      'betNSettle',
      'betNSettleAfterCancelBetNSettle',
      'cancelBetNSettle',
      'tip',
      'cancelTip',
      'tipAfterCancelTip',
      'adjustAmt',
      'cancelAdjustAmt',
      'adjustAmtAfterCancelAdjustAmt',
      'adjustBet',
    ]

    const { action } = arg
    if (action !== 'firstGetBalance') {
      if (actionResponseWithBalance.includes(action)) {
        this.pushTest(requestNo, this.validations.balanceFromAction(action, testData))
      } else {
        this.pushTest(requestNo, this.validations.balanceFromGetBalanceAfterAction(action, testData))
      }
    }

    this.pushTest(requestNo, this.validations.balanceFormat())
    // this.pushTest(requestNo, this.validations.balanceNumber())
  }

  testsForBalanceTs({ requestNo, action }) {
    this.pushTest(requestNo, this.validations.balanceTs())
    this.pushTest(requestNo, this.validations.balanceTsFormat())
    if (action !== 'firstGetBalance') this.pushTest(requestNo, this.validations.balanceTsSequence())
  }

  testsForActionWithoutBalance({ requestNo, action, options }) {
    this.pushTest(requestNo, this.validations.responseTime(action))
    if (!options?.isAsync) this.testsForStatus({ requestNo })

    return this.testsPromise()
  }

  testsForActionWithBalance({
    requestNo,
    action,
    testData,
    options,
  }) {
    const actionResponseWithBalance = [
      'bet',
      'cancelBet',
      'betNSettle',
      'cancelBetNSettle',
      'tip',
      'cancelTip',
      'adjustBet',
    ]

    if (actionResponseWithBalance.includes(action)) {
      this.pushTest(requestNo, this.validations.responseTime(action))
    } else {
      this.pushTest(requestNo, this.validations.responseTime('getBalance'))
    }

    if (!options?.isAsync) { // Async Settle / BetNSettle
      this.testsForStatus({ requestNo })
      this.testsForBalance({
        requestNo,
        action,
        testData,
        options,
      })
      this.testsForBalanceTs({ requestNo, action })
    }

    return this.testsPromise()
  }

  getBalance({
    requestNo,
    res,
    playerIndex = 0,
    options,
  }) {
    this.updateValidations(res)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    return this.testsForActionWithBalance({
      action: 'firstGetBalance',
      requestNo,
      options,
    })
  }

  bet({
    requestNo,
    res,
    playerIndex = 0,
    betIndex = 0,
    options = {},
  }) {
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data

    if (options.validateInsufficientBalance) {
      this.pushTest(requestNo, this.validations.responseTime('bet'))
      this.testsForStatus({ requestNo, isInsufficientBalance: true })
    } else if (options.afterCancelBet) {
      this.pushTest(requestNo, this.validations.responseTime('bet'))
      if (balance) {
        this.testsForBalance({ requestNo, action: 'betAfterCancelBet' })
        this.updateLastBalance(balance, playerIndex)
      }
      if (balanceTs) {
        this.testsForBalanceTs({ requestNo, action: 'betAfterCancelBet' })
        this.updateLastBalanceTs(balanceTs, playerIndex)
      }
    } else {
      const totalBetAmount = Number(this.betData[playerIndex][betIndex].reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))

      this.updateLastBalance(balance, playerIndex)
      this.updateLastBalanceTs(balanceTs, playerIndex)

      return this.testsForActionWithBalance({
        action: 'bet',
        requestNo,
        testData: {
          betAmount: totalBetAmount,
        },
        options,
      })
    }

    return this.testsPromise()
  }

  cancelBet({
    requestNo,
    res,
    betIndexes,
    timesLoop,
    options = {},
  }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance)
    this.updateLastBalanceTs(balanceTs)

    const betData = betIndexes ? this.betData[playerIndex].filter((_, betIdx) => betIndexes.includes(betIdx)) : this.betData[playerIndex]
    const totalBetAmount = Number(betData.flat().reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'cancelBet',
      requestNo,
      ...(!timesLoop && !options.beforeBet ? {
        testData: {
          betAmount: totalBetAmount,
        },
      } : {}),
    })
  }

  getBalanceAfterBetAfterCancelBet({ requestNo, res }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    return this.testsForActionWithBalance({
      action: 'betAfterCancelBet',
      requestNo,
    })
  }

  adjustBet({ requestNo, res, playerIndex }) {
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalanceTs(balanceTs, playerIndex)
    this.updateLastBalance(balance, playerIndex)

    const adjustBetData = this.adjustBetData[playerIndex].flat()
    const totalAdjustAmount = Number(adjustBetData.reduce((acc, { adjustAmount }) => acc + adjustAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'adjustBet',
      requestNo,
      testData: {
        adjustAmount: totalAdjustAmount,
      },
    })
  }

  settle({ requestNo, res, options }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'settle', options })
  }

  getBalanceAfterSettle({
    requestNo,
    res,
    playerIndex,
    options,
  }) {
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const settleData = this.settleData[playerIndex].flat()
    const totalWinAmount = Number(settleData.reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))
    const totalRebate = Number(settleData.reduce((acc, { rebate }) => acc + rebate, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'settle',
      requestNo,
      testData: {
        winAmount: totalWinAmount,
        ...(!Number.isNaN(totalRebate) && { rebate: totalRebate }),
      },
      options,
    })
  }

  getBalanceAfterAsyncSettle({ requestNo, res, options }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data

    const settleData = this.settleData[playerIndex].flat()
    const totalWinAmount = Number(settleData.reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))
    const totalRebate = Number(settleData.reduce((acc, { rebate }) => acc + rebate, 0).toFixed(4))

    const testData = {
      winAmount: totalWinAmount,
      ...(!Number.isNaN(totalRebate) && { rebate: totalRebate }),
    }

    this.pushTest(requestNo, this.validations.statusForPreviousAsyncSettle())

    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    return this.testsForActionWithBalance({
      action: 'asyncSettle',
      requestNo,
      testData,
      options,
    })
  }

  voidBet({ requestNo, res }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'voidBet' })
  }

  getBalanceAfterVoidBet({
    requestNo,
    res,
    playerIndex,
    options,
  }) {
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const voidBetData = this.voidBetData[playerIndex].flat()
    const totalBetAmount = Number(voidBetData.reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'voidBet',
      requestNo,
      testData: {
        betAmount: totalBetAmount,
      },
      options,
    })
  }

  unvoidBet({ requestNo, res }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'unvoidBet' })
  }

  getBalanceAfterUnvoidBet({ requestNo, res, options }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const unvoidBetData = this.unvoidBetData[playerIndex].flat()
    const totalBetAmount = Number(unvoidBetData.reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'unvoidBet',
      requestNo,
      testData: {
        betAmount: totalBetAmount,
      },
      options,
    })
  }

  unsettle({ requestNo, res }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'unsettle' })
  }

  getBalanceAfterUnsettle({
    requestNo,
    res,
    playerIndex,
    options,
  }) {
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const settleData = this.settleData[playerIndex].flat()
    const unsettleData = this.unsettleData[playerIndex].flat()
    const totalWinAmount = Number(settleData.filter(({ txnId }) => unsettleData.some(d => d.txnId === txnId)).reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'unsettle',
      requestNo,
      testData: {
        winAmount: totalWinAmount,
      },
      options,
    })
  }

  resettle({ requestNo, res }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'resettle' })
  }

  getBalanceAfterResettle({
    requestNo,
    res,
    playerIndex,
    options,
  }) {
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const resettleData = this.resettleData[playerIndex].flat()
    const settleData = this.settleData[playerIndex].flat()

    const totalOldWinAmount = Number(settleData.reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))
    const totalOldRebate = Number(settleData.reduce((acc, { rebate }) => acc + rebate, 0).toFixed(4))

    const totalWinAmount = Number(resettleData.reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))
    const totalRebate = Number(resettleData.reduce((acc, { rebate }) => acc + rebate, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'resettle',
      requestNo,
      testData: {
        oldWinAmount: totalOldWinAmount,
        winAmount: totalWinAmount,
        ...(!Number.isNaN(totalRebate) && {
          oldRebate: totalOldRebate,
          rebate: totalRebate,
        }),
      },
      options,
    })
  }

  voidSettle({ requestNo, res }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'voidSettle' })
  }

  getBalanceAfterVoidSettle({
    requestNo,
    res,
    options,
    playerIndex,
  }) {
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const settleData = this.settleData[playerIndex].flat()
    const voidSettleData = this.voidSettleData[playerIndex].flat()

    const totalBetAmount = Number(voidSettleData.reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))
    const totalWinAmount = Number(settleData.filter(({ txnId }) => voidSettleData.some(d => d.txnId === txnId)).reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))
    const totalOldRebate = Number(settleData.reduce((acc, { rebate }) => acc + rebate, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'voidSettle',
      requestNo,
      testData: {
        betAmount: totalBetAmount,
        winAmount: totalWinAmount,
        ...(!Number.isNaN(totalOldRebate) && {
          oldRebate: totalOldRebate,
        }),
      },
      options,
    })
  }

  unvoidSettle({ requestNo, res }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'unvoidSettle' })
  }

  getBalanceAfterUnvoidSettle({ requestNo, res, options }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const settleData = this.settleData[playerIndex].flat()
    const unvoidSettleData = this.unvoidSettleData[playerIndex].flat()

    const totalBetAmount = Number(unvoidSettleData.reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))
    const totalWinAmount = Number(settleData.filter(({ txnId }) => unvoidSettleData.some(d => d.txnId === txnId)).reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'unvoidSettle',
      requestNo,
      testData: {
        betAmount: totalBetAmount,
        winAmount: totalWinAmount,
      },
      options,
    })
  }

  refund({ requestNo, res }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'refund' })
  }

  getBalanceAfterRefund({ requestNo, res, playerIndex }) {
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const refundData = this.refundData[playerIndex].flat()
    const totalBetAmount = Number(refundData.reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))
    const totalWinAmount = Number(refundData.reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'refund',
      requestNo,
      testData: {
        betAmount: totalBetAmount,
        winAmount: totalWinAmount,
      },
    })
  }

  give({ requestNo, res }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'give' })
  }

  getBalanceAfterGive({ requestNo, res }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const giveData = this.giveData[playerIndex].flat()
    const totalGiveAmount = Number(giveData.reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'give',
      requestNo,
      testData: {
        giveAmount: totalGiveAmount,
      },
    })
  }

  freeSpin({ requestNo, res }) {
    this.updateValidations(res)
    return this.testsForActionWithoutBalance({ requestNo, action: 'freeSpin' })
  }

  getBalanceAfterFreeSpin({ requestNo, res }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const freeSpinData = this.freeSpinData[playerIndex].flat()
    const totalWinAmount = Number(freeSpinData.reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'freeSpin',
      requestNo,
      testData: {
        winAmount: totalWinAmount,
      },
    })
  }

  tip({ requestNo, res, options = {} }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data

    if (options.validateInsufficientBalance) {
      this.pushTest(requestNo, this.validations.responseTime('tip'))
      this.testsForStatus({ requestNo, isInsufficientBalance: true })
    } else if (options.afterCancelTip) {
      this.pushTest(requestNo, this.validations.responseTime('tip'))
      if (balance) {
        this.testsForBalance({ requestNo, action: 'tipAfterCancelTip' })
        this.updateLastBalance(balance, playerIndex)
      }
      if (balanceTs) {
        this.testsForBalanceTs({ requestNo, action: 'tipAfterCancelTip' })
        this.updateLastBalanceTs(balanceTs)
      }
    } else {
      const totalTip = Number(this.tipData[playerIndex][0].reduce((acc, { tip }) => acc + tip, 0).toFixed(4))

      this.updateLastBalance(balance, playerIndex)
      this.updateLastBalanceTs(balanceTs, playerIndex)

      return this.testsForActionWithBalance({
        action: 'tip',
        requestNo,
        testData: {
          tip: totalTip,
        },
        options,
      })
    }

    return this.testsPromise()
  }

  cancelTip({
    requestNo,
    res,
    timesLoop,
    options = {},
  }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const tipIndex = 0
    const totalTip = Number(this.tipData[playerIndex][tipIndex].reduce((acc, { tip }) => acc + tip, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'cancelTip',
      requestNo,
      ...(!timesLoop && !options.beforeTip ? {
        testData: {
          tip: totalTip,
        },
      } : {}),
    })
  }

  getBalanceAfterTipAfterCancelTip({ requestNo, res }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    return this.testsForActionWithBalance({
      action: 'tipAfterCancelTip',
      requestNo,
    })
  }

  betNSettle({
    requestNo,
    res,
    playerIndex = 0,
    betIndex = 0,
    options = {},
    timesLoop,
  }) {
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data

    if (options.validateInsufficientBalance) {
      this.pushTest(requestNo, this.validations.responseTime('betNSettle'))
      this.testsForStatus({ requestNo, isInsufficientBalance: true })
    } else if (options.afterCancelBetNSettle) {
      this.pushTest(requestNo, this.validations.responseTime('betNSettle'))
      if (balance) {
        this.testsForBalance({ requestNo, action: 'betNSettleAfterCancelBetNSettle' })
        this.updateLastBalance(balance, playerIndex)
      }
      if (balanceTs) {
        this.testsForBalanceTs({ requestNo, action: 'betNSettleAfterCancelBetNSettle' })
        this.updateLastBalanceTs(balanceTs)
      }
    } else {
      let testData
      if (!timesLoop && !options?.isAsync) {
        this.updateLastBalance(balance, playerIndex)
        this.updateLastBalanceTs(balanceTs, playerIndex)

        const betNSettleData = this.betNSettleData[playerIndex][betIndex]

        testData = {
          betAmount: Number(betNSettleData.reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4)),
          winAmount: Number(betNSettleData.reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4)),
        }
      }

      return this.testsForActionWithBalance({
        action: 'betNSettle',
        requestNo,
        testData,
        options,
      })
    }

    return this.testsPromise()
  }

  cancelBetNSettle({
    requestNo,
    res,
    timesLoop,
    options = {},
  }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const cancelBetNSettleData = this.cancelBetNSettleData.flat()

    const totalBetAmount = Number(cancelBetNSettleData.reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))
    const totalWinAmount = Number(cancelBetNSettleData.reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'cancelBetNSettle',
      requestNo,
      ...(!timesLoop && !options.beforeBetNSettle && {
        testData: {
          betAmount: totalBetAmount,
          winAmount: totalWinAmount,
        },
      }),
      options,
    })
  }

  getBalanceAfterBetNSettle({ requestNo, res, options }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    return this.testsForActionWithBalance({
      action: 'betNSettle',
      requestNo,
      options,
    })
  }

  getBalanceAfterAsyncBetNSettle({ requestNo, res, options }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data

    const betNSettleData = this.betNSettleData[playerIndex].flat()
    const totalBetAmount = Number(betNSettleData.reduce((acc, { betAmount }) => acc + betAmount, 0).toFixed(4))
    const totalWinAmount = Number(betNSettleData.reduce((acc, { winAmount }) => acc + winAmount, 0).toFixed(4))

    const testData = {
      betAmount: totalBetAmount,
      winAmount: totalWinAmount,
    }

    this.pushTest(requestNo, this.validations.statusForPreviousAsyncSettle())

    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    return this.testsForActionWithBalance({
      action: 'asyncBetNSettle',
      requestNo,
      testData,
      options,
    })
  }

  getBalanceAfterCancelBetNSettle({ requestNo, res, options }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    return this.testsForActionWithBalance({
      action: 'cancelBetNSettle',
      requestNo,
      options,
    })
  }

  getBalanceAfterBetNSettleAfterCancelBetNSettle({ requestNo, res }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    return this.testsForActionWithBalance({
      action: 'betNSettleAfterCancelBetNSettle',
      requestNo,
    })
  }

  adjustAmt({
    requestNo,
    res,
    betIndex = 0,
    options = {},
  }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data

    if (options.validateInsufficientBalance) {
      this.pushTest(requestNo, this.validations.responseTime('adjustAmt'))
      this.testsForStatus({ requestNo, isInsufficientBalance: true })
    } else if (options.afterCancelAdjustAmt) {
      this.pushTest(requestNo, this.validations.responseTime('adjustAmt'))
      if (balance) {
        this.testsForBalance({ requestNo, action: 'adjustAmtAfterCancelAdjustAmt' })
        this.updateLastBalance(balance, playerIndex)
      }
      if (balanceTs) {
        this.testsForBalanceTs({ requestNo, action: 'adjustAmtAfterCancelAdjustAmt' })
        this.updateLastBalanceTs(balanceTs)
      }
    } else {
      this.updateLastBalance(balance, playerIndex)
      this.updateLastBalanceTs(balanceTs, playerIndex)

      return this.testsForActionWithBalance({
        action: 'adjustAmt',
        requestNo,
        testData: {
          adjustAmount: this.adjustAmtData[playerIndex][betIndex][0].adjustAmount,
        },
        options,
      })
    }

    return this.testsPromise()
  }

  cancelAdjustAmt({
    requestNo,
    res,
    betIndexes,
    timesLoop,
    options = {},
  }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    const adjustAmtData = betIndexes ? this.adjustAmtData[playerIndex].filter((_, betIdx) => betIndexes.includes(betIdx)) : this.adjustAmtData[playerIndex]
    const totalAdjustAmt = Number(adjustAmtData.flat().reduce((acc, { adjustAmount }) => acc + adjustAmount, 0).toFixed(4))

    return this.testsForActionWithBalance({
      action: 'cancelAdjustAmt',
      requestNo,
      ...(!timesLoop && !options.beforeAdjustAmt ? {
        testData: {
          adjustAmount: totalAdjustAmt,
        },
      } : {}),
    })
  }

  getBalanceAfterAdjustAmtAfterCancelAdjustAmt({ requestNo, res }) {
    const playerIndex = 0
    this.updateValidations(res, playerIndex)

    const { balance, balanceTs } = res.data
    this.updateLastBalance(balance, playerIndex)
    this.updateLastBalanceTs(balanceTs, playerIndex)

    return this.testsForActionWithBalance({
      action: 'adjustAmtAfterCancelAdjustAmt',
      requestNo,
    })
  }
}
