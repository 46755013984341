import moment from 'moment'
import PlatformActions from './PlatformActions'

export default class E1SPORTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'ESPORTS',
      // gameName: 'eSports', // cancelBet do NOT have this field
      gameCode: 'E1-ESPORTS-001',
      platform: 'E1SPORT',
    }

    this.getConstantGameInfo = (gameNo = 0) => ({
      opponentName: 'Entropiq_vs_Akuma', // 隊伍名稱
      betInfo: gameNo ? `Game_${gameNo}_Akuma_Winner` : 'Main_Game_Akuma_Winner', // 投注項明細
      betOpponentName: 'Akuma', // 投注隊伍名稱
      betOpponent: '749980', // 投注隊伍 Id
      leagueType: 'CSGO',
      marketId: `${3935396 + gameNo}`, // 盤口 Id
      selectionKey: 'a',
      oddsAcceptOption: '2', // 當賠率變動時還是否要下注，0: 原下注賠率才收單、1: 優於原下注賠率才收單、2: 任何賠率都收單
      isQuickBet: '0', // 快速下注
    })
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      // betType,
      betAmount,
      odds,
      betTime,
      oddsMode = 1,
      marketType = '2',
      gameNo,
      tournamentDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'ESPORTS',
      gameName: 'eSports',
      // gameCode: 'E1-ESPORTS-001',
      userId,
      // platform: 'E1SPORT',
      platformTxId: `${txnId}`, // 313812
      roundId: `${roundId}`, // 46587500
      betType: gameNo ? '9001' : '20',
      currency,
      betAmount,
      betTime, // 2021-09-28T13:36:38.825+08:00
      gameInfo: {
        ...this.getConstantGameInfo(gameNo),
        // betOpponent: '749980',
        // leagueType: 'CSGO',
        // betOpponentName: 'Akuma',
        oddsMode,
        // isQuickBet: 0,
        // oddsAcceptOption: 2,
        marketType, // 盤口下注類型，1: 滾球 (live)、2: 早盤 (preMatch)
        // marketId: '3935396',
        tournamentDate,
        odds,
        // opponentName: 'Entropiq_vs_Akuma',
        // selectionKey: 'a',
        // betInfo: 'Main_Game_Akuma_Winner',
        status: 'SUCCESS', // 交易單狀態
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getAdjustBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      adjustAmount,
      odds,
      updateTime,
      oddsMode = 1,
      marketType = '2',
      gameNo,
      tournamentDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'ESPORTS',
      gameName: 'eSports',
      // gameCode: 'E1-ESPORTS-001',
      userId,
      // platform: 'E1SPORT',
      platformTxId: `${txnId}`, // 313812
      roundId: `${roundId}`, // 46587500
      betAmount,
      adjustAmount, // -odds: betAmount * (1 - abs(odds)) +odds: 0
      updateTime, // 2021-09-26T21:58:00.049+08:00
      gameInfo: {
        ...this.getConstantGameInfo(gameNo),
        // betOpponent: '749980',
        // leagueType: 'CSGO',
        // betOpponentName: 'Akuma',
        oddsMode,
        // isQuickBet: 0,
        // oddsAcceptOption: 2,
        marketType,
        // marketId: '3935396',
        tournamentDate,
        odds: `${odds}`,
        // opponentName: 'Entropiq_vs_Akuma',
        // selectionKey: 'a',
        // betInfo: 'Main_Game_Akuma_Winner',
        status: 'SUCCESS',
      },
    }))

    const message = {
      action: 'adjustBet',
      txns,
    }
    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      // betType,
      betAmount,
      winAmount,
      turnover,
      odds,
      betTime,
      updateTime,
      oddsMode = 1,
      marketType = '2',
      txnResult,
      gameNo,
      tournamentDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'ESPORTS',
      gameName: 'eSports',
      // gameCode: 'E1-ESPORTS-001',
      userId,
      // platform: 'E1SPORT',
      platformTxId: `${txnId}`, // 313812
      refPlatformTxId: null,
      settleType: 'platformTxId',
      roundId: `${roundId}`, // 46587500
      betType: gameNo ? '9001' : '20',
      turnover,
      betAmount,
      winAmount,
      betTime, // 2021-09-26T21:57:59.078+08:00
      txTime: betTime, // 2021-09-26T22:16:16.000+08:00
      updateTime, // 2021-09-28T13:35:21.676+08:00
      gameInfo: {
        ...this.getConstantGameInfo(gameNo),
        // betOpponent: '749980',
        // leagueType: 'CSGO',
        // betOpponentName: 'Akuma',
        oddsMode,
        // isQuickBet: 0,
        // oddsAcceptOption: 2,
        marketType,
        // marketId: '3935396',
        tournamentDate,
        odds: `${odds}`,
        // opponentName: 'Entropiq_vs_Akuma',
        // selectionKey: 'a',
        // betInfo: 'Main_Game_Akuma_Winner',
        status: 'SETTLED',
        txnResult,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  // settlewin
  // winAmount: betAmount * (1 + abs(odds))
  // turnover: betAmount * 1
  // txnResult: WON

  // settlelose(-)
  // result: -1
  // winAmount: betAmount * 0
  // turnover: betAmount * abs(odds)
  // txnResult: LOSE

  getUnsettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      odds,
      updateTime,
      oddsMode = 1,
      marketType = '2',
      txnResult,
      gameNo,
      tournamentDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'ESPORTS',
      gameName: 'eSports',
      // gameCode: 'E1-ESPORTS-001',
      userId,
      // platform: 'E1SPORT',
      platformTxId: `${txnId}`, // 313812
      roundId: `${roundId}`, // 46587500
      betAmount, // betAmount * abs(odds)
      updateTime, // 2021-09-28T13:35:21.676+08:00
      gameInfo: {
        ...this.getConstantGameInfo(gameNo),
        // betOpponent: '749980',
        // leagueType: 'CSGO',
        // betOpponentName: 'Akuma',
        oddsMode,
        // isQuickBet: 0,
        // oddsAcceptOption: 2,
        marketType,
        // marketId: '3935396',
        tournamentDate,
        odds: `${odds}`,
        // opponentName: 'Entropiq_vs_Akuma',
        // selectionKey: 'a',
        // betInfo: 'Main_Game_Akuma_Winner',
        status: 'SETTLED',
        txnResult,
        resettleTime: moment(updateTime).subtract(5, 'seconds').format('DD-MM-YYYY HH:mm:ss'),
      },
    }))

    const message = {
      action: 'unsettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      // betType,
      betAmount,
      winAmount,
      turnover,
      odds,
      betTime,
      updateTime,
      oddsMode = 1,
      marketType = '2',
      txnResult,
      gameNo,
      tournamentDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'ESPORTS',
      gameName: 'eSports',
      // gameCode: 'E1-ESPORTS-001',
      userId,
      // platform: 'E1SPORT',
      platformTxId: `${txnId}`, // 313812
      refPlatformTxId: null,
      settleType: 'platformTxId',
      roundId: `${roundId}`, // 46587500
      betType: gameNo ? '9001' : '20',
      turnover,
      betAmount,
      winAmount,
      betTime, // 2021-09-26T21:57:59.078+08:00
      txTime: betTime, // 2021-09-26T22:16:16.000+08:00
      updateTime, // 2021-09-28T13:35:21.676+08:00
      gameInfo: {
        ...this.getConstantGameInfo(gameNo),
        // betOpponent: '749980',
        // leagueType: 'CSGO',
        // betOpponentName: 'Akuma',
        oddsMode,
        // isQuickBet: 0,
        // oddsAcceptOption: 2,
        marketType,
        // marketId: '3935396',
        tournamentDate,
        odds: `${odds}`,
        // opponentName: 'Entropiq_vs_Akuma',
        // selectionKey: 'a',
        // betInfo: 'Main_Game_Akuma_Winner',
        status: 'SETTLED',
        txnResult,
      },
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      odds,
      updateTime,
      oddsMode = 1,
      marketType = '2',
      gameNo,
      tournamentDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'ESPORTS',
      gameName: 'eSports',
      // gameCode: 'E1-ESPORTS-001',
      userId,
      // platform: 'E1SPORT',
      platformTxId: `${txnId}`, // 313812
      roundId: `${roundId}`, // 46587500
      betAmount,
      updateTime, // 2021-09-28T13:35:21.676+08:00
      voidType: 2,
      gameInfo: {
        ...this.getConstantGameInfo(gameNo),
        // betOpponent: '749980',
        // leagueType: 'CSGO',
        // betOpponentName: 'Akuma',
        oddsMode,
        // isQuickBet: 0,
        // oddsAcceptOption: 2,
        marketType,
        // marketId: '3935396',
        tournamentDate,
        odds,
        // opponentName: 'Entropiq_vs_Akuma',
        // selectionKey: 'a',
        // betInfo: 'Main_Game_Akuma_Winner',
        status: 'SUCCESS',
      },
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'ESPORTS',
      gameName: 'eSports',
      // gameCode: 'E1-ESPORTS-001',
      userId,
      // platform: 'E1SPORT',
      platformTxId: `${txnId}`, // 313812
      roundId: `${roundId}`, // 46587500
      betAmount,
      updateTime, // 2021-09-28T13:35:21.676+08:00
      voidType: 2,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      odds,
      oddsMode = 1,
      marketType = '2',
      gameNo,
      tournamentDate,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'ESPORTS',
      // gameCode: 'E1-ESPORTS-001',
      userId,
      // platform: 'E1SPORT',
      platformTxId: `${txnId}`, // 313812
      roundId: `${roundId}`, // 46587500
      gameInfo: {
        ...this.getConstantGameInfo(gameNo),
        // betOpponent: '749980',
        // leagueType: 'CSGO',
        // betOpponentName: 'Akuma',
        oddsMode,
        // isQuickBet: 0,
        // oddsAcceptOption: 2,
        marketType,
        // marketId: '3935396',
        tournamentDate,
        odds,
        // opponentName: 'Entropiq_vs_Akuma',
        // selectionKey: 'a',
        // betInfo: 'Main_Game_Akuma_Winner',
        status: 'SUCCESS',
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
