<template>
  <Layout>
    <AntRow :gutter="20" class="content-row">
      <AntCol :span="24">
        <Instruction />
      </AntCol>
      <AntCol :xs="24" :sm="24" :md="24" :lg="8" :xl="6">
        <ParameterForm :testData="testData"  />
      </AntCol>
      <AntCol :xs="24" :sm="24" :md="24" :lg="16" :xl="18">
        <TestReport :testData="testData" />
      </AntCol>
    </AntRow>
  </Layout>
</template>

<script>
import { reactive } from 'vue'

import {
  Row as AntRow,
  Col as AntCol,
} from 'ant-design-vue'

import Layout from '@/components/SingleWalletTesting/Layout'
import Instruction from '@/components/SingleWalletTesting/Instruction'
import ParameterForm from '@/components/SingleWalletTesting/ParameterForm'
import TestReport from '@/components/SingleWalletTesting/TestReport'

export default {
  name: 'SingleWalletTesting',
  components: {
    AntRow,
    AntCol,
    Layout,
    Instruction,
    ParameterForm,
    TestReport,
  },
  setup() {
    const testData = reactive({
      isTesting: false,
      scenarioCounts: 0,
      testedScenarioCounts: 0,
      platformData: {},
      formData: {},
      testReportData: [],
      errorData: undefined,
    })

    return {
      testData,
    }
  },
}
</script>

<style scoped>
.content-row {
  padding: 32px 40px;
}
</style>
