<template>
  <div class="card">
    <div :class="['card-header', collapsable && 'collapsable', collapsed && 'collapsed']" @click="handleCollapsed">
      <CaretUpIcon v-if="collapsable" class="card-close" v-show="!collapsed" />
      <CaretDownIcon v-if="collapsable" class="card-close" v-show="collapsed" />
      <h1 class="card-title" :style="titleStyle">{{ title }}</h1>
    </div>
    <div v-show="!collapsed">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import CaretUpIcon from '@ant-design/icons-vue/lib/icons/CaretUpOutlined'
import CaretDownIcon from '@ant-design/icons-vue/lib/icons/CaretDownOutlined'

export default {
  name: 'Card',
  props: {
    title: String,
    titleStyle: Object,
    collapsable: Boolean,
    defaultCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CaretUpIcon,
    CaretDownIcon,
  },
  setup(props) {
    const collapsed = ref(props.defaultCollapsed)
    const handleCollapsed = () => { if (props.collapsable) collapsed.value = !collapsed.value }

    return {
      collapsed,
      handleCollapsed,
    }
  },
}
</script>

<style>
.card {
  background: #fff;
  box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 10%);
  padding: 28px 20px;
  margin: 10px 0;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.card-header.collapsable {
  cursor: pointer;
}

.card-header.collapsed {
  padding-bottom: 0;
  margin-bottom: 0;
  border: 0;
}

.card-title {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.75px;
  margin-bottom: 0;
}

.card-close {
  font-size: 20px;
  color: #333;
  margin-right: 12px;
}
</style>
