import PlatformActions from './PlatformActions'

export default class KINGMAKERMINIActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'TABLE',
      gameCode: 'KINGMAKERMINI-TABLE-001',
      platform: 'KINGMAKERMINI',
    }

    this.gameName = 'Baccarat'
  }

  getBetMessage(txnList) {
    const { currency, userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `gs3-brmn0006${txnId}0000`, // gs3-brmn00060bbeab7c627046800f3d69fc0000: gs3-brmn 0006 0bbeab7c627046800f3d69fc 0000
      roundId: `gs3-brmn-${roundId}`, // gs3-brmn-XQgpplB7NvrWG
      betType: null,
      currency,
      betTime, // 2023-12-05T16:03:29.029+08:00
      betAmount,
      gameInfo: {
        betType: `${betType}: ${betAmount.toFixed(1)}`,
        extension1: `${userIdPrefix}`,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      betType,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `gs3-brmn0006${txnId}0000`,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2023-12-05T16:03:48.389+08:00
      roundId: `gs3-brmn-${roundId}`,
      betType: null,
      betTime,
      txTime: betTime,
      turnover, // 50
      betAmount, // 50
      winAmount, // 97.5
      gameInfo: {
        betType: `${betType}: ${betAmount.toFixed(1)}`,
        reason: 'payout',
        agentId: `${userIdPrefix}`,
        link: `https://fake.kingdomhall729.com/plays/gs3-brmn-${roundId}/by_reference_number?transid=gs3-brmn0006${txnId}0000`,
        commission: 0,
        profit: winAmount - betAmount, // 47.5
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `gs3-brmn0006${txnId}0000`,
      roundId: `gs3-brmn-${roundId}`,
      gameInfo: {
        betType: `${betType}: ${betAmount.toFixed(1)}`,
        extension1: `${userIdPrefix}`,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
