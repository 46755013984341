import axios from 'axios'

export const getPlatform = async ({ platform }) => {
  const res = await axios.post('/api/swa/getPlatform', { platform })
  return res.data
}

export const updatePlatform = async ({
  platform,
  txnId,
  roundId,
  promotionTxId,
  tipTxnId,
}) => {
  const res = await axios.post('/api/swa/updatePlatform', {
    platform,
    txnId,
    roundId,
    promotionTxId,
    tipTxnId,
  })
  return res.data
}
