import PlatformActions from './PlatformActions'

export default class HOTROADActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'LIVE',
      gameCode: 'HOTROAD-LIVE-001',
      platform: 'HOTROAD',
    }

    this.gameName = 'BACCARAT'
    this.tableId = '16'

    const shoe = Math.random().toFixed(2).slice(-2)
    this.getRoundId = roundId => `RND-${String(this.tableId).padStart(4, '0')}110${shoe}${String(roundId).padStart(4, '0')}` // RND-0016110130037 0016(桌號)11013(靴號)0037(局號)
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betType,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `AAA-${txnId}`, // AAA-212257291
      roundId: this.getRoundId(roundId),
      betType,
      currency,
      betTime, // 2023-11-15T14:24:00.943+08:00
      betAmount,
      gameInfo: {
        tableId: this.tableId,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      betType,
      betTime,
      turnover,
      betAmount,
      winAmount,
      roundStartTime,
      odds,
      status,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `AAA-${txnId}`, // AAA-212257291
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2021-11-02T00:55:56.211+08:00
      roundId: this.getRoundId(roundId),
      betType,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        tableId: this.tableId,
        roundStartTime,
        odds: `${odds}`,
        status,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `AAA-${txnId}`, // AAA-212257291
      roundId: this.getRoundId(roundId),
      gameInfo: {
        tableId: this.tableId,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `AAA-${txnId}`, // AAA-212257291
      roundId: this.getRoundId(roundId),
      betAmount,
      updateTime,
      gameInfo: {
        tableId: this.tableId,
      },
      voidType,
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
