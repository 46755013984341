import PlatformActions from './PlatformActions'

export default class GTFFISHActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'FH',
      gameName: 'Fighting Fishing',
      gameCode: 'GTF-FISH-001',
      platform: 'GTF',
    }

    this.constantGameInfo = {
      isEndRound: true,
      hasFreeGame: '0',
      denom: '0.1',
      systemTakeWin: '0',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
      requireAmount,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 2240176
      roundId, // 5250178480127
      betType: '',
      currency,
      betTime, // 2024-04-09T16:18:30.000+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      jackpotBetAmount: 0,
      jackpotWinAmount: 0,
      updateTime, // 2024-04-09T16:18:30.094+08:00
      requireAmount: requireAmount ?? betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId,
      updateTime,
      betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
