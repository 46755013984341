import SEXYBCRTTestScenarios from './SEXYBCRTTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class AELIVECOMMTestScenarios extends SEXYBCRTTestScenarios {
  async negativeBalance_bet1_settleWin1_bet2_voidSettle1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.voidSettle({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'voidSettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiPlayers_multiBets_settle_voidSettle() {
    await this.setTestScenario(
      async () => {
        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.getBalance({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.multiBets({ playerIndexes: [playerIndex] })
        }

        await this.requests.multiSettle()

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'settle',
            playerIndexes: [playerIndex],
          })
        }

        await this.requests.multiVoidSettle()

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'voidSettle',
            playerIndexes: [playerIndex],
          })
        }
      },
    )
  }
}
