import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class PlatformAdjustBetTestScenarios extends PlatformTestScenarios {
  async bet_adjustBet_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        await this.requests.adjustBet()

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.settleWin({ timesLoop })
        }

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_asyncSettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        await this.requests.adjustBet()

        await Promise.all(Array.from({ length: 5 }).map(async (_, timesLoop) => {
          await this.requests.settleWin({
            timesLoop,
            options: { isAsync: true },
          })
          return true
        }))

        await this.requests.getBalance({ action: 'asyncSettle' })
      },
    )
  }

  async bet_adjustBet_settleLose_unsettle_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleWin_unsettle_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleWin_unsettle_settleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleTie_unsettle_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleLose_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleLose_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleLose_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleWin_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleWin_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleWin_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleTie_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleTie_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleTie_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleLose_resettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.resettle({
            options: { resettleResult: 'win' },
            timesLoop,
          })
        }

        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_adjustBet_voidBet5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.voidBet({ timesLoop })
        }

        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_adjustBet_voidBet_unvoidBet_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
        await this.requests.unvoidBet()
        await this.requests.getBalance({ action: 'unvoidBet' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleWin_unsettle_voidBet_unvoidBet_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
        await this.requests.unvoidBet()
        await this.requests.getBalance({ action: 'unvoidBet' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleWin_voidSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.voidSettle()
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async bet_adjustBet_settleLose_voidSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.voidSettle()
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async bet_adjustBet_settleWin_voidSettle_unvoidSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.voidSettle()
        await this.requests.getBalance({ action: 'voidSettle' })
        await this.requests.unvoidSettle()
        await this.requests.getBalance({ action: 'unvoidSettle' })
      },
    )
  }

  async bet_adjustBet_settleWin_voidSettle5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.voidSettle({ timesLoop })
        }

        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async bet_adjustBet_settleLose_refund() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.refund()
        await this.requests.getBalance({ action: 'refund' })
      },
    )
  }

  async bet_adjustBet_settleLose_refund5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.refund({ timesLoop })
        }

        await this.requests.getBalance({ action: 'refund' })
      },
    )
  }

  async negativeBalance_bet1_adjustBet1_settleWin1_bet2_unsettle1_settleLose1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })
        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: {
            differentRound: true,
            positiveOdds: true,
          },
        })

        await this.requests.unsettle({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleLose({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_adjustBet1_settleWin1_bet2_unsettle1_settleTie1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })
        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: {
            differentRound: true,
            positiveOdds: true,
          },
        })

        await this.requests.unsettle({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_adjustBet1_settleWin1_bet2_unsettle1_voidBet1_unvoidBet1_settleWin1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })
        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: {
            differentRound: true,
            positiveOdds: true,
          },
        })

        await this.requests.unsettle({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.voidBet({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'voidBet',
          options: { validateNegativeBalance: true },
        })

        await this.requests.unvoidBet({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'unvoidBet',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_adjustBet1_settleWin1_bet2_resettleLose1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })
        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: {
            differentRound: true,
            positiveOdds: true,
          },
        })

        await this.requests.resettle({
          betIndexes: [0],
          options: { resettleResult: 'lose' },
        })

        await this.requests.getBalance({
          action: 'resettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBetForOddsChange_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.adjustBet({ options: { forOddsChange: true } })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async betOnPositiveOdds_adjustBetForOddsChange_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ options: { positiveOdds: true } })
        await this.requests.adjustBet({ options: { forOddsChange: true } })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }
}
