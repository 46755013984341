<template>
  <AntTable
    :rowKey="(record, index) => `scenario${scenarioIndex}-request${requestIndex}-custom${index}`"
    :columns="customColumns"
    :dataSource="getCustomDataSource(record)"
    :pagination="false"
    :showHeader="false"
    :expandedRowKeys="expandedRowKeys.custom"
    @expandedRowsChange="onExpandedRowsChange"
    size="small"
    class="no-bg"
  >
    <template #timestamp="{ text }">
      <div class="timestamp">{{ fomartTimestamp(text) }}</div>
    </template>
    <template #expandedRowRender="{ record, index }">
      <slot name="expandedRowRender" :record="record" :index="index"></slot>
    </template>
  </AntTable>
</template>

<script>
import { inject, reactive } from 'vue'
import moment from 'moment'

import { Table as AntTable } from 'ant-design-vue'

export default {
  name: 'TestCustomTable',
  components: {
    AntTable,
  },
  props: {
    record: {
      type: Object,
      default() {
        return {}
      },
    },
    scenarioIndex: {
      type: Number,
      default: 0,
    },
    requestIndex: {
      type: Number,
      default: 0,
    },
    expandedRowKeys: Object,
  },
  setup(props) {
    const t = inject('t')
    const expandedRowKeys = reactive(props.expandedRowKeys)

    const customColumns = [
      {
        dataIndex: 'title',
        key: 'title',
        width: '100%',
      },
      {
        dataIndex: 'timestamp',
        key: 'timestamp',
        slots: { customRender: 'timestamp' },
      },
    ]

    const onExpandedRowsChange = rowKeys => expandedRowKeys.custom = rowKeys

    const fomartTimestamp = (text) => {
      if (!text) return ''
      return moment(text).format('YYYY/MM/DD HH:mm:ss.SSS (Z)')
    }

    const getCustomDataSource = record => ([
      {
        title: t('request-body'),
        content: record.requestBody,
        timestamp: record.requestTs,
      },
      {
        title: t('response-body'),
        content: record.responseBody,
        timestamp: record.responseTs,
      },
      {
        title: t('tests'),
        content: record.tests,
      },
    ])

    return {
      t,
      customColumns,
      onExpandedRowsChange,
      fomartTimestamp,
      getCustomDataSource,
    }
  },
}
</script>

<style>
.no-bg .ant-table {
  background: initial;
}

.timestamp {
  white-space: nowrap;
}
</style>
