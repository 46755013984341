<template>
  <div class="exportable-table hide">
    <table id="client-fields-table">
      <thead>
        <tr>
          <th>{{ t('parameter') }}</th>
          <th>{{ t('value') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ t('form-label-platform') }}</td>
          <td>{{ platformData.name }}</td>
        </tr>
        <template
          v-for="key in platformData.formFields"
          :key="`client-fields-${key}`"
        >
          <tr>
            <td>{{ t(`form-label-${key}`) }}</td>
            <td v-if="key === 'skippedTestScenarios'" v-html="getSkippedScenariosHtml(formData[key])"></td>
            <td v-else>{{ formData[key] }}</td>
          </tr>
        </template>
      </tbody>
    </table>

    <table id="test-report-table">
      <thead>
        <tr>
          <th class="scenario-th center">{{ t('test-result') }}</th>
          <th colspan="5" class="scenario-th">{{ t('test-scenario') }}</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="({ scenario, passed, requests }, scenarioIndex) in testReportData"
          :key="`scenario${scenarioIndex}`"
        >
          <tr>
            <td class="center">
              <v-switch :case="getScenarioResult({ passed, requests }).toString()">
                <template #warning>{{ t('warning') }}</template>
                <template #true>{{ t('pass') }}</template>
                <template #false>{{ t('fail') }}</template>
              </v-switch>
            </td>
            <td colspan="5">{{ scenario }}</td>
          </tr>
          <tr v-if="requests.length">
            <th class="empty"></th>
            <th class="request-head center">{{ t('test-result') }}</th>
            <th class="request-head" colspan="2">{{ t('resquest-name') }}</th>
            <th class="request-head center">{{ t('response-code') }}</th>
            <th class="request-head right">{{ t('duration') }}</th>
          </tr>
          <template
            v-for="({
              name,
              extraData,
              requestBody,
              responseBody,
              requestTs,
              responseTs,
              responseCode,
              passed,
              tests,
              duration,
            }, requestIndex) in requests"
            :key=" `scenario${scenarioIndex}-request${requestIndex}`"
          >
            <tr>
              <td class="empty"></td>
              <td class="center">
                <v-switch :case="getRequestResult({ passed, tests }).toString()">
                  <template #warning>{{ t('warning') }}</template>
                  <template #true>{{ t('pass') }}</template>
                  <template #false>{{ t('fail') }}</template>
                </v-switch>
              </td>
              <td colspan="2">
                <span v-html="name.replaceAll('\n', '<br>')"></span>
                <template v-if="extraData && extraData.settleTxnIds">
                  <br><br>
                  {{ t('confirm-settled-platformTxId-msg1') }}<br>
                  <template v-for="id in extraData.settleTxnIds" :key="id">
                    {{ id }}<br>
                  </template>
                  {{ t('confirm-settled-platformTxId-msg2') }}
                </template>
              </td>
              <td class="center">{{ responseCode }}</td>
              <td class="right">{{ duration }} ms</td>
            </tr>
            <tr>
              <th class="empty"></th>
              <th class="empty"></th>
              <th colspan="2" class="json-head">{{ t('request-body') }}</th>
              <th colspan="2" class="json-head right">{{ fomartTimestamp(requestTs) }}</th>
            </tr>
            <tr>
              <td class="empty"></td>
              <td class="empty"></td>
              <td colspan="4">{{ prettifyJsonString(requestBody) }}</td>
            </tr>
            <tr>
              <th class="empty"></th>
              <th class="empty"></th>
              <th colspan="2" class="json-head"><b>{{ t('response-body') }}</b></th>
              <th colspan="2" class="json-head right">{{ fomartTimestamp(responseTs) }}</th>
            </tr>
            <tr>
              <td class="empty"></td>
              <td class="empty"></td>
              <td colspan="4">{{ prettifyJsonString(responseBody) }}</td>
            </tr>
            <tr v-if="tests.length">
              <th class="empty"></th>
              <th class="empty"></th>
              <th class="case-head center">{{ t('test-result') }}</th>
              <th class="case-head" colspan="3">{{ t('tests') }}</th>
            </tr>
            <tr v-for="({ name, passed, warning }, testIndex) in insertWarningRows(tests)" :key=" `scenario${scenarioIndex}-request${requestIndex}-test${testIndex}`">
              <template v-if="!warning">
                <td class="empty"></td>
                <td class="empty"></td>
                <td class="center">{{ passed ? t('pass') : t('fail') }}</td>
              </template>
              <template v-if="passed === 'warning'">
                <td rowspan="2" class="empty"></td>
                <td rowspan="2" class="empty"></td>
                <td rowspan="2" class="center">{{ t('warning') }}</td>
              </template>
              <td colspan="3">
                {{ passed === 'warning' ? `${t('warning')}${t('colon')}${warning}` : name }}
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject } from 'vue'
import moment from 'moment'
import VSwitch from '@lmiller1990/v-switch'

import prettifyJsonString from '@/utils/prettifyJsonString'
import ALL_PLATFORM_TEST_SCENARIO_LIST from '@/libs/SWA/testScenarioList'

export default {
  name: 'ExportableTable',
  props: {
    platformData: Object,
    formData: Object,
    testReportData: Array,
  },
  components: {
    VSwitch,
  },
  setup(props) {
    const t = inject('t')

    const getScenarioResult = ({ passed, requests }) => {
      const haveWarning = requests.some(({ tests }) => tests.some(({ warning }) => !!warning))

      // eslint-disable-next-line no-nested-ternary
      return passed
        ? haveWarning ? 'warning' : passed
        : passed
    }

    const getRequestResult = ({ passed, tests }) => {
      const haveWarning = tests.some(({ warning }) => !!warning)

      // eslint-disable-next-line no-nested-ternary
      return passed
        ? haveWarning ? 'warning' : passed
        : passed
    }

    const insertWarningRows = (tests) => {
      const arr = []
      tests.forEach((test) => {
        const { warning } = test
        if (warning) {
          arr.push({
            passed: 'warning',
            warning,
          })
        }
        arr.push(test)
      })
      return arr
    }

    const fomartTimestamp = (text) => {
      if (!text) return ''
      return moment(text).format('YYYY/MM/DD HH:mm:ss.SSS (Z)')
    }

    const getSkippedScenariosHtml = (skippedScenarios) => {
      const scenarioList = ALL_PLATFORM_TEST_SCENARIO_LIST[props.formData.platform]
      return skippedScenarios.reduce((acc, scenarioNo) => {
        if (scenarioList[scenarioNo]) acc.push(`${scenarioNo}. ${scenarioList[scenarioNo].name}`)
        return acc
      }, []).join('<br>')
    }

    return {
      t,
      getScenarioResult,
      getRequestResult,
      insertWarningRows,
      fomartTimestamp,
      prettifyJsonString,
      getSkippedScenariosHtml,
    }
  },
}
</script>

<style scoped>
.exportable-table {
  background: #fff;
  font-size: 10px;
}

.hide {
  visibility: hidden;
  position: fixed;
  left: -10000px;
  top: 0px;
  border: 0px;
}

.exportable-table, .exportable-table th, .exportable-table td{
  border: 1px solid #eee;
  padding: 0 8px;
}

#client-fields-table tr:nth-child(even) {
  background-color: #eee;
}

#client-fields-table th {
  color: #fff;
  background: #4f6196;
}

.scenario-th {
  color: #fff;
  background: #4f6196;
}

.request-head {
  background: #a9b2cb;
}

.case-head {
  background: #eee;
}

.json-head {
  background: #eee;
}

.empty {
  background: #fff;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
</style>
