import axios from 'axios'
import moment from 'moment'

import PlatformActions from './PlatformActions'

export default class SEXYBCRTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnDataByGame = {
      baccarat: {
        gameName: 'BaccaratClassic',
        gameCode: 'MX-LIVE-001',
        txnIdPrefix: 'BAC',
      },
      roulette: {
        gameName: 'Roulette',
        gameCode: 'MX-LIVE-009',
        txnIdPrefix: 'RL',
      },
      oneday: {
        gameName: 'oneday',
        gameCode: 'MX-LIVE-013',
        txnIdPrefix: 'TPO',
      },
    }

    this.constantGameInfoByGame = {
      baccarat: {
        streamerId: '',
        tableId: '1',
        dealerDomain: 'Mexico',
      },
      roulette: {
        tableId: '71',
        dealerDomain: 'Mexico',
      },
      oneday: {
        tableId: '91',
        dealerDomain: 'Mexico',
      },
    }

    this.constantTipInfo = {
      quantity: 1,
      receiverId: 'Alice/Bulgaria/1501544',
      giftName: 'Balloon',
    }
  }

  constantTxnData(game = 'baccarat') {
    return {
      gameType: 'LIVE',
      // gameName: this.constantTxnDataByGame[game], // cancelBet and cancelAdjustAmt do NOT have this field
      gameCode: this.constantTxnDataByGame[game].gameCode,
      platform: 'SEXYBCRT',
    }
  }

  getPlatformTxIdAndRoundId({ txnId, roundId, game }) {
    const { txnIdPrefix } = this.constantTxnDataByGame[game]
    const { dealerDomain, tableId } = this.constantGameInfoByGame[game]
    const tableIdInRound = tableId.padStart(2, '0')

    return {
      platformTxId: `${txnIdPrefix}-${txnId}`, // BAC-8511001601
      roundId: `${dealerDomain}-${tableIdInRound}-GA${roundId}`, // Mexico-01-GA262720036
    }
  }

  async bet(txnList) {
    const { callbackUrl, key } = this.formData
    const [{ txnId }] = txnList

    const reqBody = {
      key,
      message: this.getBetMessage(txnList),
    }

    const res = await axios.post(`/proxy/${callbackUrl}?trace=${moment().valueOf()}-BAC-${txnId}`, reqBody)
    console.log('SEXYBCRT bet res', res)
    return res
  }

  async adjustAmt(txnList) {
    const { callbackUrl, key } = this.formData
    const [{ txnId }] = txnList

    const reqBody = {
      key,
      message: this.getAdjustAmtMessage(txnList),
    }

    const res = await axios.post(`/proxy/${callbackUrl}?trace=${moment().valueOf()}-TPO-${txnId}`, reqBody)
    console.log('SEXYBCRT adjustAmt res', res)
    return res
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      betType = 'Banker',
      betTime,
      betAmount,
      roundStartTime,
      denyTime,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      betType,
      currency,
      betTime,
      betAmount,
      gameInfo: {
        roundStartTime,
        ...this.constantGameInfoByGame[game],
        denyTime,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      updateTime,
      roundId,
      betType = 'Banker',
      betTime,
      turnover,
      betAmount,
      winAmount,
      result,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      betType,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime,
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getUnsettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      result,
      betAmount,
      updateTime,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      updateTime,
      betAmount,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime,
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'unsettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      updateTime,
      roundId,
      betType = 'Banker',
      betTime,
      turnover,
      betAmount,
      winAmount,
      result,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      betType,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime,
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
      roundStartTime,
      result,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      betAmount,
      updateTime,
      voidType,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime,
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      updateTime,
      voidType,
      betAmount,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      roundStartTime,
    }) => ({
      ...this.constantTxnData(game),
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      gameInfo: {
        roundStartTime,
        ...this.constantGameInfoByGame[game],
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getGiveMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      betAmount,
      promotionTxId,
      txTime,
    }) => ({
      platform: this.constantTxnData().platform,
      currency,
      userId,
      amount: betAmount, // betAmount.toFixed(1)
      promotionTxId: `W${promotionTxId}`, // ex: W2626000000014
      promotionId: '336',
      promotionTypeId: '3',
      txTime,
    }))

    const message = {
      action: 'give',
      txns,
    }

    return JSON.stringify(message)
  }

  getTipMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      tipTxnId,
      tip,
      txTime,
    }) => {
      const { tableId, dealerDomain } = this.constantGameInfoByGame[game]

      return {
        ...this.constantTxnData(game),
        gameName: this.constantTxnDataByGame[game].gameName,
        userId,
        platformTxId: `TIP-${tipTxnId}`, // ex id: 6665
        tip,
        currency,
        txTime,
        tipInfo: {
          unitPrice: tip,
          ...this.constantTipInfo,
          tableId,
          dealerDomain,
        },
        type: 'DEALER_TIPPING',
      }
    })

    const message = {
      action: 'tip',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelTipMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      tipTxnId,
      tip,
    }) => {
      const { tableId, dealerDomain } = this.constantGameInfoByGame[game]

      return {
        ...this.constantTxnData(game),
        gameName: this.constantTxnDataByGame[game].gameName,
        userId,
        platformTxId: `TIP-${tipTxnId}`,
        tipInfo: {
          unitPrice: tip,
          ...this.constantTipInfo,
          tableId,
          dealerDomain,
        },
        type: 'DEALER_TIPPING',
      }
    })

    const message = {
      action: 'cancelTip',
      txns,
    }

    return JSON.stringify(message)
  }

  getAdjustAmtMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      game = 'oneday',
      userId,
      txnId,
      adjustId,
      roundId,
      betTime,
      adjustAmount,
      roundStartTime,
      denyTime,
    }) => {
      const { txnIdPrefix } = this.constantTxnDataByGame[game]

      return {
        ...this.constantTxnData(game),
        gameName: this.constantTxnDataByGame[game].gameName,
        userId,
        ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
        refPlatformTxId: `${txnIdPrefix}-${txnId}-${adjustId}`,
        betType: null,
        currency,
        betTime,
        adjustAmount,
        gameInfo: {
          roundStartTime,
          ...this.constantGameInfoByGame[game],
          denyTime,
        },
      }
    })

    const message = {
      action: 'adjustAmt',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelAdjustAmtMessage(txnList) {
    const txns = txnList.map(({
      game = 'oneday',
      userId,
      txnId,
      adjustId,
      roundId,
      roundStartTime,
      denyTime,
    }) => {
      const { txnIdPrefix } = this.constantTxnDataByGame[game]

      return {
        ...this.constantTxnData(game),
        userId,
        ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
        refPlatformTxId: `${txnIdPrefix}-${txnId}-${adjustId}`,
        gameInfo: {
          roundStartTime,
          ...this.constantGameInfoByGame[game],
          denyTime,
        },
      }
    })

    const message = {
      action: 'cancelAdjustAmt',
      txns,
    }

    return JSON.stringify(message)
  }
}
