import PlatformActions from './PlatformActions'

export default class PGTABLEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'TABLE',
      gameCode: 'PG-TABLE-001',
      platform: 'PG',
    }

    this.gameName = 'Baccarat Deluxe'

    this.constantGameInfo = {
      freeGameTransactionId: '',
      isAdjustment: false,
      bonusTransactionId: '',
      isFeature: false,
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 1551813785193807872
      roundId: `${txnId}`, // 1551813785193807872
      betType: '1',
      currency,
      betTime, // 2022-07-26T14:16:52.322+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, // 2022-07-26T14:16:54.284+08:00
      requireAmount: betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${txnId}`,
      roundId: `${txnId}`,
      updateTime,
      betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
