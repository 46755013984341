export default [
  {
    name: 'Bet > AdjustBet > Settle Win (MY oddsType = 1 ‒)',
    functionName: 'bet_adjustBet_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose (CN oddsType = 2 +)',
    functionName: 'bet_adjustBet_settleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie (MY oddsType = 1 +)',
    functionName: 'bet_adjustBet_settleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Win 5 times (DEC oddsType = 3 +)',
    functionName: 'bet_adjustBet_settleWin5Times',
  },
  {
    name: 'Bet > AdjustBet > (Network) Settle Win 5 times (CN oddsType = 2 +)',
    functionName: 'bet_adjustBet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Unsettle > Settle Win (IND oddsType = 4 +)',
    functionName: 'bet_adjustBet_settleLose_unsettle_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Unsettle > Settle Lose (IND oddsType = 4 ‒)',
    functionName: 'bet_adjustBet_settleWin_unsettle_settleLose',
  },
  {
    name: 'Bet > AdjustBet > VoidBet (CN oddsType = 2 +)',
    functionName: 'bet_adjustBet_voidBet',
  },
  {
    name: 'Bet > AdjustBet > VoidBet 5 times (US oddsType = 5 ‒)',
    functionName: 'bet_adjustBet_voidBet5Times',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Unsettle > VoidBet (US oddsType = 5 +)',
    functionName: 'bet_adjustBet_settleWin_unsettle_voidBet',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Unsettle > VoidBet (IND oddsType = 4 ‒)',
    functionName: 'bet_adjustBet_settleLose_unsettle_voidBet',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > VoidSettle (IND oddsType = 4 +)',
    functionName: 'bet_adjustBet_settleWin_voidSettle',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > VoidSettle (CN oddsType = 2 +)',
    functionName: 'bet_adjustBet_settleLose_voidSettle',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > VoidSettle 5 times (DEC oddsType = 3 +)',
    functionName: 'bet_adjustBet_settleWin_voidSettle5Times',
  },
  {
    name: 'Bet > CancelBet (CN oddsType = 2 +)',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'CancelBet only (CN oddsType = 2 +)',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet (CN oddsType = 2 +)',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet (CN oddsType = 2 +)',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Bet > CancelBet 5 times (CN oddsType = 2 +)',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'Bet on Parlay > Settle Win (DEC oddsType = 3 +)',
    functionName: 'betOnParlay_settleWin',
  },
  {
    name: 'Negative Balance - Bet > AdjustBet > Settle Win > Unsettle > Settle Lose (MY oddsType = 1 +)',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_unsettle1_settleLose1_settleWin2',
  },
  {
    name: 'Negative Balance - Bet > AdjustBet > Settle Win > Unsettle > Void Bet (IND oddsType = 4 +)',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_unsettle1_voidBet1_settleWin2',
  },
  {
    name: 'Multi Bets - Bet > AdjustBet > Settle Win (MY oddsType = 1 +/‒)',
    functionName: 'multiBets_bet1_bet2_bet3_settle',
  },
  {
    name: 'Odds Change - Bet > AdjustBet > Settle Win (US oddsType = 5 ‒)',
    functionName: 'bet_adjustBetForOddsChange_settleWin',
  },
  {
    name: 'Odds Change - Bet > AdjustBet > Settle Win (US oddsType = 5 +)',
    functionName: 'betOnPositiveOdds_adjustBetForOddsChange_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Win (CN oddsType = 2 +)',
    functionName: 'bet_adjustBet_settleWin_resettleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Win (winAmount +) (MY oddsType = 1 +)',
    functionName: 'bet_adjustBet_settleWin_resettleWin_increased',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Win (winAmount -) (MY oddsType = 1 ‒)',
    functionName: 'bet_adjustBet_settleWin_resettleWin_decreased',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Lose (CN oddsType = 2 +)',
    functionName: 'bet_adjustBet_settleWin_resettleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Tie (DEC oddsType = 3 +)',
    functionName: 'bet_adjustBet_settleWin_resettleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Win (CN oddsType = 2 +)',
    functionName: 'bet_adjustBet_settleLose_resettleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Lose (CN oddsType = 2 +)',
    functionName: 'bet_adjustBet_settleLose_resettleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Tie (IND oddsType = 4 +)',
    functionName: 'bet_adjustBet_settleLose_resettleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie > Resettle Win (IND oddsType = 4 ‒)',
    functionName: 'bet_adjustBet_settleTie_resettleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie > Resettle Lose (US oddsType = 5 +)',
    functionName: 'bet_adjustBet_settleTie_resettleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie > Resettle Tie (US oddsType = 5 ‒)',
    functionName: 'bet_adjustBet_settleTie_resettleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Win 5 times (CN oddsType = 2 +)',
    functionName: 'bet_adjustBet_settleLose_resettleWin5Times',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Lose (MY oddsType = 1 ‒)',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_resettleLose1_settleTie2',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Tie (DEC oddsType = 3 +)',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_resettleTie1_settleTie2',
  },
  {
    name: 'Positive Balance - Bet (CN oddsType = 2 +)',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet (CN oddsType = 2 +)',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet (CN oddsType = 2 +)',
    functionName: 'negativeBalance_bet',
  },
]
