import PlatformTestScenarios from './PlatformTestScenarios'

// OddsType:
// MY: 1
// CN: 2
// DEC: 3
// IND: 4
// US: 5

/* eslint-disable camelcase, no-await-in-loop */
export default class SABAOLDTestScenarios extends PlatformTestScenarios {
  async bet_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '1' } }) // MY [-]
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '5' } }) // US [-]

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.settleWin({ timesLoop })
        }

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleLose_unsettle_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '4' } }) // IND [+]
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin_unsettle_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '1' } }) // MY [-]
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_voidBet5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '1' } }) // MY [-]

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.voidBet({ timesLoop })
        }

        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_settleWin_unsettle_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '1' } }) // MY [-]
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_settleLose_unsettle_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '5' } }) // US [-]
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_settleWin_voidSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '4' } })// IND [+]
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.voidSettle()
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async bet_settleWin_voidSettle5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '3' } }) // DEC [+]
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.voidSettle({ timesLoop })
        }

        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async bet_cancelBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '3' } }) // DEC [+]
        await this.requests.cancelBet()
      },
    )
  }

  async cancelBet_bet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.cancelBet({
          data: { oddsType: '1' }, // MY [-]
          options: { beforeBet: true },
        })

        await this.requests.bet({ options: { afterCancelBet: true } })
        await this.requests.getBalance({ action: 'betAfterCancelBet' })
      },
    )
  }

  async bet1_bet2_bet3_cancelBet123() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        for (let roundIdx = 0; roundIdx < 3; roundIdx += 1) {
          await this.requests.bet({
            betIndexes: [roundIdx],
            data: { oddsType: '3' }, // DEC [+]
            options: {
              differentRound: true,
            },
          })
        }
        await this.requests.cancelBet({ betIndexes: [0, 1, 2] })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_unsettle1_settleLose1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { oddsType: '3' }, // DEC [+]
        })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          data: {
            odds: 1.1,
            oddsType: '3', // DEC [+]
          },
          options: {
            differentRound: true,
          },
        })

        await this.requests.unsettle({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleLose({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async betOnParlay_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { isParlay: true } })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }
}
