import moment from 'moment'
import PlatformActions from './PlatformActions'

export default class LUDOActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'TABLE',
      gameCode: 'LUDO-TABLE-001',
      platform: 'LUDO',
    }

    this.gameName = 'LUDO'

    this.getConstantGameInfo = ({
      userId,
      txnId,
      roundId,
      betAmount,
      betTime,
      sign,
    }) => ({
      action: 'placeBet',
      username: `${this.formData.userIdPrefix}${userId}`,
      sign, // f1ff6c363deef25099e2cc34fd98952d,
      ticketID: `${txnId}`,
      game: 'Ludo',
      roundID: `${roundId}`,
      betAmt: betAmount,
      betTime: moment.utc(betTime).format().replace('Z', '.000+0000'), // 2022-02-16T05:31:35.000+0000,
    })
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      roundIdSuffix,
      betAmount,
      betTime,
      sign,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 2639080
      roundId: `${roundId}${roundIdSuffix}`, // 16133134GE3DVL24
      betType: null,
      currency,
      betTime, // 2022-02-16T13:31:35.000+08:00
      betAmount,
      gameInfo: {
        ...this.getConstantGameInfo({
          userId,
          txnId,
          roundId: `${roundId}${roundIdSuffix}`,
          betTime,
          betAmount,
          sign,
        }),
        winAmt: null,
        descr: null,
        result: null,
        info: null,
        cancelTime: null,
        settleTime: null,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      roundIdSuffix,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
      sign,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'SLOT',
      gameName: this.gameName,
      // gameCode: 'PG-SLOT-054',
      userId,
      // platform: 'PG',
      platformTxId: `${txnId}`,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // ex: 2021-10-01T13:11:16.017+08:00
      roundId: `${roundId}${roundIdSuffix}`,
      betType: null,
      betTime, // ex: 2021-10-01T13:11:15.798+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...this.getConstantGameInfo({
          userId,
          txnId,
          roundId: `${roundId}${roundIdSuffix}`,
          betTime,
          betAmount,
          sign,
        }),
        nply: 4,
        fin: 4,
        kill: 2,
        die: 1,
        roll: 103,
        step: 274,
        c: 0.79,
        m: 8548,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      roundIdSuffix,
      betAmount,
      betTime,
      sign,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${txnId}`,
      roundId: `${roundId}${roundIdSuffix}`,
      gameInfo: {
        ...this.getConstantGameInfo({
          userId,
          txnId,
          roundId: `${roundId}${roundIdSuffix}`,
          betTime,
          betAmount,
          sign,
        }),
        winAmt: null,
        descr: null,
        result: null,
        info: null,
        cancelTime: null,
        settleTime: null,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
