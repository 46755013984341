import EVOLUTIONActions from './EVOLUTIONActions'

export default class NLCActions extends EVOLUTIONActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'NLC-SLOT-011',
      platform: 'NLC',
    }

    this.gameName = 'EvilGoblins'
  }
}
