export default [
  {
    name: 'Bet > Settle Win',
    functionName: 'bet_settleWin',
  },
  {
    name: 'Bet > Settle Lose',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Win 5 times',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times',
    functionName: 'bet_asyncSettleWin5Times',
  },
  {
    name: 'Multi Bets - Place 2 bets in 2 rounds > Settle Win',
    functionName: 'multiBets_bet1_bet2_settleWin',
  },
  {
    name: 'Multi Bets - Place 3 bets > Undo (CancelBet) 3 times',
    functionName: 'multiBets_bet1_bet2_bet3_cancelBet3_cancelBet2_cancelBet1',
  },
  {
    name: 'Multi Bets - Place 3 bets > Undo (CancelBet) > Settle',
    functionName: 'multiBets_bet1_bet2_bet3_cancelBet3_settle12',
  },
  {
    name: 'Multi Bets - Place 4 bets > CancelBet',
    functionName: 'multiBets_bet1_bet2_bet3_bet4_cancelBet1234',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
