export default [
  {
    name: 'Bet > Settle Win',
    functionName: 'bet_settleWin',
  },
  {
    name: 'Bet > Settle Lose',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Win 5 times',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times',
    functionName: 'bet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > AdjustBet (odds+) > Settle Win',
    functionName: 'bet_adjustBet_settleWin',
  },
  {
    name: 'Bet > AdjustBet (odds+) > Settle Lose',
    functionName: 'bet_adjustBet_settleLose',
  },
  {
    name: 'Bet > AdjustBet (odds-) > Settle Win',
    functionName: 'bet_adjustBetForOddsDecreased_settleWin',
  },
  {
    name: 'Bet > AdjustBet (odds-) > Settle Lose',
    functionName: 'bet_adjustBetForOddsDecreased_settleLose',
  },
  {
    name: 'Bet > VoidBet',
    functionName: 'bet_voidBet',
  },
  {
    name: 'Bet > VoidBet 5 times',
    functionName: 'bet_voidBet5Times',
  },
  {
    name: 'Bet > VoidBet > UnvoidBet > SettleWin',
    functionName: 'bet_voidBet_unvoidBet_settleWin',
  },
  {
    name: 'Bet > Settle Lose > Unsettle > Settle Win',
    functionName: 'bet_settleLose_unsettle_settleWin',
  },
  {
    name: 'Bet > Settle Win > Unsettle > Settle Lose',
    functionName: 'bet_settleWin_unsettle_settleLose',
  },
  {
    name: 'Bet > Settle Win > Unsettle > VoidBet',
    functionName: 'bet_settleWin_unsettle_voidBet',
  },
  {
    name: 'Bet > Settle Win > VoidSettle',
    functionName: 'bet_settleWin_voidSettle',
  },
  {
    name: 'Bet > Settle Lose > VoidSettle',
    functionName: 'bet_settleLose_voidSettle',
  },
  {
    name: 'Bet > Settle Win > VoidSettle 5 times',
    functionName: 'bet_settleWin_voidSettle5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'Negative Balance - Settle Win > Unsettle > Settle Lose',
    functionName: 'negativeBalance_bet1_settleWin1_bet2_unsettle1_settleLose1_settleWin2',
  },
  {
    name: 'Negative Balance - VoidBet > UnvoidBet',
    functionName: 'negativeBalance_bet1_voidBet1_bet2_unvoidBet1_settleLose1_settleWin2',
  },
  {
    name: 'Negative Balance - Settle Win > VoidSettle',
    functionName: 'negativeBalance_bet1_settleWin1_bet2_voidSettle1_settleWin2',
  },
  {
    name: 'Multi Bets - Bet on W/P > Settle Lose on WIN, Settle Win on PLC',
    functionName: 'multiBets_betOnWP_settleLoseOnW_settleWinOnP',
  },
  {
    name: 'Multi Bets - Bet on same position twice > Settle Win > VoidSettle on TXN 1',
    functionName: 'multiBets_bet1_bet2_settleWin12_voidSettle1',
  },
  {
    name: 'Multi Bets - Place 3 bets on 3 horses > VoidBet on TXN 1 > Settle Win on TXN 2 & Settle Lose on TXN 3',
    functionName: 'multiBets_bet1_bet2_bet3_voidBet1_settleWin2_settleLose3',
  },
  {
    name: 'Multi Bets - Bet on WIN / PLC / WP for 10 horses',
    functionName: 'multiBets_bet1_bet2_bet3_10horses_settle',
  },
  {
    name: 'Multi Players - Bet > Settle Win',
    functionName: 'multiPlayers_bet_settleWin',
  },
  {
    name: 'Multi Players - Bet > VoidBet',
    functionName: 'multiPlayers_bet_voidBet',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
