import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class AELOTTOTestScenarios extends PlatformTestScenarios {
  constructor(formData) {
    super(formData)
    this.BET_ITEMS = ['04', '13', '36', '61', '89']
  }

  async multiBets_bet1_bet2_bet3_bet4_bet5_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = this.BET_ITEMS.length
        const betIndexes = []
        for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
          await this.requests.bet({
            betIndexes: [betIndex],
            data: {
              betItems: this.BET_ITEMS[betIndex],
            },
          })

          betIndexes.push(betIndex)
        }

        await this.requests.settleWin({ betIndexes: [BET_LENGTH - 1] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.settleLose({
          betIndexes: Array.from({ length: BET_LENGTH - 1 }).map((_, i) => i),
        })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_bet4_bet5_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = this.BET_ITEMS.length
        const betIndexes = []
        for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
          await this.requests.bet({
            betIndexes: [betIndex],
            data: {
              betItems: this.BET_ITEMS[betIndex],
            },
          })

          betIndexes.push(betIndex)
        }

        await this.requests.voidBet({ betIndexes })
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async multiTxnsIn1Bet_bet_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.multiBets()

        await this.requests.multiSettle({
          settleResult: 'win',
        })

        await this.requests.getBalance({ action: 'settle' })

        await this.requests.multiSettle({
          settleResult: 'lose',
        })

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiTxnsIn1Bet_bet_cancelBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.multiBets()
        await this.requests.multiCancelBets()
      },
    )
  }

  async multiTxnsIn1Bet_bet_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.multiBets()
        await this.requests.multiVoidBets()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }
}
