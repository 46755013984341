import EVOLUTIONActions from './EVOLUTIONActions'

export default class NETENTTABLEActions extends EVOLUTIONActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'TABLE',
      gameCode: 'NETENT-TABLE-001',
      platform: 'NETENT',
    }

    this.gameName = 'NetEnt Blackjack Classic'
  }
}
