import PlatformActions from './PlatformActions'

export default class SABAActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'VIRTUAL',
      // gameName: this.gameName, // cancelBet do NOT have this field
      gameCode: 'SABA-VIRTUAL-001',
      platform: 'SABA',
    }

    this.gameName = 'Virtual Sports'

    this.constantGameInfo = {
      sportTypeName_en: 'Soccer',
      sportTypeName: 'Soccer',
      oddsId: 468532274,
      betTypeName: 'Handicap',
      homeName: 'Italy (V)',
      betTeam: 'a',
      betTypeName_en: 'Handicap',
      oddsType: 1,
      betChoice: 'Mexico (V)',
      awayName_en: 'Mexico (V)',
      tsId: '', // PRD no?
      point: '1.75',
      point2: '',
      leagueName: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
      isLive: false, // PRD no?
      awayScore: 0,
      leagueId: 95731,
      excluding: '',
      // operationId: '4244900_1_301_U', // PRD no?
      betFrom: 'x', // PRD no?
      homeId: 674770,
      oddsInfo: '', // PRD no?
      homeScore: 0, // PRD no?
      // matchDateTime: '2022-09-13T01:30:00.000-04:00', // PRD no?
      leagueName_en: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
      sportType: 1,
      IP: '172.168.0.1', // PRD no?
      homeName_en: 'Italy (V)',
      awayId: 674804,
      awayName: 'Mexico (V)',
      baStatus: false,
      betRemark: '', // PRD no?
      betChoice_en: 'Mexico (V)',
    }

    this.getParlayGameInfo = ({ betAmount, odds, matchDateTime }) => ({
      txnDetail: {
        parlayType: 'Parlay_Combo',
        detail: [{
          type: 1,
          betCount: 1,
          stake: betAmount,
          name: 'Trebles',
          odds: Number((odds.reduce((acc, item) => acc * item, 1) - 1).toFixed(4)),
        }],
      },
      ticketDetails: {
        ticketDetail: [
          {
            matchId: 49511590,
            homeId: 674773,
            awayId: 674777,
            homeName: 'Poland (V)',
            awayName: 'Spain (V)',
            kickOffTime: matchDateTime,
            sportType: 1,
            sportTypeName: 'Soccer',
            betType: 1,
            betTypeName: 'Handicap',
            oddsId: 393165044,
            odds: odds[0],
            oddsType: 3,
            betChoice: 'Poland (V)',
            betChoice_en: 'Poland (V)',
            leagueId: 95731,
            leagueName: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
            isLive: false,
            point: '0.00',
            point2: '',
            betTeam: 'h',
            homeScore: 0,
            awayScore: 0,
            baStatus: false,
            excluding: '',
            leagueName_en: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
            sportTypeName_en: 'Soccer',
            betTypeName_en: 'Handicap',
            homeName_en: 'Poland (V)',
            awayName_en: 'Spain (V)',
            matchDateTime,
            betRemark: '',
          },
          {
            matchId: 49511605,
            homeId: 674797,
            awayId: 674850,
            homeName: 'Switzerland (V)',
            awayName: 'Argentina (V)',
            kickOffTime: matchDateTime,
            sportType: 1,
            sportTypeName: 'Soccer',
            betType: 1,
            betTypeName: '1H Handicap',
            oddsId: 393165356,
            odds: odds[1],
            oddsType: 3,
            betChoice: 'Switzerland (V)',
            leagueId: 95731,
            leagueName: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
            isLive: false,
            point: '0.00',
            point2: '',
            betTeam: 'h',
            homeScore: 0,
            awayScore: 0,
            baStatus: false,
            excluding: '',
            leagueName_en: 'SABA INTERNATIONAL FRIENDLY Virtual PES 21 - 20 Mins Play',
            sportTypeName_en: 'Soccer',
            betTypeName_en: '1H Handicap',
            homeName_en: 'Switzerland (V)',
            awayName_en: 'Argentina (V)',
            matchDateTime,
            betRemark: '',
          },
          {
            matchId: 49510246,
            homeId: 674926,
            awayId: 674751,
            homeName: 'Real Sociedad (V)',
            awayName: 'Bayern Munchen (V)',
            kickOffTime: matchDateTime,
            sportType: 1,
            sportTypeName: 'Soccer',
            betType: 3,
            betTypeName: 'Over/Under',
            oddsId: 393444506,
            odds: odds[2],
            oddsType: 3,
            betChoice: 'Over',
            leagueId: 95730,
            leagueName: 'SABA CLUB FRIENDLY Virtual PES 21 - 15 Mins Play',
            isLive: false,
            point: '3.50',
            point2: '',
            betTeam: 'h',
            homeScore: 1,
            awayScore: 2,
            baStatus: false,
            excluding: '',
            leagueName_en: 'SABA CLUB FRIENDLY Virtual PES 21 - 15 Mins Play',
            sportTypeName_en: 'Soccer',
            betTypeName_en: 'Over/Under',
            homeName_en: 'Real Sociedad (V)',
            awayName_en: 'Bayern Munchen (V)',
            matchDateTime,
            betRemark: '',
          },
        ],
      },
    })
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      oddsType = '2',
      odds,
      isParlay,
      matchDateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 7926
      roundId: isParlay ? null : `${roundId}`, // 49307808
      betType: isParlay ? null : '1',
      currency,
      betTime,
      betAmount,
      gameInfo: {
        ...(isParlay ? this.getParlayGameInfo({ betAmount, odds, matchDateTime }) : {
          ...this.constantGameInfo,
          operationId: `${txnId}`,
          matchDateTime,
          oddsType,
        }),
        // odds: isParlay ? (odds.reduce((acc, item) => acc * item, 1) - 1).toFixed(4) : `${odds}`,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getAdjustBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betAmount,
      adjustAmount,
      oddsType = '2',
      odds,
      isParlay,
      matchDateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 788663
      roundId: isParlay ? null : `${roundId}`, // 49307808
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      betAmount,
      adjustAmount,
      gameInfo: {
        ...(isParlay ? this.getParlayGameInfo({ betAmount, odds, matchDateTime }) : {
          ...this.constantGameInfo,
          operationId: `${txnId}`,
          matchDateTime,
          oddsType,
        }),
        odds: isParlay ? (odds.reduce((acc, item) => acc * item, 1) - 1).toFixed(4) : `${odds}`,
      },
    }))

    const message = {
      action: 'adjustBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      betTime,
      txTime,
      turnover,
      betAmount,
      winAmount,
      oddsType = '2',
      odds,
      isParlay,
      matchDateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 788663
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      roundId: isParlay ? null : `${roundId}`, // 49307808
      betType: isParlay ? null : '1',
      betTime, // 2021-12-27T01:09:37.170+08:00
      txTime, // 2021-12-27T01:14:56.210+08:00
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...(isParlay ? this.getParlayGameInfo({ betAmount, odds, matchDateTime }) : {
          ...this.constantGameInfo,
          operationId: `${txnId}`,
          matchDateTime,
          oddsType,
        }),
        odds: isParlay ? (odds.reduce((acc, item) => acc * item, 1) - 1).toFixed(4) : `${odds}`,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      roundId,
      betTime,
      txTime,
      turnover,
      betAmount,
      winAmount,
      oddsType = '2',
      odds,
      matchDateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 788663
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      roundId: `${roundId}`, // 49307808
      betType: '1',
      betTime, // 2021-12-27T01:09:37.170+08:00
      txTime, // 2021-12-27T01:14:56.210+08:00
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...this.constantGameInfo,
        operationId: `${txnId}`,
        matchDateTime,
        oddsType,
        odds: `${odds}`,
      },
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      oddsType = '2',
      odds,
      matchDateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`,
      updateTime,
      roundId: `${roundId}`,
      betAmount,
      voidType: 2,
      gameInfo: {
        ...this.constantGameInfo,
        operationId: `${txnId}`,
        matchDateTime,
        oddsType,
        odds: `${odds}`,
      },
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getUnsettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      oddsType = '2',
      odds,
      matchDateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`,
      updateTime,
      roundId: `${roundId}`,
      betAmount,
      gameInfo: {
        ...this.constantGameInfo,
        operationId: `${txnId}`,
        matchDateTime,
        oddsType,
        odds: `${odds}`,
      },
    }))

    const message = {
      action: 'unsettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`,
      roundId: `${roundId}`,
      updateTime,
      voidType: 2,
      betAmount,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      oddsType = '2',
      matchDateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${txnId}`,
      roundId: `${roundId}`,
      gameInfo: {
        ...this.constantGameInfo,
        operationId: `${txnId}`,
        matchDateTime,
        oddsType,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
