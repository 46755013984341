import PlatformRequests from './PlatformRequests'

export default class VIACASINORequests extends PlatformRequests {
  // eslint-disable-next-line class-methods-use-this
  getSettleDataBySettleResult({
    action = 'settle',
    settleResult,
    resettleResult,
    resettleAmountChange,
  }) {
    // C梅花♣：♣1(0)  ♣2(1)  ♣3(2)  ♣4(3)  ♣5(4)  ♣6(5)  ♣7(6)  ♣8(7)  ♣9(8)  ♣10(9)  ♣J(10) ♣Q(11) ♣K(12)
    // H紅心♥：♥1(13) ♥2(14) ♥3(15) ♥4(16) ♥5(17) ♥6(18) ♥7(19) ♥8(20) ♥9(21) ♥10(22) ♥J(23) ♥Q(24) ♥K(25)
    // D方塊♦：♦1(26) ♦2(27) ♦3(28) ♦4(29) ♦5(30) ♦6(31) ♦7(32) ♦8(33) ♦9(34) ♦10(36) ♦J(36) ♦Q(37) ♦K(38)
    // S黑桃♠：♠1(39) ♠2(40) ♠3(41) ♠4(42) ♠5(43) ♠6(44) ♠7(45) ♠8(46) ♠9(47) ♠10(48) ♠J(49) ♠Q(50) ♠K(51)

    // winner: 1 = BANKER庄, 2 = PLAYER閒, 3 = TIE和
    // tableCards: [PLAYER, BANKER]

    let data = {}

    const settleResultData = {
      win: {
        odds: 0.95,
        resultData: {
          winner: 1, // BANKER
          bankerHandValue: 6,
          playerHandValue: 0,
          tableCards: [22, 49, 38, 41, 6, 18], // [H10, S11, D13, S03, C07, H06]
        },
      },
      lose: {
        odds: -1,
        resultData: {
          winner: 2, // PLAYER
          bankerHandValue: 0,
          playerHandValue: 6,
          tableCards: [41, 6, 18, 22, 49, 38], // [S03, C07, H06, H10, S11, D13]
        },
      },
      tie: {
        odds: 0,
        resultData: {
          winner: 3, // TIE
          bankerHandValue: 0,
          playerHandValue: 0,
          tableCards: [22, 49, 38, 41, 6, 22], // [H10, S11, D13, S03, C07, H10]
        },
      },
      increased: {
        // betType: 'BANKER,BANKER_PAIR',
        odds: 0.95,
        resultData: {
          winner: 1, // BANKER
          bankerHandValue: 6,
          playerHandValue: 0,
          tableCards: [22, 49, 38, 41, 6, 18], // [H10, S11, D13, S03, C07, H06]
        },
      },
      decreased: {
        // betType: 'BANKER,BANKER_PAIR',
        odds: 0.95 + 11 + 1,
        resultData: {
          winner: 1, // BANKER
          bankerHandValue: 2,
          playerHandValue: 0,
          tableCards: [22, 49, 38, 41, 2, 18], // [H10, S11, D13, S03, C03, H06]
        },
      },
    }

    if (action === 'settle') {
      data = {
        ...data,
        ...settleResultData[resettleAmountChange || settleResult],
      }
    } else {
      data = { ...settleResultData[resettleResult] }
      const resettleResultLookup = {
        win: {
          // Original Result:
          // bankerHandValue: 6,
          // playerHandValue: 0,
          // tableCards: [22, 49, 38, 41, 6, 18], // [H10, S11, D13, S03, C07, H06]
          win: {
            winner: 1, // BANKER
            bankerHandValue: 7,
            playerHandValue: 0,
            tableCards: [22, 49, 38, 41, 6, 19], // [H10, S11, D13, S03, C07, H07]
          },
          lose: {
            winner: 2, // PLAYER
            bankerHandValue: 6,
            playerHandValue: 9,
            tableCards: [21, 49, 38, 41, 6, 18], // [H09, S11, D13, S03, C07, H06]
          },
          tie: {
            winner: 3, // TIE
            bankerHandValue: 0,
            playerHandValue: 0,
            tableCards: [22, 49, 38, 41, 6, 22], // [H10, S11, D13, S03, C07, H10]
          },
        },
        lose: {
          // Original Result:
          // bankerHandValue: 0,
          // playerHandValue: 6,
          // tableCards: [41, 6, 18, 22, 49, 38], // [S03, C07, H06, H10, S11, D13]
          win: {
            winner: 1, // BANKER
            bankerHandValue: 9,
            playerHandValue: 6,
            tableCards: [41, 6, 18, 21, 49, 38], // [S03, C07, H06, H09, S11, D13]
          },
          lose: {
            winner: 2, // PLAYER
            bankerHandValue: 0,
            playerHandValue: 7,
            tableCards: [41, 6, 19, 22, 49, 38], // [S03, C07, H07, H10, S11, D13]
          },
          tie: {
            winner: 3, // TIE
            bankerHandValue: 0,
            playerHandValue: 0,
            tableCards: [41, 6, 22, 22, 49, 38], // [S03, C07, H10, H10, S11, D13]
          },
        },
        tie: {
          // Original Result:
          // bankerHandValue: 0,
          // playerHandValue: 0,
          // tableCards: [22, 49, 38, 41, 6, 22], // [H10, S11, D13, S03, C07, H10]
          win: {
            winner: 1, // BANKER
            bankerHandValue: 6,
            playerHandValue: 0,
            tableCards: [22, 49, 38, 41, 6, 18], // [H10, S11, D13, S03, C07, H06]
          },
          lose: {
            winner: 2, // PLAYER
            bankerHandValue: 0,
            playerHandValue: 9,
            tableCards: [21, 49, 38, 41, 6, 22], // [H09, S11, D13, S03, C07, H10]
          },
          tie: {
            winner: 3, // TIE
            bankerHandValue: 6,
            playerHandValue: 9,
            tableCards: [22, 49, 38, 41, 6, 23], // [H10, S11, D13, S03, C07, H11]
          },
        },
      }

      if (resettleAmountChange) {
        const resettleAmountChangeData = {
          increased: {
            odds: 0.95 + 11 + 1,
            resultData: {
              winner: 1, // BANKER
              bankerHandValue: 2,
              playerHandValue: 0,
              tableCards: [22, 49, 38, 41, 2, 18], // [H10, S11, D13, S03, C07, H06] => [H10, S11, D13, S03, C03, H06]
            },
          },
          decreased: {
            odds: 0.95,
            resultData: {
              winner: 1, // BANKER
              bankerHandValue: 6,
              playerHandValue: 0,
              tableCards: [22, 49, 38, 41, 6, 18], // [H10, S11, D13, S03, C03, H06] => [H10, S11, D13, S03, C07, H06]
            },
          },
        }

        data = {
          ...data,
          ...resettleAmountChangeData[resettleAmountChange],
        }
      } else {
        data.resultData = resettleResultLookup[settleResult][resettleResult]
      }
    }

    return data
  }
}
