import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class SPADESLOTTestScenarios extends PlatformTestScenarios {
  async bet_settleWin_freeSpin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin({ options: { hasFreeSpin: true } })
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.freeSpin()
        await this.requests.getBalance({ action: 'freeSpin' })
      },
    )
  }
}
