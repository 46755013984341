import { jsPDF as JsPDF } from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'

const exportPDF = async (t, platform) => {
  const pdf = new JsPDF({
    format: 'a4',
    unit: 'px',
    hotfixes: ['px_scaling'],
  })

  const CUSTOM_FONTS = ['FZHTJW', 'Consolas']

  const fonts = await Promise.all([
    import(/* webpackChunkName: 'FZHTJW-normal' */ './fonts/FZHTJW-normal'),
    import(/* webpackChunkName: 'Consolas-normal' */ './fonts/Consolas-normal'),
  ])

  CUSTOM_FONTS.forEach((fontName, i) => {
    pdf.addFileToVFS(`${fontName}-normal.ttf`, fonts[i].default)
    pdf.addFont(`${fontName}-normal.ttf`, fontName, 'normal')
  })

  const pageWidth = pdf.internal.pageSize.getWidth()

  let accumulatedY = 0
  // px
  const MARGIN_LR = 40
  const MARGIN_TOP = 16 + 40 // 16 is to make title against upper edge of the page, 40 is margin top
  const TITLE_HEIGHT = 8
  const ELEMENT_MARGIN_BOTTOM = 60

  // // mm
  // const MARGIN_LR = 10
  // const MARGIN_TOP = 8 + 10 // 20 is to make title against upper edge of the page, 40 is margin top
  // const TITLE_HEIGHT = 4
  // const ELEMENT_MARGIN_BOTTOM = 5

  const clientFieldsTableHeight = document.getElementById('client-fields-table').clientHeight
  const testResultTableHeight = document.getElementById('exportable-test-result-table').clientHeight

  pdf.setFont('FZHTJW')
  pdf.text(t('test-report-pdf-title'), pageWidth / 2, MARGIN_TOP, { align: 'center' })

  accumulatedY += (MARGIN_TOP + TITLE_HEIGHT + ELEMENT_MARGIN_BOTTOM)
  pdf.autoTable({
    html: '#client-fields-table',
    useCss: true,
    styles: {
      font: 'FZHTJW',
      fontSize: 10,
      textColor: [17, 17, 17],
    },
    margin: MARGIN_LR,
    startY: accumulatedY,
  })

  accumulatedY += (clientFieldsTableHeight + ELEMENT_MARGIN_BOTTOM)
  pdf.autoTable({
    html: '#exportable-test-result-table',
    useCss: true,
    styles: {
      font: 'FZHTJW',
      // fontSize: 10,
      textColor: [17, 17, 17],
    },
    margin: MARGIN_LR,
    startY: accumulatedY,
  })

  accumulatedY += (testResultTableHeight + ELEMENT_MARGIN_BOTTOM)
  pdf.autoTable({
    html: '#test-report-table',
    didParseCell: (d) => {
      const cellText = d.cell.text[0]
      // console.log(cellText, d)

      if (cellText === t('test-result')) {
        d.cell.styles.cellWidth = 80
      }

      if (cellText === t('response-code') && d.cell.raw.tagName === 'TH') {
        d.cell.styles.cellWidth = 120
      }

      if (cellText === t('duration') && d.cell.raw.tagName === 'TH') {
        d.cell.styles.cellWidth = 80
      }

      if (cellText === t('pass')) {
        d.cell.styles.fillColor = [229, 255, 216]
      }

      if (cellText === t('fail')) {
        d.cell.styles.fillColor = [255, 205, 205]
      }

      if (cellText === t('warning')) {
        d.cell.styles.fillColor = [255, 238, 204]
        d.cell.text[0] = t('pass')
      }

      if (cellText.startsWith(`${t('warning')}${t('colon')}`)) {
        d.cell.styles.textColor = [250, 173, 20]
      }

      // Handle font and whitespaces of JSON cells
      if (cellText.startsWith('{')) {
        d.cell.text = d.cell.raw.innerHTML.split('\n')
        d.cell.styles.font = 'Consolas'
      }
    },
    theme: 'striped',
    useCss: true,
    styles: {
      font: 'FZHTJW',
      fontSize: 10,
      textColor: [17, 17, 17],
      lineWidth: 1,
    },
    margin: MARGIN_LR,
    startY: accumulatedY,
    showHead: 'firstPage',
  })

  pdf.save(`AWC API Test Report_${platform}_${moment().format('YYYY-MM-DD_HH-mm-ss')}.pdf`)
}

export default exportPDF
