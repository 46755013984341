import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import PlatformRequests, { getTimesLoopRequestName } from './PlatformRequests'

export default class KINGMAKERSLOTRequests extends PlatformRequests {
  async settleLose() {
    let updateTime
    const settleData = this.betData.map((playerBetData, playerIdx) => playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
      const { txnId, betAmount } = txnBetData

      if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)
      return {
        ...txnBetData,
        txnId: uuidv4().replaceAll('-', ''),
        refPlatformTxId: txnId,
        winAmount: 0,
        turnover: betAmount * 1,
        updateTime,
        isEndRound: true,
      }
    })))

    this.tests.settleData = settleData

    const res = await this.actions.settle(settleData[0].flat())

    return this.requestPromise(
      {
        action: 'settle',
        requestName: 'Settle Lose & Close',
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async settleClose(args = {}) {
    const { timesLoop } = args
    let settleData

    if (timesLoop) {
      settleData = this.tests.settleData
    } else {
      let updateTime
      settleData = this.betData.map((playerBetData, playerIdx) => playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
        const { txnId, betAmount } = txnBetData

        if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)
        return {
          ...txnBetData,
          txnId: uuidv4().replaceAll('-', ''),
          refPlatformTxId: txnId,
          betAmount: 0,
          winAmount: 0,
          turnover: betAmount * 1,
          updateTime,
          isEndRound: true,
        }
      })))
    }

    this.tests.settleData = settleData

    const res = await this.actions.settle(settleData[0].flat())
    const timesLoopName = getTimesLoopRequestName(timesLoop)

    return this.requestPromise(
      {
        action: 'settle',
        requestName: `${timesLoopName}Settle Close`,
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }
}
