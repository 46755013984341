import PlatformActions from './PlatformActions'

export default class LUCKYPOKERActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'TABLE',
      gameCode: 'LUCKYPOKER-TABLE-004',
      platform: 'LUCKYPOKER',
    }

    this.gameName = 'Hilo'
    this.userRandomNo = Math.random().toFixed(12).slice(-12)
  }

  getBetMessage(txnList) {
    const { currency, userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      roundIdUnix,
      betAmount,
      betTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${this.userRandomNo}-502-${roundId}-${txnId}`, // 214748365991-502-19929308-12775, 214748365991-502-19929308-12774
      roundId: `${userIdPrefix}${userId}50-${roundIdUnix}-${roundId}`, // sw0thbtest150-1655806197-19929308
      betType: null,
      currency,
      betTime, // 2022-06-21T18:10:10.046+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      // txnId,
      roundId,
      roundIdUnix,
      betCount,
      betAmount,
      winAmount,
      turnover,
      betTime,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `50-${roundIdUnix}-${roundId}-1`, // 50-1655806197-19929308-1
      refPlatformTxId: null,
      settleType: 'roundId',
      updateTime, // 2022-06-21T18:11:24.462+08:00
      roundId: `${userIdPrefix}${userId}50-${roundIdUnix}-${roundId}`, // sw0thbtest150-1655806197-19929308
      betType: null,
      betTime, // 2022-06-21T18:10:10.046+08:00
      txTime: betTime, // 2022-06-21T18:11:24.209+08:00
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        betInfo: Array.from({ length: betCount }).map(_ => null), // eslint-disable-line no-unused-vars
        settleInfo: {
          betCount: `${betCount}`,
          totalBetAmt: `${betAmount}`,
        },
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      roundIdUnix,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${this.userRandomNo}-502-${roundId}-${txnId}`, // 214748365991-502-19929308-12775, 214748365991-502-19929308-12774
      roundId: `${userIdPrefix}${userId}50-${roundIdUnix}-${roundId}`, // sw0thbtest150-1655806197-19929308
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
