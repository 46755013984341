import PlatformActions from './PlatformActions'

export default class AWSEGAMEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'EGAME',
      gameName: 'FOOTBALL ALLSTAR',
      gameCode: 'AWS-EGAME-001',
      platform: 'AWS',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betAmount,
      turnover,
      winAmount,
      betTime,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'EGAME',
      // gameName: 'FOOTBALL ALLSTAR',
      // gameCode: 'AWS-EGAME-001',
      userId,
      // platform: 'AWS',
      platformTxId: `AWC${currency}_41295_${txnId}`, // ex: AWCTHB_41295_14115847563581088
      roundId: `AWC${currency}_41295_${txnId}`,
      betType: '100',
      currency,
      betTime, // ex: 2021-12-10T15:44:51.000+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, // ex: 2021-12-10T15:44:56.558+08:00
      requireAmount: betAmount,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  // settle_win
  // winAmount: ( betAmount * 2.5 ).toFixed(1)
  // settle_lose
  // winAmount: ( betAmount * 0 ).toFixed(1)
  // turnover: ( betAmount * 1 ).toFixed(1)

  getCancelBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'EGAME',
      // gameName: 'FOOTBALL ALLSTAR',
      // gameCode: 'AWS-EGAME-001',
      userId,
      // platform: 'AWS',
      platformTxId: `AWC${currency}_41295_${txnId}`, // ex: AWCTHB_41295_14115847563581088
      roundId: `AWC${currency}_41295_${txnId}`,
      betAmount, // betAmount.toFixed(1)
      updateTime, // ex: 2021-12-10T15:44:56.560+08:00
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
