import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class HORSEBOOKTestScenarios extends PlatformTestScenarios {
  async bet_cancelBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { foBetRule: 0 } })
        await this.requests.cancelBet()
      },
    )
  }

  async bet_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { foBetRule: 0 } })
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_adjustBet_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { foBetRule: 1 } })
        await this.requests.adjustBet({ options: { forOddsChange: true } })
        await this.requests.settleWin()
      },
    )
  }

  async bet_adjustBet_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { foBetRule: 1 } })
        await this.requests.adjustBet({ options: { forOddsChange: true } })
        await this.requests.settleLose()
      },
    )
  }

  async bet_adjustBetForOddsDecreased_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { foBetRule: 2 } })
        await this.requests.adjustBet({ options: { forOddsChange: true } })
        await this.requests.settleWin()
      },
    )
  }

  async bet_adjustBetForOddsDecreased_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { foBetRule: 2 } })
        await this.requests.adjustBet({ options: { forOddsChange: true } })
        await this.requests.settleLose()
      },
    )
  }

  async multiBets_betOnWP_settleLoseOnW_settleWinOnP() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.betOnWP()
        await this.requests.settleOnWP()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_settleWin12_voidSettle1() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })
        await this.requests.bet({ betIndexes: [1] })
        await this.requests.settleWin({ betIndexes: [0, 1] })
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.voidSettle({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_voidBet1_settleWin2_settleLose3() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ betIndexes: [0], data: { runnerNo: '1' } })
        await this.requests.bet({ betIndexes: [1], data: { runnerNo: '2' } })
        await this.requests.bet({ betIndexes: [2], data: { runnerNo: '3' } })

        await this.requests.voidBet({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'voidBet' })

        await this.requests.settle({
          betIndexes: [1, 2],
          settleInfo: {
            top3Data: [
              { runnerNo: '2' },
            ],
          },
        })

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_10horses_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const HORSE_QTY = 10
        const BET_LENGTH_PER_HORSE = 3
        for (let horseLoop = 0; horseLoop < HORSE_QTY; horseLoop += 1) {
          await this.requests.bet({
            betIndexes: [BET_LENGTH_PER_HORSE * horseLoop],
            horseLoop,
            data: {
              betType: 'WIN',
              runnerNo: `${horseLoop + 1}`,
            },
          })
          await this.requests.bet({
            betIndexes: [BET_LENGTH_PER_HORSE * horseLoop + 1],
            horseLoop,
            data: {
              betType: 'PLC',
              runnerNo: `${horseLoop + 1}`,
            },
          })
          await this.requests.betOnWP({
            betIndexes: [BET_LENGTH_PER_HORSE * horseLoop + 2],
            horseLoop,
            data: {
              runnerNo: `${horseLoop + 1}`,
            },
          })
        }

        await this.requests.settle({
          betIndexes: Array.from({ length: HORSE_QTY * BET_LENGTH_PER_HORSE }).map((_, i) => i),
          settleInfo: {
            top3Data: [
              {
                runnerNo: '1',
                odds: { WIN: 1.5, PLC: 1 },
              },
              {
                runnerNo: '2',
                odds: { WIN: 2, PLC: 1.5 },
              },
              {
                runnerNo: '3',
                odds: { WIN: 2.5, PLC: 2 },
              },
            ],
          },
        })

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiPlayers_bet_settleWin() {
    await this.setTestScenario(
      async () => {
        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.getBalance({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.bet({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.settleWin({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'settle',
            playerIndexes: [playerIndex],
          })
        }
      },
    )
  }

  async multiPlayers_bet_voidBet() {
    await this.setTestScenario(
      async () => {
        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.getBalance({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.bet({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.voidBet({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'voidBet',
            playerIndexes: [playerIndex],
          })
        }
      },
    )
  }
}
