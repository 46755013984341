<template>
  <pre class="json-code" v-if="!Array.isArray(record.content)">{{ prettifyJsonString(record.content) }}</pre>
  <AntTable
    v-else
    :rowKey="(record, index) => `test${index}`"
    :columns="testColumns"
    :data-source="record.content"
    :pagination="false"
    :showHeader="false"
    size="small"
  >
    <template #passed="{ text, record }">
      <AntBadge :status="text ? record.warning ? 'warning' : 'success' : 'error'" />
      {{ text ? t('pass') : t('fail') }}
    </template>

    <template #name="{ text, record }">
      <div v-if="record.warning" class="warning">
        <AntBadge status="warning" />
        {{ `${t('warning')}${t('colon')}${record.warning}` }}
      </div>
      {{ text }}
    </template>
  </AntTable>
</template>

<script>
import { inject, computed } from 'vue'

import {
  Table as AntTable,
  Badge as AntBadge,
} from 'ant-design-vue'

import prettifyJsonString from '@/utils/prettifyJsonString'

export default {
  name: 'TestCaseTable',
  components: {
    AntTable,
    AntBadge,
  },
  props: {
    record: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup() {
    const t = inject('t')

    const testColumns = computed(() => [
      {
        dataIndex: 'passed',
        key: 'testPassed',
        slots: { customRender: 'passed' },
        width: 100,
      },
      {
        dataIndex: 'name',
        key: 'testName',
        slots: { customRender: 'name' },
      },
    ])

    return {
      t,
      testColumns,
      prettifyJsonString,
    }
  },
}
</script>

<style scoped>
.json-code {
  font-size: 12px;
  word-break: break-word;
  white-space: break-spaces;
}

.warning {
  color: #faad14;
}
</style>
