export default [
  {
    name: 'Bet > Settle Win',
    functionName: 'bet_settleWin',
  },
  {
    name: 'Bet > Settle Lose',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Win 5 times',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times',
    functionName: 'bet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'BetNSettle Win',
    functionName: 'betNSettleWin',
  },
  {
    name: 'BetNSettle Lose',
    functionName: 'betNSettleLose',
  },
  {
    name: 'BetNSettle Win 5 times',
    functionName: 'betNSettleWin5Times',
  },
  {
    name: '(Network) BetNSettle Win 5 times',
    functionName: 'asyncBetNSettleWin5Times',
  },
  {
    name: 'BetNSettle Win > CancelBetNSettle',
    functionName: 'betNSettleWin_cancelBetNSettle',
  },
  {
    name: 'BetNSettle Lose > CancelBetNSettle',
    functionName: 'betNSettleLose_cancelBetNSettle',
  },
  {
    name: 'BetNSettle Win > CancelBetNSettle 5 times',
    functionName: 'betNSettleWin_cancelBetNSettle5Times',
  },
  {
    name: 'CancelBetNSettle only',
    functionName: 'cancelBetNSettleOnly',
  },
  {
    name: 'CancelBetNSettle > BetNSettle Win',
    functionName: 'cancelBetNSettle_betNSettleWin',
  },
  {
    name: 'Multi Bets - Place 10 BetNSettle > CancelBetNSettle',
    functionName: 'betNSettleWin10Times_cancelBetNSettle',
  },
  {
    name: 'Negative Balance - BetNSettle Win > CancelBetNSettle',
    functionName: 'negativeBalance_betNSettleWin_cancelBetNSettle',
  },
  {
    name: 'Positive Balance - Insufficient - BetNSettle Win',
    functionName: 'positiveBalance_insufficient_betNSettleWin',
  },
  {
    name: 'Positive Balance - Insufficient - BetNSettle Lose',
    functionName: 'positiveBalance_insufficient_betNSettleLose',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Zero Balance - BetNSettle Win',
    functionName: 'zeroBalance_betNSettleWin',
  },
  {
    name: 'Zero Balance - BetNSettle Lose',
    functionName: 'zeroBalance_betNSettleLose',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
  {
    name: 'Negative Balance - BetNSettle Win',
    functionName: 'negativeBalance_betNSettleWin',
  },
  {
    name: 'Negative Balance - BetNSettle Lose',
    functionName: 'negativeBalance_betNSettleLose',
  },
]
