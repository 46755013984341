import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class PGMIXEDTestScenarios extends PlatformTestScenarios {
  async mixed_bet_settle_betNSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ betIndexes: [0] })
        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        const BET_LENGTH = 3
        for (let i = 1; i < BET_LENGTH + 1; i += 1) {
          await this.requests.bet({ betIndexes: [i] })
        }

        const BETNSETTLE_LENGTH = 3
        for (let i = 0; i < BETNSETTLE_LENGTH; i += 1) {
          if (i === BETNSETTLE_LENGTH - 1) {
            await this.requests.betNSettleLose({ betIndexes: [i] })
          } else {
            await this.requests.betNSettleWin({ betIndexes: [i] })
          }
          await this.requests.getBalance({ action: 'betNSettle' })
        }

        for (let i = 1; i < BET_LENGTH + 1; i += 1) {
          if (i === BETNSETTLE_LENGTH) {
            await this.requests.settleLose({ betIndexes: [i] })
          } else {
            await this.requests.settleWin({ betIndexes: [i] })
          }
          await this.requests.getBalance({ action: 'settle' })
        }
      },
    )
  }

  async mixed_bet_settle_betNSettle_2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ betIndexes: [0] })
        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        const BET_LENGTH = 3
        for (let i = 1; i < BET_LENGTH + 1; i += 1) {
          await this.requests.bet({ betIndexes: [i] })
        }

        // Switch to NEW PG (betNSettle)
        await this.requests.betNSettleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'betNSettle' })

        await this.requests.cancelBet({ betIndexes: [1] })

        await this.requests.settleLose({ betIndexes: [2] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.settleWin({ betIndexes: [3] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betNSettleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'betNSettle' })

        // Switch to OLD PG (bet + settle)
        await this.requests.bet({ betIndexes: [4] })

        await this.requests.cancelBetNSettle({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'cancelBetNSettle' })

        await this.requests.cancelBetNSettle({
          betIndexes: [2],
          options: { beforeBetNSettle: true },
        })
        await this.requests.getBalance({ action: 'cancelBetNSettle' })

        await this.requests.settleWin({ betIndexes: [4] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.bet({ betIndexes: [5] })
        await this.requests.settleLose({ betIndexes: [5] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async mixed_bet30_settle30Async() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = 30
        for (let i = 0; i < BET_LENGTH; i += 1) {
          await this.requests.bet({ betIndexes: [i] })
        }

        await Promise.all(Array.from({ length: BET_LENGTH }).map(async (_, i) => {
          await this.requests.settleWin({
            betIndexes: [i],
            options: { async: true },
          })
          return true
        }))

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }
}
