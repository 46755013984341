export default [
  {
    name: 'Bet > Settle Win',
    functionName: 'bet_settleWin',
  },
  {
    name: 'Bet > Settle Lose',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Win 5 times',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times',
    functionName: 'bet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > VoidBet',
    functionName: 'bet_voidBet',
  },
  {
    name: 'Bet > VoidBet 5 times',
    functionName: 'bet_voidBet5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Multi Bets - Place 5 bets > Settle Win on TXN 2, TXN 4 & Settle Lose on TXN 1, TXN 3, TXN 5',
    functionName: 'multiBets_bet1_bet2_bet3_bet4_bet5_settle',
  },
  {
    name: 'Multi Bets - Place 5 bets > VoidBet',
    functionName: 'multiBets_bet1_bet2_bet3_bet4_bet5_voidBet',
  },
  {
    name: 'Multi Bets - Place 5 bets > VoidBet on on TXN 1, TXN 2 > Settle Win on on TXN 3 & Settle Lose TXN 4, TXN 5',
    functionName: 'multiBets_bet1_bet2_bet3_bet4_bet5_voidBet12_settle345',
  },
  {
    name: 'Multi Bets - Place 1 bet with 5 TXN > Settle',
    functionName: 'multiTxnsIn1Bet_bet_settle',
  },
  {
    name: 'Multi Bets - Place 1 bet with 5 TXN > VoidBet',
    functionName: 'multiTxnsIn1Bet_bet_voidBet',
  },
  {
    name: 'Multi Bets - Place 1 bet with 5 TXN > CancelBet',
    functionName: 'multiTxnsIn1Bet_bet_cancelBet',
  },
  {
    name: 'Bet > Settle Win > Resettle Win (winAmount +)',
    functionName: 'bet_settleWin_resettleWin_increased',
  },
  {
    name: 'Bet > Settle Win > Resettle Win (winAmount -)',
    functionName: 'bet_settleWin_resettleWin_decreased',
  },
  {
    name: 'Bet > Settle Win > Resettle Lose',
    functionName: 'bet_settleWin_resettleLose',
  },
  {
    name: 'Bet > Settle Lose > Resettle Win',
    functionName: 'bet_settleLose_resettleWin',
  },
  {
    name: 'Bet > Settle Lose > Resettle Win 5 times',
    functionName: 'bet_settleLose_resettleWin5Times',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Lose',
    functionName: 'negativeBalance_bet1_settleWin1_bet2_resettleLose1_settleWin2',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
