import PlatformActions from './PlatformActions'

export default class YLFISHActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'FH',
      gameName: 'Fishing 2',
      gameCode: 'YL-FISH-001',
      platform: 'YL',
    }

    this.constantGameInfo = {
      extension1: 'TSTESTyl00wc081688',
      chair: '2',
      roomLevel: '3',
      commission: '0.0',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      turnover,
      winAmount,
      betTime,
      updateTime,
      roomId,
      requireAmount,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'FH',
      // gameName: 'Fishing 2',
      // gameCode: 'YL-FISH-001',
      userId,
      // platform: 'YL',
      platformTxId: txnId, // ex: 20211001023324741 0897450 100030  *txid 是 gameNumber+ 阶段小局序号*
      roundId, // ex: 20211001023324741 0897450  *gameNumber roomId 这两个是 日期+时间(ms)+随机数字(7)*
      betType: null,
      currency,
      betAmount, // betAmount.toFixed(1)
      winAmount,
      requireAmount: requireAmount ?? betAmount,
      turnover,
      betTime,
      txTime: betTime,
      updateTime, // ex: 2021-10-01T11:06:40.625+08:00
      gameInfo: {
        ...this.constantGameInfo,
        // extension1: 'TSTESTyl00wc081688',
        // chair: '2',
        // roomLevel: '3',
        // commission: '0.0',
        profit: winAmount - betAmount,
        roomId, // 20210930202540342 0068594  *gameNumber roomId 这两个是 日期+时间(ms)+随机数字(7)*
      },
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  // settle_win
  // winAmount: ( betAmount * 8.5 ).toFixed(1)
  // settle_lose
  // winAmount: ( betAmount * 0 ).toFixed(1)

  // turnover: ( betAmount * 1 ).toFixed(1)
  // profit: ( winAmount - betAmount ).toFixed(1)

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      winAmount,
      updateTime,
      roomId,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'FH',
      // gameName: 'Fishing 2',
      // gameCode: 'YL-FISH-001',
      userId,
      // platform: 'YL',
      platformTxId: txnId, // ex: 202110010233247410897450100030  *txid 是 gameNumber+ 阶段小局序号*
      roundId, // ex:202110010233247410897450  *gameNumber roomId 这两个是 日期+时间(ms)+随机数字(7)*
      betAmount, // betAmount.toFixed(1)
      updateTime, // ex: 2021-10-01T11:06:40.625+08:00
      gameInfo: {
        ...this.constantGameInfo,
        // extension1: 'TSTESTyl00wc081688',
        // chair: '2',
        // roomLevel: '3',
        // commission: '0.0',
        profit: winAmount - betAmount,
        roomId, // 202109302025403420068594  *gameNumber roomId 这两个是 日期+时间(ms)+随机数字(7)*
      },
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
