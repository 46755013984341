import PlatformActions from './PlatformActions'

export default class LADYLUCKActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'LADYLUCK-SLOT-005',
      platform: 'LADYLUCK',
    }

    this.gameName = 'Popeye'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 66bc69217ab560c53d39a258
      roundId: txnId, // 66bc69217ab560c53d39a258
      betType: 'regular',
      currency,
      betTime, // 2024-08-14T16:20:04.554+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      refPlatformTxId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // b6112d49-47d0-4e0d-af5d-32676c0ca1d3
      refPlatformTxId, // 66bc69217ab560c53d39a258
      settleType: 'refPlatformTxId',
      updateTime, // 2024-08-14T16:20:04.848+08:00
      roundId: refPlatformTxId, // 66bc69217ab560c53d39a258
      betType: 'regular',
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        isEndRound: true,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 66bc69217ab560c53d39a258
      roundId: txnId,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
