import moment from 'moment'
import { capitalize } from 'lodash'

import PlatformRequests from './PlatformRequests'

export default class VRLOTTORequests extends PlatformRequests {
  async multiBets() {
    const {
      userId1: userId,
      betAmount,
    } = this.formData

    const playerIndex = 0
    const BET_LENGTH = 5

    const { roundId } = await this.getIdData()
    const betTime = moment().millisecond(0).utcOffset(8).toISOString(true)

    this.betData[playerIndex] = this.betData[playerIndex] || []
    this.betData[playerIndex][0] = Array.from({ length: BET_LENGTH }).map((_, txnIdx) => ({
      txnId: `1${moment().format('MMDD')}${moment().valueOf() + txnIdx}`,
      roundId,
      userId,
      betAmount,
      betTime,
    }))
    console.log('this.betData', this.betData[playerIndex][0])
    this.tests.betData = this.betData

    const res = await this.actions.bet(this.betData[playerIndex].flat())

    return this.requestPromise(
      {
        action: 'bet',
        requestName: 'Bet',
      },
      res,
      () => this.tests.bet({
        requestNo: this.requestNo,
        res: res.data,
        playerIndex,
      }),
    )
  }

  async multiSettle({ settleResult }) {
    const updateTime = moment().utcOffset(8).toISOString(true)

    const settleData = this.betData.map(playerBetData => playerBetData.map((singleBetData) => {
      const txnsData = []
      singleBetData.forEach((txnBetData, txnIdx) => {
        const { betAmount } = txnBetData
        const winTxnIndexes = [1, 3]
        const isWin = winTxnIndexes.includes(txnIdx)

        if ((settleResult === 'win' && isWin) || (settleResult === 'lose' && !isWin)) {
          txnsData.push({
            ...txnBetData,
            winAmount: isWin ? betAmount * (1 + 0.96) : 0,
            turnover: betAmount * 1,
            updateTime,
          })
        }
      })
      return txnsData
    }))

    this.tests.settleData = settleData

    const res = await this.actions.settle(settleData.flat().flat())

    return this.requestPromise(
      {
        action: 'settle',
        requestName: `Settle ${capitalize(settleResult)}`,
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async multiCancelBets() {
    const cancelBetData = this.betData.map(playerBetData => playerBetData.map(singleBetData => singleBetData.map(txnBetData => txnBetData)))

    this.tests.cancelBetData = cancelBetData

    const res = await this.actions.cancelBet(cancelBetData.flat().flat())

    return this.requestPromise(
      {
        action: 'cancelBet',
        requestName: 'CancelBet',
      },
      res,
      () => this.tests.cancelBet({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async multiVoidBets() {
    let updateTime
    const voidBetData = this.betData.map((playerBetData, playerIdx) => playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
      if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)
      return {
        ...txnBetData,
        voidType: 2,
        updateTime,
      }
    })))

    this.tests.voidBetData = voidBetData

    const res = await this.actions.voidBet(voidBetData.flat().flat())

    return this.requestPromise(
      {
        action: 'voidBet',
        requestName: 'VoidBet',
      },
      res,
      () => this.tests.voidBet({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }
}
