import PlatformAdjustBetTestScenarios from './PlatformAdjustBetTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class SV388TestScenarios extends PlatformAdjustBetTestScenarios {
  async multiBets_bet1_adjustBet1_bet2_bet3_settleWinOnWALA() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { betType: 'WALA' },
        })
        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.bet({
          betIndexes: [1],
          data: { betType: 'MERON' },
          options: { positiveOdds: true },
        })

        await this.requests.bet({
          betIndexes: [2],
          data: { betType: 'BDD' },
          options: { positiveOdds: true },
        })

        await this.requests.settle({
          betIndexes: [0, 1, 2],
          settleInfo: { matchResult: 'WALA' },
        })

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_settleWinOnMERON() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { betType: 'WALA' },
          options: { positiveOdds: true },
        })

        await this.requests.bet({
          betIndexes: [1],
          data: { betType: 'MERON' },
          options: { positiveOdds: true },
        })

        await this.requests.bet({
          betIndexes: [2],
          data: { betType: 'BDD' },
          options: { positiveOdds: true },
        })

        await this.requests.settle({
          betIndexes: [0, 1, 2],
          settleInfo: { matchResult: 'MERON' },
        })

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_adjustBet1_bet2_bet3_settleWinOnBDD() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { betType: 'WALA' },
        })
        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.bet({
          betIndexes: [1],
          data: { betType: 'MERON' },
          options: { positiveOdds: true },
        })

        await this.requests.bet({
          betIndexes: [2],
          data: { betType: 'BDD' },
          options: { positiveOdds: true },
        })

        await this.requests.settle({
          betIndexes: [0, 1, 2],
          settleInfo: { matchResult: 'BDD' },
        })

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_adjustBet1_bet2_bet3_settleWinOnFTD() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { betType: 'WALA' },
        })
        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.bet({
          betIndexes: [1],
          data: { betType: 'MERON' },
          options: { positiveOdds: true },
        })

        await this.requests.bet({
          betIndexes: [2],
          data: { betType: 'BDD' },
          options: { positiveOdds: true },
        })

        await this.requests.settle({
          betIndexes: [0, 1, 2],
          settleInfo: { matchResult: 'FTD' },
        })

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiPlayers_multiBets_bet20_settle20() {
    await this.setTestScenario(
      async () => {
        const PLAYER_LENGTH = 3
        const BET_LENGTH = 20
        const DIVISOR = 3

        const playerIndexes = Array.from({ length: PLAYER_LENGTH }).map((_, i) => i)
        const betIndexes = Array.from({ length: BET_LENGTH }).map((_, i) => i)

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({ playerIndexes: [playerIndex] })

          for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
            const isNegativeOdds = betIndex % DIVISOR === 0

            await this.requests.bet({
              playerIndexes: [playerIndex],
              betIndexes: [betIndex],
              data: {
                betType: ['WALA', 'MERON', 'BDD'][betIndex % DIVISOR],
              },
              ...(!isNegativeOdds && {
                options: { positiveOdds: true },
              }),
            })

            if (isNegativeOdds) {
              await this.requests.adjustBet({
                playerIndexes: [playerIndex],
                betIndexes: [betIndex],
              })
            }
          }
        }

        await this.requests.settle({
          betIndexes,
          playerIndexes,
          settleInfo: { matchResult: 'WALA' },
        })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'settle',
            playerIndexes: [playerIndex],
          })
        }
      },
    )
  }

  async multiPlayers_multiBets_bet1_bet2_bet3_settle_unsettle_settle() {
    await this.setTestScenario(
      async () => {
        const PLAYER_LENGTH = 3
        const BET_LENGTH = 3
        const DIVISOR = 3

        const playerIndexes = Array.from({ length: PLAYER_LENGTH }).map((_, i) => i)
        const betIndexes = Array.from({ length: BET_LENGTH }).map((_, i) => i)

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({ playerIndexes: [playerIndex] })

          for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
            const isNegativeOdds = betIndex % DIVISOR === 0

            await this.requests.bet({
              playerIndexes: [playerIndex],
              betIndexes: [betIndex],
              data: {
                betType: ['WALA', 'MERON', 'BDD'][betIndex % DIVISOR],
              },
              ...(!isNegativeOdds && {
                options: { positiveOdds: true },
              }),
            })

            if (isNegativeOdds) {
              await this.requests.adjustBet({
                playerIndexes: [playerIndex],
                betIndexes: [betIndex],
              })
            }
          }
        }

        await this.requests.settle({
          playerIndexes,
          betIndexes,
          settleInfo: { matchResult: 'WALA' },
        })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'settle',
            playerIndexes: [playerIndex],
          })
        }

        await this.requests.unsettle({
          playerIndexes,
          betIndexes,
        })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'unsettle',
            playerIndexes: [playerIndex],
          })
        }

        await this.requests.settle({
          playerIndexes,
          betIndexes,
          settleInfo: { matchResult: 'MERON' },
        })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'settle',
            playerIndexes: [playerIndex],
          })
        }
      },
    )
  }

  async multiPlayers_bet_adjustBet_settleLose_refund() {
    await this.setTestScenario(
      async () => {
        const PLAYER_LENGTH = 3
        const playerIndexes = Array.from({ length: PLAYER_LENGTH }).map((_, i) => i)

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({ playerIndexes: [playerIndex] })
          await this.requests.bet({ playerIndexes: [playerIndex] })
          await this.requests.adjustBet({ playerIndexes: [playerIndex] })
        }

        await this.requests.settleLose({ playerIndexes })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'settle',
            playerIndexes: [playerIndex],
          })
        }

        await this.requests.refund({ playerIndexes })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'refund',
            playerIndexes: [playerIndex],
          })
        }
      },
    )
  }
}
