export default [
  {
    name: 'Bet > Settle Win',
    functionName: 'bet_settleWin',
  },
  {
    name: 'Bet > Settle Lose',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Tie',
    functionName: 'bet_settleTie',
  },
  {
    name: 'Bet > Settle Win 5 times',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times',
    functionName: 'bet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > Settle Lose > Resettle Win',
    functionName: 'bet_settleLose_resettleWin',
  },
  {
    name: 'Bet > Settle Lose > Resettle Tie',
    functionName: 'bet_settleLose_resettleTie',
  },
  {
    name: 'Bet > Settle Win > Resettle Lose',
    functionName: 'bet_settleWin_resettleLose',
  },
  {
    name: 'Bet > Settle Win > Resettle Tie',
    functionName: 'bet_settleWin_resettleTie',
  },
  {
    name: 'Bet > Settle Tie > Resettle Win',
    functionName: 'bet_settleTie_resettleWin',
  },
  {
    name: 'Bet > Settle Tie > Resettle Lose',
    functionName: 'bet_settleTie_resettleLose',
  },
  {
    name: 'Bet > VoidBet',
    functionName: 'bet_voidBet',
  },
  {
    name: 'Bet > VoidBet 5 times',
    functionName: 'bet_voidBet5Times',
  },
  // {
  //   name: 'Bet > Settle Win > Unsettle > VoidBet',
  //   functionName: 'bet_settleWin_unsettle_voidBet',
  // },
  {
    name: 'Bet > Settle Win > VoidSettle',
    functionName: 'bet_settleWin_voidSettle',
  },
  {
    name: 'Bet > Settle Lose > VoidSettle',
    functionName: 'bet_settleLose_voidSettle',
  },
  {
    name: 'Bet > Settle Tie > VoidSettle',
    functionName: 'bet_settleTie_voidSettle',
  },
  {
    name: 'Bet > Settle Win > VoidSettle 5 times',
    functionName: 'bet_settleWin_voidSettle5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Multi Bets - Place 3 bets > Settle Win on TXN 1 & Settle Lose on TXN 2, TXN 3',
    functionName: 'multiBets_bet1_bet2_bet3_settle',
  },
  {
    name: 'Multi Bets - Place 3 bets > VoidBet',
    functionName: 'multiBets_bet1_bet2_bet3_voidBet',
  },
  {
    name: 'Multi Bets - Place 1 bet with 45 txns > Settle', // roulette
    functionName: 'multiTxnsIn1Bet_bet_settle',
  },
  {
    name: 'Multi Bets - Place 1 bet with 45 txns > VoidBet', // roulette
    functionName: 'multiTxnsIn1Bet_bet_voidBet',
  },
  {
    name: 'Multi Bets - Place 1 bet with 45 txns > CancelBet', // roulette
    functionName: 'multiTxnsIn1Bet_bet_cancelBet',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Lose',
    functionName: 'negativeBalance_bet1_settleWin1_bet2_resettleLose1_settleTie2',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Tie',
    functionName: 'negativeBalance_bet1_settleWin1_bet2_resettleTie1_settleTie2',
  },
  // {
  //   name: 'Negative Balance - Settle Win > Unsettle > VoidBet',
  //   functionName: 'negativeBalance_bet1_settleWin1_bet2_unsettle1_voidBet1_settleTie2',
  // },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
