import PlatformActions from './PlatformActions'

export default class AWSSLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameName: 'GOLDEN FISH',
      gameCode: 'AWS-SLOT-056',
      platform: 'AWS',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betAmount,
      turnover,
      winAmount,
      betTime,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `AWC${currency}_75293_${txnId}`, // AWCTHB_75293_532074259620308
      roundId: `AWC${currency}_75293_${txnId}`,
      betType: '100',
      currency,
      betTime, // 2021-12-10T15:44:51.000+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, //  2021-12-10T15:44:56.558+08:00
      requireAmount: betAmount,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  // settle_win
  // winAmount: ( betAmount * 2.5 ).toFixed(1)
  // settle_lose
  // winAmount: ( betAmount * 0 ).toFixed(1)
  // turnover: ( betAmount * 1 ).toFixed(1)

  getCancelBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `AWC${currency}_75293_${txnId}`, // AWCTHB_75293_532074259620308
      roundId: `AWC${currency}_75293_${txnId}`,
      betAmount,
      updateTime, // 2021-12-10T15:44:56.560+08:00
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
